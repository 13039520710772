import { AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';
import { IGainAccessTestPayload, IUpdateTestBannerPayload } from '../types/test.types';
import { ICreateTestPayload, ITest, IUpdateTestPayload } from '../types';

class TestApiService {
    async getAllTests() {
        return axiosInstance.get<ITest[]>('/test')
            .then((res: AxiosResponse) => res.data.data)
            .catch(() => []);
    }

    async getTest(testId: string) {
        return axiosInstance.get<ITest>(`/test/${testId}`)
            .then((res: AxiosResponse) => res.data.data)
    }

    async createTest(payload: ICreateTestPayload) {
        return axiosInstance.post<ITest>('/test/create', { payload })
            .then((res: AxiosResponse) => res.data.data)
    }

    async updateTest(payload: IUpdateTestPayload) {
        return axiosInstance.patch<ITest>('/test', { payload })
            .then((res: AxiosResponse) => res.data.data);
    }

    async updateTestBanner(payload: IUpdateTestBannerPayload) {
        return axiosInstance.patch('/test/banner', payload)
            .then((res: AxiosResponse) => res.data.data);
    }

    async deleteTests(testsIds: string[]) {
        return axiosInstance.post('/test/delete', { testsIds })
            .then((res: AxiosResponse) => res.data)
    }

    async applyForAccess(payload: IGainAccessTestPayload) {
        return axiosInstance.post('/test/gain-access', { payload });
    }
}

export const testApiService = new TestApiService();