import { useTranslation } from "react-i18next";
import { InfoPageWrapper } from "../../components/common";
import LocalizationSwitcher from "../../components/common/localization-switcher/LocalizationSwitcher";
import style from './ForbiddenPage.module.scss';

function ForbiddenPage () {
  const { t } = useTranslation();

  return (
    <InfoPageWrapper
      largeText={ t('unavailableResults') }
    >
      <div className={style.localizationSwitcher} >
        <LocalizationSwitcher/>
      </div>
    </InfoPageWrapper>
  );
}

export default ForbiddenPage;