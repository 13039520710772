import cn from 'classnames';
import style from './ErrorText.module.scss';

interface IErrorTextProp {
    text?: string;
    position?: 'absolute' | 'static';
}

function ErrorText({ text, position = 'absolute' }: IErrorTextProp) {
    return (
        <div
            className={cn(style.error, { [style.hidden]: !text })}
            style={{ position }}>
            {text}
        </div>
    )
}

export default ErrorText;