import { makeAutoObservable } from 'mobx';

class AuthStateService {
    isAuthorized: boolean = false;

    constructor() { makeAutoObservable(this) }

    setAuthorized() {
        this.isAuthorized = true;
    }

    setUnauthorized() {
        this.isAuthorized = false;
    }
}

export const authStateService = new AuthStateService();