import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { IRoute } from "./types";

interface IAdminRoute extends IRoute {}

export const AdminRoute = ({ children }: IAdminRoute) => {
  const { info } = useAppSelector(state => state.user);

  if (!info) {
    return <Navigate to='/login'/>;
  }

  if (!info?.isAdmin) {
    return <Navigate to='/check'/>;
  }

  return children;
};
