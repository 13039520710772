import { toJS } from "mobx";
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import style from './TestPreview.module.scss';
import { testStateService } from '../../../state';
import { IQuestion } from "../../../types/question.types";
import { defaultTestCardImg } from '../../../images';
import TestPreviewQuestion from './TestPreviewQuestion';

function TestPreview() {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const defaultQuestions = testStateService.defaultCategory.questions.filter((item: IQuestion) => item.isDefault);
    const defaultCategoryOtherQuestions = testStateService.defaultCategory.questions.filter((item: IQuestion) => !item.isDefault);

    const categories = [
        { questions: defaultQuestions, title: null },
        { questions: defaultCategoryOtherQuestions, title: null }, 
        ...testStateService.categories
    ];

    const handleNextPage = () => {
        if (currentPage >= categories.length - 1) return;
        setCurrentPage(currentPage + 1);
    };
    const handlePrevPage = () => {
        if (currentPage <= 0) return;
        setCurrentPage(currentPage - 1);
    };

    return (
        <div className={style.content}>
            <div className={style.itemWrapper}>
                <div className={style.title}>{testStateService.test?.title}</div>
                <div
                    className={style.banner}
                    style={{
                        backgroundImage: `url(${testStateService.test?.banner ?
                            `${process.env.REACT_APP_BACK_URL}/${testStateService.test?.banner}`
                            : defaultTestCardImg})`
                    }}>
                </div>
            </div>
            {   categories[currentPage]?.title &&
                <div className={style.categoryContainer}>
                <div className={style.categoryTitle} > { categories[currentPage]?.title }</div>
                </div>
            }
            { toJS( categories[currentPage].questions)?.map((item: IQuestion) => {
                return <TestPreviewQuestion key={item._id} question={item} />
            })}
            <div className={style.buttons} >
            { currentPage > 0 && <button onClick={handlePrevPage} className={style.button} >
                Back
            </button>}
            { currentPage < categories.length - 1 && <button onClick={handleNextPage} className={style.button}>
                Next
            </button>}
            { currentPage === categories.length -1 && <button className={style.sendButton}>
                Send
            </button>}
            </div>
        </div>
    )
}

export default observer(TestPreview);
