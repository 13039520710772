import { IResult } from '../types';
import { resultApiService } from '../api';
import { resultStateService } from '../state';

class ResultService {
    async getResults(testId: string) {
        try {
            const results: IResult[] = await resultApiService.getResults(testId);
            resultStateService.setResults(results);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async getResult(resutlId: string) {
        try {
            const result = await resultApiService.getResult(resutlId);
            return Promise.resolve(result);
        }
        catch {
            return Promise.reject();
        }
    }

    async deleteResults(resultsIds: string[], testId?: string) {
        try {
            resultStateService.deleteResults(resultsIds);
            await resultApiService.deleteResults(resultsIds, testId);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async markResults(resultsIds: string[]) {
        try {
            await resultApiService.markResults(resultsIds);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }
}

export const resultService = new ResultService();