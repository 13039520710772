import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import MaxScore from './max-score/MaxScore';
import { countMaxScore } from '../../../utils';
import style from './QuestionCard.module.scss';
import { testService } from '../../../services';
import { deleteIcon, dragIcon } from '../../../images';
import QuestionHead from './question-head/QuestionHead';
import QuestionOptions from './question-options/QuestionOptions';
import { DroppedQuestion, IQuestion, QuestionTypesEnum } from '../../../types/question.types';
import { useDrag, useDrop } from 'react-dnd';
import { Items } from '../../../types/category.types';
import { errorPopup } from '../../common/error-popup/errorPopup';

interface IQuestionCardProp {
    question: IQuestion;
}

function QuestionCard({ question }: IQuestionCardProp) {
    const [{ opacity }, drag, preview] = useDrag(() => ({
        type: Items.QUESTION,
        item: { id: question._id, categoryId: question.category },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    }));

    const [, drop] = useDrop(() => ({
        accept: Items.QUESTION,
        drop: async (item: DroppedQuestion) => {
            if (item.id === question._id) return;

            if (item.categoryId === question.category) {
                await testService.updateQuestionsOrder(item.id, question._id, question.category).catch(() => { errorPopup() });
            } else {
                await testService.updateQuestionOrderInCategory(item.id, question._id, item.categoryId, question.category).catch(() => { errorPopup() });
            }
        }
    }));

    async function updateQuestionCallback(questionType: string) {
        const updatePayload = { questionId: question._id, type: questionType, maxScore: 0 }
        if (questionType === QuestionTypesEnum.closed && question.options) {
            updatePayload.maxScore = countMaxScore(question.options);
        }
        await testService.updateQuestion(updatePayload, question.category)
            .catch(() => {});
    }

    async function deleteQuestion() {
        await testService.deleteQuestion(question.test, question._id, question.category).catch(() => { errorPopup() });
    }

    useEffect(() => {}, [question.options]);

    return (
        <>{question._id &&
            <div id={question._id} className={style.card} style={{ opacity }}
                ref={ (node) => {
                preview(node);
                drop(node);
            } } >
                <div ref={drag} className={style.dragIcon}><img src={dragIcon} alt='' /></div>
                <img
                    className={style.deleteButton}
                    onClick={deleteQuestion}
                    src={deleteIcon}
                    alt='' />

                <QuestionHead
                    question={question}
                    setQuestionType={updateQuestionCallback}
                    uploadResultFileIsAvailable={question.uploadResultFileIsAvailable} />

                {question.type === QuestionTypesEnum.open &&
                    <div className={style.openQuestionPanel}>
                        <MaxScore questionId={question._id} categoryId={question.category} initialValue={question.maxScore} />
                    </div>}

                {question.type === QuestionTypesEnum.closed &&
                    <QuestionOptions
                        categoryId={question.category}
                        correctOptionsCount={question.correctOptionsCount}
                        options={question.options}
                        questionId={question._id}
                        maxScore={question.maxScore} />}
            </div>
        }</>
    )
}

export default observer(QuestionCard);