import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import style from './QuestionHead.module.scss';
import { testService } from '../../../../services';
import { useAutosizeTextArea } from '../../../../utils';
import AddLinkPanel from '../add-link-panel/AddLinkPanel';
import { AttachedElementType } from '../../../../types/enums';
import { missedFieldsStateService } from '../../../../state';
import AttachedElements from '../../../common/attached-elements/AttachedElements';
import { attachImg, closedQuestionImg, linkImg, openQuestionImg } from '../../../../images';
import { AddButton, ErrorText, Notification, Select, Switcher, UploadInput } from '../../../common';
import { IAddQuestionFilePayload, IQuestion, IUpdateQuestionPayload, QuestionTypesEnum } from '../../../../types/question.types';
import { useTranslation } from 'react-i18next';

interface IQuestionHeadProp {
    question: IQuestion;
    uploadResultFileIsAvailable?: boolean;
    setQuestionType: (questionType: string) => void;
}

function QuestionHead({ question, setQuestionType }: IQuestionHeadProp) {
    const [questionText, setQuestionText] = useState(question.text || '');
    const [addLinkPanelIsActive, setAddLinkPanelIsActive] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [file, setFile] = useState();
    const [fileUploadErr, setFileUploadErr] = useState(false);
    const [fileSavedNotificIsActive, setFileSavedNotificIsActive] = useState(false);

    const headTextRef = useRef(null);
    const [headTextRefId, setHeadTextRefId] = useState<string>('');

    const selectRef = useRef(null);
    const [selectRefId, setSelectRefId] = useState<string>('');

    const { t } = useTranslation();

    const selectOptions = [
        { icon: openQuestionImg, text: t('question.openQuestion'), value: QuestionTypesEnum.open },
        { icon: closedQuestionImg, text: t('question.chooseVariant'), value: QuestionTypesEnum.closed },
    ];

    useAutosizeTextArea(textareaRef.current, questionText);

    function handleChange(e: any) {
        if (!textareaRef.current) return;
        const value = e.target.value.replaceAll('\n', '');
        setQuestionText(value);
    }

    async function updateQuestion(payload: IUpdateQuestionPayload) {
        await testService.updateQuestion(payload, question.category).catch(() => {});
    }

    async function updateRequiredStatus(isRequired: boolean) {
        await testService.updateQuestion({ questionId: question._id, isRequired }, question.category).catch(() => {});
    }

    async function updateuploadResultFile(uploadResultFileIsAvailable: boolean) {
        await testService.updateQuestion({ questionId: question._id, uploadResultFileIsAvailable }, question.category).catch(() => {});
    }

    async function addQuestionFile(payload: IAddQuestionFilePayload) {
        await testService.addQuestionFile(payload, question.category);
    }

    useEffect(() => {
        if (file) addQuestionFile({ questionId: question._id, file })
            .then(() => { 
                setFileSavedNotificIsActive(true);
                setFileUploadErr(false) 
            })
            .catch(() => { setFileUploadErr(true) });
    }, [file]);

    useEffect(() => {
        if (headTextRef.current && !questionText.length) {
            missedFieldsStateService.addElement(headTextRefId, headTextRef);
        }
        if (questionText.length && missedFieldsStateService.elements[headTextRefId]) {
            missedFieldsStateService.removeElement(headTextRefId);
        }
    }, [questionText.length]);

    useEffect(() => {
        if (selectRef.current && !question.type) {
            missedFieldsStateService.addElement(selectRefId, selectRef);
        }
        if (question.type && missedFieldsStateService.elements[selectRefId]) {
            missedFieldsStateService.removeElement(selectRefId);
        }
    }, [question]);

    useEffect(() => {}, [questionText.length, question]);

    useEffect(() => {
        const refTextId = `${question._id + question.type}`;
        setHeadTextRefId(refTextId);
        if (headTextRef.current && !questionText.length) {
            missedFieldsStateService.addElement(refTextId, headTextRef);
        }

        const refSelectId = `${question._id + question.type}select`;
        setSelectRefId(refSelectId);
        if (selectRef.current && !question.type) {
            missedFieldsStateService.addElement(refSelectId, selectRef);
        }
    }, []);

    return (
        <div className={style.wrapper}>
            <div
                className={style.head}
                ref={headTextRef}>
                <div className={cn(style.headInfo, { [style.error]: !questionText.length })}>
                    <div className={style.info} >
                        <textarea
                            name='question'
                            rows={1}
                            ref={textareaRef}
                            value={questionText}
                            onInput={handleChange}
                            onBlur={() => updateQuestion({ questionId: question._id, text: questionText.trim() })}
                            className={style.input}
                            placeholder={ t('question.question') } />
                    </div>
                    <div>
                        {question.files &&
                            <div className={style.attachmentsList}>
                                <AttachedElements
                                    questionId={question._id}
                                    categoryId={question.category}
                                    elements={question.files}
                                    type={AttachedElementType.questionFile} />
                            </div>}
                        {question.links &&
                            <div className={style.attachmentsList}>
                                <AttachedElements
                                    questionId={question._id}
                                    categoryId={question.category}
                                    elements={question.links}
                                    type={AttachedElementType.questionLink} />
                            </div>}
                    </div>
                </div>
                <div className={style.attachments}>
                    {!addLinkPanelIsActive &&
                        <>
                            <UploadInput
                                accept={['image/*', 'audio/*', 'video/*', '.pdf', '.doc', '.docx']}
                                setFile={setFile}>
                                <div className={style.addButton}>
                                    <AddButton icon={attachImg} text={ t('question.addFile') } onClick={() => {}} />
                                </div>
                                <Notification
                                    text={ t('savedSuccess') }
                                    active={fileSavedNotificIsActive}
                                    initialDisplay='flex'
                                    onClose={() => setFileSavedNotificIsActive(false)}
                                    position={{ top: -7, right: 34 }} />
                            </UploadInput>
                            <div className={style.addButton}>
                                <AddButton
                                    icon={linkImg}
                                    text={ t('question.addLink') }
                                    onClick={() => setAddLinkPanelIsActive(true)} />
                            </div>
                        </>}
                    {addLinkPanelIsActive && <AddLinkPanel
                        questionId={question._id}
                        categoryId={question.category}
                        initialLinks={question.links}
                        onClose={() => setAddLinkPanelIsActive(false)} />}
                </div>
                <div style={{ position: 'relative', marginBottom: 15}} >
                    <ErrorText text={!questionText.length ? t('question.enterQuestion') : ''} />
                </div>
                <div style={{ position: 'relative' }} >
                    <ErrorText text={ fileUploadErr ? t('question.addFileErr') : ''} />
                </div>
            </div>
            <div
                ref={selectRef}
                className={style.selectPanel}>
                <Select
                    type='question'
                    initialCheck={true}
                    initialValue={selectOptions.find(item => item.value === question.type)}
                    title={ t('question.questionType') }
                    noValueError={ t('question.chooseType') }
                    options={selectOptions}
                    onSelect={setQuestionType} />
                <div className={style.switcher}>
                    <Switcher
                        title={ t('question.mandatory') }
                        initialState={question.isRequired}
                        onSwitch={updateRequiredStatus} />
                    {question.type === QuestionTypesEnum.open &&
                        <div style={{ marginTop: '10px' }}>
                            <Switcher
                                title={ t('question.allowAddingFiles') }
                                initialState={question.uploadResultFileIsAvailable}
                                onSwitch={updateuploadResultFile} />
                        </div>}
                </div>
            </div>
        </div >
    )
}

export default observer(QuestionHead);