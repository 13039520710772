import { useEffect, useState } from 'react';
import { Attachments } from '../../test-passing';
import style from './CheckResultQuestion.module.scss';
import { IQuestion, IQuestionOption } from '../../../types';
import {
    checkboxCheckedIcon, checkboxIcon, radioChecked,
    radioUncheckedPassingIcon, rightAnswer, wrongAnswer
} from '../../../images';
import { useTranslation } from 'react-i18next';

interface ICheckResultQuestionOptionsProp {
    question: IQuestion;
}

function CheckResultQuestionOptions({ question }: ICheckResultQuestionOptionsProp) {
    const [multipleCurrect, setMultipleCorrect] = useState<boolean>(false);

    const { t } = useTranslation();

    function defineIcon(item: IQuestionOption) {
        if (!question.answers || !item._id) return;
        const itemIsChecked = question.answers[0]?.checkedOptions?.find(el => el.optionId === item._id);
        if (multipleCurrect) {
            if (itemIsChecked) return checkboxCheckedIcon;
            else return checkboxIcon;
        }
        else if (!multipleCurrect) {
            if (itemIsChecked) return radioChecked;
            else return radioUncheckedPassingIcon;
        }
    }

    function getRightAnswers () {
        const correctOptions = question.options.filter((item) => item.isCorrect);
        const correctOptionsTextArray = correctOptions.map(option => option.text);

        const rightOptions = `${
            t('test.rightOption', { count: correctOptions.length })
        }${correctOptionsTextArray.join('; ')}`

        return rightOptions;
    }

    useEffect(() => {
        if (question.options.filter(item => item.isCorrect).length > 1) setMultipleCorrect(true);
    }, []);

    return (
        <div>
            {question.options.map((item, index) =>
                <div key={index} className={style.optionItem}>
                    <img alt='' src={defineIcon(item)} />
                    <div className={style.optionContent}>
                        <div className={style.text}>{item.text}</div>
                        {item.file && <Attachments files={[item.file]} isOption={true} />}
                    </div>
                    <div className={style.correctIcon}>
                        {((question.answers
                            )
                            && item.isCorrect)
                            ? <img alt='' src={rightAnswer} />
                            : <img alt='' src={wrongAnswer} />}
                    </div>
                </div>
            )}
            <div> { getRightAnswers() } </div>
        </div>
    )
}

export default CheckResultQuestionOptions;