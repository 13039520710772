import { closeIcon } from '../../../../images';
import style from './AssignTutorPopup.module.scss';

interface IAssingedTutorElementProp {
    email: string;
    onDelete: () => void;
}

function AssingedTutorElement({ email, onDelete }: IAssingedTutorElementProp) {
    return (
        <div className={style.element}>
            <img className={style.clearBtn} onClick={onDelete} src={closeIcon} alt='' />
            <div>{email}</div>
        </div>
    )
}

export default AssingedTutorElement;