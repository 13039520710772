import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import style from './InfoPanel.module.scss';
import { viewService } from '../../../services';
import { ContentViewTypeEnum } from '../../../types/enums';
import { tileIcon, viewIcon } from '../../../images';
import PathElement from './path-element/PathElement';
import { pathStateService, viewStateService } from '../../../state';

function InfoPanel() {
    return (
        <div className={style.panel}>
            <div className={style.path}>
                {pathStateService.path.length > 1 &&
                    <>{toJS(pathStateService.path.map((item, index) =>
                        <PathElement key={index} pathElement={item} />))}</>}
            </div>
            <div className={style.view}>
                {viewStateService.contentView === ContentViewTypeEnum.tile &&
                    <img
                        className={style.icon}
                        onClick={() => viewService.changeContentView(ContentViewTypeEnum.list)}
                        src={viewIcon}
                        alt='' />}
                {viewStateService.contentView === ContentViewTypeEnum.list &&
                    <img
                        className={style.icon}
                        onClick={() => viewService.changeContentView(ContentViewTypeEnum.tile)}
                        src={tileIcon}
                        alt='' />}
            </div>
        </div>
    )
}

export default observer(InfoPanel);