import { BackButton, PageWrapper } from "../../components/common";
import TutorTable from "../../components/tutor/tutor-table/TutorTable";

function TutorHandlingPage() {
  return (
    <PageWrapper backgroundColor='#E9ECF3'>
      <BackButton margin={{ top: 40 }} to={'/'} />
      <TutorTable/>
    </PageWrapper>
  );
};

export default TutorHandlingPage;