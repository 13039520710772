import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { emailRegex } from '../../../utils';
import style from './TestPassingAnswer.module.scss';
import Attachments from '../attachments/Attachments';
import { testPassingService } from '../../../services';
import { IAnswer } from '../../../types/result.types';
import { testPassingStateService } from '../../../state';
import TestPassingInput from '../test-passing-input/TestPassingInput';
import TestPassingOptions from '../test-passing-options/TestPassingOptions';
import { IQuestion, QuestionTypesEnum } from '../../../types/question.types';
import { IUpdateResultAnswerPayload } from '../../../types/test-passing.types';
import TestPassingAttachments from '../test-passing-attachments/TestPassingAttachments';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface ITestPassingAnswerProp {
    question: IQuestion;
    nextButtonClicked: boolean;
    resultId: string;
    placeholder?: string;
    initialValue?: string;
}

function TestPassingAnswer({ question, nextButtonClicked }: ITestPassingAnswerProp) {
    const [answer, setAnswer] = useState<IAnswer | undefined>(question.answers ? question.answers[0] : undefined);
    const [emailExists, setEmailExists] = useState<boolean>();
    const [fileUploadErr, setFileUploadErr] = useState<boolean>(false);

    const { t } = useTranslation();

    async function updateAnswer(payload: IUpdateResultAnswerPayload) {
        await testPassingService.updateResultAnswer(
            { questionId: question._id, updatePayload: payload })
            .then(data => {
                setAnswer(data.answer);
                if (data.emailAlreadyExists) { 
                    setEmailExists(true);
                } else {
                    setEmailExists(false);
                };

                let answeredState = false;
                if (data.answer.isRequired) {
                    answeredState = true;
                    if (!data.answer?.checkedOptions?.length && !data.answer.text?.length) {
                        answeredState = false;
                        testPassingStateService.udateRequiredQuestionsAnswer(question._id, answeredState);
                        return;
                    }
                    if (data.answer.isEmail) {
                        if (!emailRegex.test(data.answer.text || '') || data.emailAlreadyExists) answeredState = false;
                    }
                    testPassingStateService.udateRequiredQuestionsAnswer(question._id, answeredState);
                }
            })
            .catch(() => {});
    }

    async function updateAnswerFile(file?: File) {
        await testPassingService.updateResultAnswerFile({ questionId: question._id, file })
            .then((data) => { setAnswer(data); setFileUploadErr(false) })
            .catch(() => { setFileUploadErr(true) });
    }

    const checkError = () => {
        return question.isRequired && (nextButtonClicked && !Boolean(testPassingStateService.checkQuestionHasAnswer(question._id)))
    }

    return (
        <div 
            id={`question_${question._id}`}
            className={cn(style.answer, 
                { [style.error]: checkError() || fileUploadErr
        })}>
            {question.text &&
                <div className={style.text}>
                    {t(question.text)}
                    {question.isRequired && <span className={style.requeredFlag}>*</span>}
                </div>}
            <Attachments links={question.links} files={question.files} />
            {question.type === QuestionTypesEnum.open &&
                <>
                    <TestPassingInput
                        questionId={question._id}
                        isEmail={question.isEmail}
                        initialText={answer?.text}
                        isRequired={question.isRequired}
                        onChange={(value: string) => updateAnswer({ text: value })} />

                    {question.uploadResultFileIsAvailable &&
                        <TestPassingAttachments
                            questionId={question._id}
                            initialFile={answer?.file}
                            onChange={(file) => updateAnswerFile(file)} />}
                </>}

            {question.type === QuestionTypesEnum.closed &&
                <TestPassingOptions
                    correctOptionsCount={question.correctOptionsCount}
                    options={question.options}
                    initialSelect={answer?.checkedOptions}
                    isRequired={question.isRequired}
                    onChange={(options) => updateAnswer({ checkedOptions: options })} />}
            { checkError() && 
                <span className={style.requiredField}> (!) { question.isEmail && emailExists ? t('finishTestError.alreadyPassed') : t('test.answerRequired') } </span>
            }
            {
                fileUploadErr && 
                    <span className={style.requiredField}> (!) { `${t('question.addFileErr')}` } </span>
            }
        </div >
    )
}

export default observer(TestPassingAnswer);