import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import ResultCard from './ResultCard';
import style from './TestAnswersTab.module.scss';
import { resultService } from '../../../services';
import { TestPageTabEnum } from '../../../types/enums';
import { BackButton, DeletePopup } from '../../common';
import { resultStateService, testStateService } from '../../../state';
import { ReactComponent as TrashIcon } from '../../../images/bin.svg';
import { ReactComponent as ExelIcon } from '../../../images/exel.svg';
import { ReactComponent as CheckboxIcon } from '../../../images/correct-checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../images/correct-checkbox-checked.svg';
import { ReactComponent as CheckIcon } from '../../../images/check.svg';
import { useTranslation } from 'react-i18next';
import { checkApiService } from '../../../api';
import { ITutor } from '../../../types/category.types';

interface ITestAnswersTabProp {
  testId?: string;
  assignedTutors?: ITutor[] | undefined;
}

function TestAnswersTab({ testId, assignedTutors }: ITestAnswersTabProp) {
  const [tab, setTab] = useState<
    TestPageTabEnum.answers | TestPageTabEnum.result
  >(TestPageTabEnum.answers);
  const [selectPanelActive, setSelectPanelActive] = useState(false);
  const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);

  const { t } = useTranslation();

  async function getTestInfo() {
    if (!testId) return;
    await resultService.getResults(testId).catch(() => {});
  }

  async function deleteResults() {
    await resultService
      .deleteResults(resultStateService.selectedResultsIds, testId)
      .catch(() => {});
  }

  async function getSelectedResultsData() {
    checkApiService
      .getResultCheckInfoMany(resultStateService.selectedResultsIds)
      .then((data) => data)
      .catch(() => {});
  }

  async function markResultsAsChecked() {
    await resultService
      .markResults(resultStateService.selectedResultsIds)
      .catch(() => {})
      .finally(() => getTestInfo());
  }

  useEffect(() => {}, [testId]);

  useEffect(() => {
    if (resultStateService.selectedResultsIds.length)
      setSelectPanelActive(true);
    else setSelectPanelActive(false);
  }, [resultStateService.selectedResultsIds.length]);

  useEffect(() => {
    getTestInfo();
  }, []);

  return (
    <div className={style.contentWrapper}>
      {tab === TestPageTabEnum.answers && (
        <>
          {selectPanelActive && (
            <div className={style.selectedPanel}>
              <div className={style.selectedPanelCount}>
                { t('check.recordSelected', { count: resultStateService.selectedResultsIds.length }) }
              </div>
              <div
                className={style.selectedPanelItem}
                onClick={() => setDeletePopupIsActive(true)}
              >
                { t('delete') }
                <TrashIcon
                  className={style.selectedPanelIcon}
                  style={{ fill: '#491B94' }}
                />
              </div>
              <div
                className={style.selectedPanelItem}
                onClick={getSelectedResultsData}
              >
                { t('check.excelExport') }
                <ExelIcon className={style.selectedPanelIcon} />
              </div>
              <div
                className={style.selectedPanelItem}
                onClick={markResultsAsChecked}
              >
                { t('test.markChecked', { count: resultStateService.selectedResultsIds.length }) }
                <CheckIcon className={style.selectedPanelIcon} />
              </div>
            </div>
          )}

          <div className={style.answers}>
            <div className={style.cardGridContainer}>
              <div
                className={style.iconWrapper}
                onClick={() => resultStateService.setAllSelected()}
              >
                {resultStateService.results.length > 0 &&
                resultStateService.results.length ===
                  resultStateService.selectedResultsIds.length ? (
                  <CheckboxCheckedIcon />
                ) : (
                  <CheckboxIcon />
                )}
              </div>
              <div className={cn(style.cardGrid, style.head)}>
              <div>{ t('check.userName') }</div>
              <div>{ t('check.userEmail') }</div>
              <div>{ t('date') }</div>
              <div>{ t('check.points') }</div>
              <div>{ t('check.checked') }</div>
              <div>{ t('tutor') }</div>
              </div>
              <div> </div>
            </div>

            <div className={style.list}>
              {resultStateService.results.map((item) => (
                <ResultCard
                  testMaxScore={testStateService.test?.maxScore || 0}
                  key={item._id}
                  result={item}
                  assignedTutors={assignedTutors || []}
                />
              ))}
            </div>
          </div>

          <DeletePopup
            title={t('deleteMultipleRecords', { count: resultStateService.selectedResultsIds.length })}
            active={deletePopupIsActive}
            onClose={() => setDeletePopupIsActive(false)}
            onSubmit={() => {
              deleteResults();
              setDeletePopupIsActive(false);
            }}
          />
        </>
      )}

      {tab === TestPageTabEnum.result && (
        <div>
          <BackButton onClick={() => setTab(TestPageTabEnum.answers)} />
        </div>
      )}
    </div>
  );
}

export default observer(TestAnswersTab);
