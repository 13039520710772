import style from './TutorPage.module.scss';
import { BackButton, PageWrapper } from '../../components/common';
import { CheckAnswerTab } from '../../components/test';

function TutorTestPage() {
    return (
        <PageWrapper backgroundColor='#E9ECF3'>
            <div className={style.content}>
                <BackButton to='/check'/>
                <CheckAnswerTab />
            </div>
        </PageWrapper>
    )
}

export default TutorTestPage;