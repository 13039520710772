import AttachedElement from './AttachedElement';
import { testService } from '../../../services';
import { AttachedElementType } from '../../../types/enums';
import { useEffect, useState } from 'react';

interface IAttachedElementProp {
    questionId: string;
    categoryId: string;
    type: AttachedElementType;
    elements: string[];
}

function AttachedElements({ questionId, categoryId, type, elements }: IAttachedElementProp) {
    const [updatedEls, setUpdatedEls] = useState(elements);

    async function updateQuestion(elementToDelete: string) {
        switch (type) {
            case AttachedElementType.questionLink:
                const newLinksArray = elements;
                newLinksArray.splice(newLinksArray.findIndex(el => el === elementToDelete), 1)
                await testService.updateQuestion({ questionId, links: newLinksArray }, categoryId)
                    .then(() => setUpdatedEls(newLinksArray))
                    .catch(() => {});
                break;
            case AttachedElementType.questionFile:
                await testService.updateQuestion({ questionId, filesPathToDelete: [elementToDelete] }, categoryId)
                    .then(() => setUpdatedEls(prevEls => prevEls.filter((el => el !== elementToDelete))))
                    .catch(() => {});
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setUpdatedEls(elements);
    }, [elements]);

    return (
        <>
            {updatedEls.map((el, index) =>
                <AttachedElement
                    key={index}
                    element={el}
                    margin={{ top: 10 }}
                    initialLink={type === AttachedElementType.questionLink}
                    onDelete={async () => await updateQuestion(el)} />
            )}
        </>
    )
}

export default AttachedElements;