import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { pathStateService } from './state';
import { authService, viewService } from './services';
import {
  CheckResultPage, FolderPage, HomePage, LoginPage,
  RequestSentPage, TestDeletedPage, TestPage, TestPassedPage, TestPassingPage,
  TestUnavailablePage, ForbiddenPage, RequestErrorPage, TestArchivedPage,
  TutorsPage,
  TutorTestPage,
} from './pages';
import './i18n';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TutorHandlingPage from './pages/tutor-handling-page/TutorHandlingPage';
import { useAppDispatch } from './redux/store';
import { logout } from './redux/features';
import { AdminRoute, HomepageRoute, PublicRoute } from './routes';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  async function checkAuthorized() {
    await authService.checkAuthorized().catch(() => {
      dispatch(logout());
      navigate('/login', { replace: true });
    });
  }

  useEffect(() => { pathStateService.checkPathChanges(location.pathname) }, [location]);
  useEffect(() => {
    if (!location.pathname.includes('passing')) {
      checkAuthorized();
    }
    viewService.setInitialView();
    pathStateService.setInitialFolderPath();
  }, []);

  return (
    <DndProvider backend={HTML5Backend} >
      <Routes>
        <Route index path='/' element={
          <HomepageRoute>
            <HomePage />
          </HomepageRoute>
        } />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/check' element={
          <PublicRoute>
            <TutorsPage />
          </PublicRoute>
        } />
        <Route path='/check/test/:id' element={
          <PublicRoute>
            <TutorTestPage/>
          </PublicRoute>
        }/>
        <Route path='/test/:id' element={
          <AdminRoute>
            <TestPage />
          </AdminRoute>
        } />
        <Route path='/folder/:id' element={
          <AdminRoute>
            <FolderPage />
          </AdminRoute>
        } />
        <Route path='/passing' element={<TestPassingPage />} />
        <Route path='/test-passed' element={<TestPassedPage />} />
        <Route path='/request-sent' element={<RequestSentPage />} />
        <Route path='/test-deleted' element={<TestDeletedPage />} />
        <Route path='/test-unavailable' element={<TestUnavailablePage />} />
        <Route path='/test-archived' element={<TestArchivedPage />} />
        <Route path='/check/:id' element={
          <PublicRoute>
            <CheckResultPage />
          </PublicRoute>
        } />  
        <Route path='/results-unavailable' element={<ForbiddenPage/>} />
        <Route path='/request-error' element={<RequestErrorPage/>} />
        <Route path='/tutors' element={
          <AdminRoute>
            <TutorHandlingPage/>
          </AdminRoute>
        } />
      </Routes >
    </DndProvider>
  );
}

export default observer(App);
