import moment from 'moment';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITestCard } from '../../../types';
import { copyText } from '../../../utils';
import style from './TestCard.module.scss';
import { testService } from '../../../services';
import { ContentViewTypeEnum } from '../../../types/enums';
import TestCardPhoto from '../test-card-photo/TestCardPhoto';
import { selectionStateService, viewStateService } from '../../../state';
import { AssignTutorPopup, DeletePopup, Menu, Notification } from '../../common';
import { checkboxCheckedIcon, checkboxIcon, moveIcon, shareIcon, testIcon, trashIcon, tutorIcon } from '../../../images';
import { useTranslation } from 'react-i18next';

interface ITestCardProp {
    test: ITestCard;
    onReplace: (folderId?: string, testId?: string) => void;
}

function TestCard({ test, onReplace }: ITestCardProp) {
    const navigate = useNavigate();
    const [isSelected, setIsSelected] = useState(false);
    const [copyTestLinkNotificIsActive, setCopyTestLinkNotificIsActive] = useState(false);
    const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);
    const [tutorPopupIsActive, setTutorPopupIsActive] = useState(false);

    const { t } = useTranslation();

    const menuOptions = [
        { icon: tutorIcon, text: t('assignTutor'), onClick: () => setTutorPopupIsActive(true) },
        { icon: moveIcon, text: t('moveTo'), onClick: () => onReplace(undefined, test._id) },
        { icon: trashIcon, text: t('test.delete'), onClick: () => setDeletePopupIsActive(true) },
    ];

    if (!test.isArchived) {
        menuOptions.splice(3, 0, {
            icon: shareIcon,
            text: t('test.copyLink'),
            onClick: () =>
                copyText(`${process.env.REACT_APP_APP_URL}/passing?encoded=${test.link}`,
                    () => setCopyTestLinkNotificIsActive(true)),
        });
    }

    async function deleteTest() {
        await testService.deleteTests([test._id]).catch(() => {});
    }

    function handleClick(e: any) {
        const elemClass = e.target.className;
        if (!elemClass || typeof elemClass === 'object') return;
        if (elemClass.toLowerCase().includes('menu')) return;
        navigate(`/test/${test._id}`);
    }

    useEffect(() => {
        const testIsSelected = selectionStateService.checkTestSelection(test._id);
        if (testIsSelected) setIsSelected(true);
        else setIsSelected(false);
    }, [selectionStateService.testsIds.length]);

    return (<>
        {viewStateService.contentView === ContentViewTypeEnum.tile &&
            <div className={style.tile} onClick={handleClick}>
                <TestCardPhoto title={test.title} banner={test.banner} />
                <div className={style.info}>
                    <div className={style.title}>{test.title || t('test.untitled')}</div>
                    <Menu options={menuOptions} defaultPosition={{ right: 19, top: 30 }}>
                        <Notification
                            text={ t('test.linkCopied') }
                            active={copyTestLinkNotificIsActive}
                            onClose={() => setCopyTestLinkNotificIsActive(false)}
                            position={{ top: -10, right: 23 }} />
                    </Menu>
                </div>
            </div >}
        {viewStateService.contentView === ContentViewTypeEnum.list &&
            <div className={cn(style.list, { [style.isSelected]: isSelected })}>
                <img
                    className={style.selectIcon}
                    onClick={() => selectionStateService.handleTestSelection(test._id)}
                    src={isSelected ? checkboxCheckedIcon : checkboxIcon}
                    alt='' />
                <div className={style.mainInfo} onClick={handleClick}>
                    <img className={style.icon} alt='' src={testIcon} />
                    <div className={style.title}>{test.title || t('test.untitled')}</div>
                    <div></div>
                    <div className={style.createdOn}>
                        {moment(test.createdAt).format('DD-MM-YYYY hh:mm A')}
                    </div>
                    <div className={style.editedOn}>
                        {moment(test.updatedAt).format('DD-MM-YYYY hh:mm A')}
                    </div>
                </div>
                <div className={style.info}>
                    <Menu options={menuOptions} defaultPosition={{ right: 19, top: 30 }} />
                    <Notification
                        text={ t('test.linkCopied') }
                        active={copyTestLinkNotificIsActive}
                        onClose={() => setCopyTestLinkNotificIsActive(false)}
                        position={{ bottom: 25, right: 25 }} />
                </div>
            </div>}

        <DeletePopup
            title={t('confirmDeletionItem')}
            active={deletePopupIsActive}
            onClose={() => setDeletePopupIsActive(false)}
            onSubmit={() => {
                deleteTest();
                setDeletePopupIsActive(false);
            }} />
        <AssignTutorPopup
            testId={test._id}
            assignedTutors={test.assignedTutors}
            isActive={tutorPopupIsActive}
            onClose={() => setTutorPopupIsActive(false)} />
    </>)
}

export default TestCard;
