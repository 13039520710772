import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './global-style.scss';
import './fonts/fonts.scss';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Suspense } from 'react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={<>loading...</>} persistor={persistor}>
      <BrowserRouter> 
        <Suspense fallback={<>loading...</>} >
          <App /> 
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
