import cn from 'classnames';
import { useState } from 'react';
import style from './Switcher.module.scss';

interface ISwitcherProp {
    title?: string;
    initialState?: boolean;
    onSwitch: (active: boolean) => void;
}

function Switcher({ title, initialState, onSwitch }: ISwitcherProp) {
    const [active, setActive] = useState<boolean>(initialState || false);

    function handleClick() {
        setActive(!active);
        onSwitch(!active);
    }

    return (
        <div
            className={cn(style.switcher,
                { [style.active]: active, [style.notactive]: !active })}
            onClick={handleClick}>
            <div className={style.title}> {title}</div>
        </div >
    )
}

export default Switcher;
