import { toJS } from 'mobx';
import { useState } from "react";
import { ICategory } from '../../../types/category.types';
import { IQuestion } from '../../../types';
import QuestionCard from '../test-question-card/QuestionCard';
import { observer } from 'mobx-react-lite';
import CategoryHead from '../test-category-head/CategoryHead';
import { expandIcon, collapseIcon } from '../../../images';
import style from './CategoryCard.module.scss';

interface ICategoryCardProp {
  categoryIndex: number;
  category: ICategory;
  sortCards: (a: IQuestion, b: IQuestion) => number;
}

function CategoryCard({
  categoryIndex, 
  category, 
  sortCards,
}: ICategoryCardProp) {
  const [expanded, setExpanded] = useState(true);

  function handleView() {
    expanded ? setExpanded(false) : setExpanded(true);
  }

   const handleCollapse = () => {
    setExpanded(false);
  }

  return(
    <div>
      <div>
        { category.isDefault ? (<></>) : (
          <div className={style.card} >
            <img 
              className={style.expandCollapseBtn}
              onClick={handleView}
              src={expanded ? collapseIcon : expandIcon} 
              alt="" 
            />

            <CategoryHead 
              testId={category.test}
              title={category.title} 
              id={category._id} 
              isRandom={category.isRandom || false}
              handleCollapse={handleCollapse}
              orderIndex={category.orderIndex} />
          </div>
        )}
        { expanded  ?  toJS(category.questions)?.sort(sortCards).map((item: IQuestion, index) => {
          if(!item.isDefault) return (
            <div
              id={`@${item._id}`}
              key={index}> 
              <QuestionCard key={item._id} question={item} />
            </div>
          );
        }) : <></> }
      </div>
    </div>
  )
}

export default observer(CategoryCard);