import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import style from './TestPreview.module.scss';
import { radioPreview, checkboxIcon } from '../../../images';
import Attachments from '../../test-passing/attachments/Attachments';
import { IQuestion, QuestionTypesEnum } from '../../../types/question.types';
import TestPassingAttachments from '../../test-passing/test-passing-attachments/TestPassingAttachments';
import { useTranslation } from 'react-i18next';

interface ITestPreviewQuestionProp {
    question: IQuestion;
}

function TestPreviewQuestion({ question }: ITestPreviewQuestionProp) {
    const [oneCorrectOption, setOneCorrectOption] = useState<boolean>();
    const [isOnFocus, setIsOnFocus] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const { t } = useTranslation();

    useEffect(() => {
        let correctOptions = 0;
        question.options?.forEach(item => {
            if (item.isCorrect) correctOptions += 1;
        })
        if (correctOptions <= 1) setOneCorrectOption(true);
        else setOneCorrectOption(false);
    }, [])

    return (
        <div className={style.itemWrapper}>
            { question?.text && question?.type 
                ? <></> 
                : <div style={{color:'red'}} >{`(!)${t('finishTestError.requiredFields')}`}</div> 
            }
            <div className={style.text}>
                {question.text || ''}
                {question.isRequired && <span className={style.requeredFlag}>*</span>}
            </div>
            <Attachments links={question.links} files={question.files} />

            {question.type === QuestionTypesEnum.open
                && <div
                    className={cn(style.input, { [style.isOnFocus]: isOnFocus })}
                    onFocus={() => setIsOnFocus(true)}
                    onBlur={() => setIsOnFocus(false)}>
                    <textarea
                        value=''
                        ref={inputRef}
                        onChange={() => {}}
                        placeholder={ t('test.typeAnswer') } />
                </div>}

            {question.type === QuestionTypesEnum.closed
                && <div className={style.options}>
                    {(question.correctOptionsCount && question.correctOptionsCount > 1) &&
                        <div className={style.multipleCorrectFlag}>
                            * { t('test.multipleCorrect.partOne') } <span>{ t('test.multipleCorrect.partTwo') }</span> { t('test.multipleCorrect.partThree') }
                        </div>}
                    {question.options?.map((item, index) =>
                        <div className={style.option} key={index}>
                            <img src={oneCorrectOption ? radioPreview : checkboxIcon} alt='' />
                            <div className={style.optionContent}>
                                <div
                                    className={style.textOption}
                                    style={{ marginBottom: (item.text && item.file) ? '15px' : 'none' }}>
                                    {item.text}
                                </div>
                                {item.file && <Attachments files={[item.file]} isOption={true} />}
                            </div>
                        </div>)}
                </div>}
            {question.uploadResultFileIsAvailable &&
                <TestPassingAttachments questionId={question._id} onChange={() => {}} />}
        </div>
    )
}

export default TestPreviewQuestion;