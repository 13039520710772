import { makeAutoObservable } from 'mobx';
import { IPathElement } from '../types';

interface IPathElementDto {
    title: string;
    folderId: string;
}

class PathStateService {
    path: IPathElement[] = [{ title: 'Homepage', link: '/', folderId: '' }];

    constructor() { makeAutoObservable(this) }

    setInitialFolderPath() {
        const lastPath = localStorage.getItem('folder-path');
        if (lastPath) this.path = JSON.parse(lastPath);
    }

    addToPath(dto: IPathElementDto) {
        const element = {
            title: dto.title,
            folderId: dto.folderId,
            link: `/folder/${dto.folderId}`,
        }
        this.path.push(element);
        localStorage.setItem('folder-path', JSON.stringify(this.path));
    }

    updatePath(dto: IPathElementDto) {
        const activeElementIndex = this.path.findIndex(item => item.folderId === dto.folderId);
        this.path.splice((activeElementIndex + 1), 9e9);
        localStorage.setItem('folder-path', JSON.stringify(this.path));
    }

    checkPathChanges(path?: string) {
        if (path) {
            const element = this.path.find(item => item.link.includes(path));
            if (element) this.updatePath(element);
        }
    }
}

export const pathStateService = new PathStateService();
