import { AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';
import { IServerResponse } from '../types/common.types';

class ResultApiService {
    async getResults(testId: string) {
        return axiosInstance.get<IServerResponse>(`/result/list/${testId}`)
            .then((res: AxiosResponse) => res.data.data)
    }

    async getResult(resutlId: string) {
        return axiosInstance.get<IServerResponse>(`/result/${resutlId}`)
            .then((res: AxiosResponse) => res.data.data);
    }

    async deleteResults(resultsIds: string[], testId?: string) {
        return axiosInstance.post<IServerResponse>(`/result/delete`, { resultsIds, testId })
            .then((res: AxiosResponse) => res.data);
    }

    async markResults(resultsIds: string[]) {
        return axiosInstance.patch<IServerResponse>(`/result/check-mark`, { resultsIds })
            .then((res: AxiosResponse) => res.data);
    }
}

export const resultApiService = new ResultApiService();