import { useTranslation } from 'react-i18next';
import { Button } from '../../button/Button';
import Popup from '../Popup';

interface IDeletePopupProp {
    title: string;
    active: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

function DeletePopup({ title, active, onClose, onSubmit }: IDeletePopupProp) {
    const { t } = useTranslation();

    return (
        <Popup
            width={320}
            active={active}
            title={title}
            onClose={onClose} >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button text={ t('deletePopup.no') } onClick={onClose} width={125} type='outlined' />
                <Button text={ t('deletePopup.yes') } width={125} onClick={onSubmit} />
            </div>
        </Popup>
    )
}

export default DeletePopup;
