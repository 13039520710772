import cn from 'classnames';
import { useState, useEffect } from 'react';
import { QuestionTypesEnum } from '../../../types/question.types';
import style from './CheckResultQuestion.module.scss';
import { checkApiService } from '../../../api';
import { Notification } from '../../common';
import { useTranslation } from 'react-i18next';

interface ICheckResultScoreProp {
    answerId: string;
    maxScore: number;
    resultId: string;
    questionType: QuestionTypesEnum;
    initialScore?: number;
    isImmutable?: boolean;
}

function CheckResultScore({ answerId, initialScore, maxScore, isImmutable, questionType, resultId }: ICheckResultScoreProp) {
    const [score, setScore] = useState(initialScore || 0);
    const [savedNotificIsActive, setSavedNotificIsActive] = useState(false);
    const [notificText, setNotificText] = useState('');

    const { t } = useTranslation();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;
        if (value.match("^[0-9]*$") && value.length <= 2 && Number(value) <= maxScore) {
            setScore(Number(e.currentTarget.value));
        }
    }

    async function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
        await checkApiService.updateResultAnswerScore(answerId, score)
            .then(() => setNotificText(t('savedChanges')))
            .catch(() => setNotificText(t('savedError')))
            .finally(() => setSavedNotificIsActive(true));
    }

    useEffect(() => { setScore(initialScore || 0) }, [initialScore])

    return (
        <div className={style.score}>
            { t('score') }: <>
                <input
                    className={cn(style.input, { [style.isImmutable]: isImmutable })}
                    value={score}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isImmutable} />
            </> / {maxScore}


            <Notification
                text={notificText}
                active={savedNotificIsActive}
                onClose={() => setSavedNotificIsActive(false)}
                position={{ top: 10, right: -5 }} />
        </div>
    )
}

export default CheckResultScore;