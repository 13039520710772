import { ContentList, CreationPanel, InfoPanel, PageWrapper } from "../../components/common";

export default function HomePage() {

    return (
        <PageWrapper>
            <CreationPanel />
            <InfoPanel />
            <ContentList />
        </PageWrapper>
    )
}
