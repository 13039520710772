import { useNavigate } from "react-router-dom";
import { arrowIcon } from "../../../images";
import style from './BackButton.module.scss';
import { IPosition } from '../../../types';
import { useTranslation } from "react-i18next";

interface IBackButtonProp {
    to?: string;
    onClick?: () => void;
    margin?: IPosition;
}

function BackButton({ to, onClick, margin }: IBackButtonProp) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            className={style.button}
            style={{
                marginTop: `${margin?.top}px`,
                marginBottom: `${margin?.bottom}px`,
                marginRight: `${margin?.right}px`,
                marginLeft: `${margin?.left}px`,
            }}
            onClick={() => {
                if (onClick) onClick();
                if (to) navigate(to);
            }}>
            <img src={arrowIcon} alt='' />
            { t('backButton') }
        </div>
    )
}

export default BackButton;