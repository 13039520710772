import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Popup from '../Popup';
import { Button } from '../../button/Button';
import style from './MovePopup.module.scss';
import { folderApiService } from '../../../../api';
import { folderService } from '../../../../services';
import Notification from '../../notification/Notification';
import FolderSelect from '../../folder-select/FolderSelect';
import { folderStateService, pathStateService, selectionStateService } from '../../../../state';
import { useTranslation } from 'react-i18next';

interface IMovePopupProp {
    active: boolean;
    rootFolderId?: string;
    testIdToReplace?: string;
    folderIdToReplace?: string;
    onClose: (saved?: boolean) => void;
    onSave: (value?: string) => void;
}

function MovePopup({ active, testIdToReplace, folderIdToReplace, rootFolderId, onClose, onSave }: IMovePopupProp) {
    const [disabled] = useState(false);
    const [optionsFolders, setOptionsFolders] = useState([]);
    const [distFolderId, setDistFolderId] = useState<string | undefined>();
    const [elementReplacedNotificIsActive, setElementReplacedNotificIsActive] = useState(false);
    const [tutorAssignedNotificText, setTutorAssignedNotificText] = useState<string>();

    const { t } = useTranslation();

    async function getFoldersNestedContent(foldersIds: string[]) {
        const folders = await folderApiService.getFoldersNestedContent(foldersIds, rootFolderId).catch(() => {});
        return folders;
    }

    async function replaceElements(distFolderId?: string) {
        try {
            if (selectionStateService.foldersIds.length)
                await folderService.replaceFolders(selectionStateService.foldersIds, distFolderId);
            if (selectionStateService.testsIds.length)
                await folderService.replaceTests(selectionStateService.testsIds, distFolderId);
            setTutorAssignedNotificText(t('savedSuccess'));
        }
        catch {
            setTutorAssignedNotificText(t('elementReplacedNotificText.error'));
        }
        finally {
            setElementReplacedNotificIsActive(true)
        }
    }

    async function replaceOneElement(folderId?: string, testId?: string, distFolderId?: string) {
        try {
            if (folderId) await folderApiService.replaceFolders([folderId], distFolderId);
            else if (testId) await folderApiService.replaceTests([testId], distFolderId);
            setTutorAssignedNotificText(t('savedSuccess'));
            onSave(distFolderId);
        }
        catch {
            setTutorAssignedNotificText(t('elementReplacedNotificText.error'));
        }
        finally {
            setElementReplacedNotificIsActive(true);
        }
    }

    async function getOptionsFolders() {
        if (folderStateService.folder) {
            let ids: string[] = [];
            folderStateService.folder?.folders.forEach(item => {
                const itemIsSelected = selectionStateService.checkFolderSelection(item._id);
                if (!itemIsSelected) ids.push(item._id);
            })
            for (let i = 0; i < pathStateService.path.length - 1; i++) {
                if (pathStateService.path[i].folderId) ids.push(pathStateService.path[i].folderId);
            }
            if (folderIdToReplace) {
                ids = ids.filter(item => item !== folderIdToReplace);
            }
            await getFoldersNestedContent(ids).then(data => setOptionsFolders(data)).catch(() => {});
        }
        else {
            await folderApiService.getUnnestedFolders().then(data => setOptionsFolders(data)).catch(() => {});
        }
    }

    useEffect(() => {
        if (!active) return;
        getOptionsFolders();
    }, [active]);

    return (
        <Popup
            active={active}
            title={ t('moveTo') }
            onClose={() => onClose()}
            width={400}>
            <FolderSelect
                initialOptionsFolders={optionsFolders}
                onSelect={(distFolderIdValue?: string) => setDistFolderId(distFolderIdValue)} />
            <div className={style.btns}>
                <Button
                    text={ t('cancelButton') }
                    onClick={() => onClose()}
                    width={170}
                    type='outlined' />
                <Button
                    text={ t('saveButton') }
                    width={170}
                    disabled={disabled}
                    onClick={async () => {
                        if (testIdToReplace || folderIdToReplace)
                            await replaceOneElement(folderIdToReplace, testIdToReplace, distFolderId);
                        else await replaceElements(distFolderId);
                    }} />
            </div>

            <Notification
                text={tutorAssignedNotificText || ''}
                active={elementReplacedNotificIsActive}
                initialDisplay='flex'
                onClose={() => setElementReplacedNotificIsActive(false)}
                position={{ top: 10 }} />
        </Popup>
    )
}

export default observer(MovePopup);
