import { observer } from 'mobx-react-lite';
import { useEffect, useState, useRef } from 'react';
import MaxScore from '../max-score/MaxScore';
import QuestionOption from './QuestionOption';
import style from './QuestionOptions.module.scss';
import { testService } from '../../../../services';
import { addVariantIcon } from '../../../../images';
import AddButton from '../../../common/add-button/AddButton';
import { missedFieldsStateService } from '../../../../state';
import { IQuestion, IQuestionOption, OptionTypesEnum } from '../../../../types/question.types';
import { useTranslation } from 'react-i18next';

interface IQuestionCardProp {
    questionId: string;
    categoryId: string;
    options: IQuestionOption[];
    maxScore: number;
    correctOptionsCount?: number;
}

function QuestionOptions({ questionId, categoryId, correctOptionsCount, options, maxScore }: IQuestionCardProp) {
    const [type, setType] = useState<OptionTypesEnum>(OptionTypesEnum.manyCorrect);
    const [noRightOptionCheckedError, setNoRightOptionCheckedError] = useState(false);

    const optionsRef = useRef(null);
    const [optionsRefId, setOptionsRefId] = useState<string>('');

    const { t } = useTranslation();

    async function updateOptions(option: IQuestionOption) {
        if (noRightOptionCheckedError && option.isCorrect) setNoRightOptionCheckedError(false);
        await testService.updateQuestionOption(questionId, option, categoryId)
            .then((data: IQuestion) => checkRightOptionIsChoosen(data.options))
            .catch(() => {});
    }

    function checkRightOptionIsChoosen(options: IQuestionOption[]) {
        let rightOption;
        options.forEach(item => { if (item.isCorrect) rightOption = item });
        if (!rightOption) {
            setNoRightOptionCheckedError(true);
            return true;
        }
        return false;
    }

    async function addQuestionOption(questionId: string) {
        await testService.addQuestionOption(questionId, categoryId).catch(() => {});
        setTimeout(() => {
            const height = document.documentElement.scrollTop || document.body.scrollTop;
            window.scrollTo(0, height + 50);
        }, 1);
    }

    useEffect(() => {
        if (!correctOptionsCount) return;
        if (correctOptionsCount > 1) setType(OptionTypesEnum.manyCorrect);
        else if (correctOptionsCount <= 1) setType(OptionTypesEnum.oneCorrect);
    }, [correctOptionsCount]);

    useEffect(() => {
        if (optionsRef.current && noRightOptionCheckedError) {
            missedFieldsStateService.addElement(optionsRefId, optionsRef);
        }
        if (!noRightOptionCheckedError && missedFieldsStateService.elements[optionsRefId]) {
            missedFieldsStateService.removeElement(optionsRefId);
        }
    }, [noRightOptionCheckedError]);

    useEffect(() => {}, [options]);

    useEffect(() => {
        const refOptionsId = `${questionId}opt`;
        setOptionsRefId(refOptionsId);
        const error = checkRightOptionIsChoosen(options);

        if (optionsRef.current && error) {
            missedFieldsStateService.addElement(refOptionsId, optionsRef);
        }
    }, []);

    return (
        <div
            ref={optionsRef}
            className={style.options}>
            <div className={style.list}>
                {options?.map((item) =>
                    <QuestionOption
                        key={item._id}
                        categoryId={categoryId}
                        questionId={questionId}
                        option={item}
                        type={type}
                        noRightOptionCheckedError={noRightOptionCheckedError}
                        updateOptions={updateOptions} />)}
            </div>
            <div className={style.panel}>
                <AddButton
                    text={ t('question.addVariant') }
                    icon={addVariantIcon}
                    onClick={() => addQuestionOption(questionId)} />
                <MaxScore
                    questionId={questionId}
                    categoryId={categoryId}
                    initialValue={maxScore}
                    noRightOptionCheckedError={noRightOptionCheckedError} />
            </div>
        </div>
    )
}


export default observer(QuestionOptions);