import { IAnswer } from './result.types';

export interface DroppedQuestion {
    id: string;
    categoryId: string;
}

export enum QuestionTypesEnum {
    open = 'Open',
    closed = 'Closed'
}

export interface IQuestionOption {
    _id?: string;
    text?: string;
    isCorrect?: boolean;
    file?: string;
}

export interface IQuestion {
    _id: string;
    test: string;
    category: string;
    isDefault?: boolean;
    text?: string;
    isRequired: boolean;
    orderIndex: number;
    uploadResultFileIsAvailable?: boolean;
    maxScore: number;
    type?: QuestionTypesEnum;
    options: IQuestionOption[];
    correctOptionsCount?: number;
    files?: string[];
    answers?: IAnswer[];
    links?: string[];
    isEmail?: boolean;
    isName?: boolean;
}

export interface IUpdateQuestionPayload {
    questionId: string;
    text?: string;
    maxScore?: number;
    isRequired?: boolean;
    orderIndex?: number;
    type?: QuestionTypesEnum | string;
    options?: IQuestionOption[];
    links?: string[];
    filesPathToDelete?: string[];
    uploadResultFileIsAvailable?: boolean;
}

export enum OptionTypesEnum {
    oneCorrect = 'oneCorrect',
    manyCorrect = 'manyCorrect',
}

export interface IAddQuestionFilePayload {
    questionId: string;
    file: File;
}

export interface IAddQuestionOptionFilePayload extends IAddQuestionFilePayload {
    optionId: string;
}

export interface IDeleteQuestionFilePayload {
    questionId: string;
    filePath: string;
}

export interface IUpdateQuestionsOrderIndexes {
    questionId: string;
    distOrderIndex: number;
    currentQuestionOrderIndex: number;
}

export interface IUpdateQuestionInCategoryPayload {
    fromCategoryId: string,
    toCategoryId: string,
    questionId: string,
    orderIndexes: { questionId: string, orderIndex: number }[];
}