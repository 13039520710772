import { viewStateService } from '../state';
import { ContentViewTypeEnum, TestPageTabEnum } from '../types/enums';

class ViewService {
    setInitialView() {
        const contentViewType = localStorage.getItem('content-view-type');
        if (contentViewType) viewStateService.changeContentView(contentViewType as ContentViewTypeEnum);
    }

    changeContentView(viewType: ContentViewTypeEnum) {
        localStorage.setItem('content-view-type', viewType);
        viewStateService.changeContentView(viewType);
    }

    changeTestPageTab(testId: string, tab: TestPageTabEnum) {
        localStorage.setItem('test-page-tab', JSON.stringify({ testId, tab }));
        viewStateService.changeTestPageTab(tab);
    }

    setInitialTestPageTab(testId: string) {
        const testPageTab = localStorage.getItem('test-page-tab');
        if (testPageTab) {
            const testPageTabObj: { testId: string, tab: TestPageTabEnum } = JSON.parse(testPageTab);
            if (testPageTabObj.testId === testId) viewStateService.changeTestPageTab(testPageTabObj.tab);
            else this.changeTestPageTab(testId, TestPageTabEnum.questions);
        }
    }
}

export const viewService = new ViewService();