import cn from 'classnames';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { copyText } from '../../utils';
import style from './TestPage.module.scss';
import { TestPageTabEnum } from '../../types/enums';
import { testService, viewService } from '../../services';
import { resultStateService, testStateService, viewStateService } from '../../state';
import {
    AssignTutorPopup, BackButton, DeletePopup, Menu, MovePopup, Notification, PageWrapper
} from '../../components/common';
import { moveIcon, previewIcon, shareIcon, timerIcon, trashIcon, tutorIcon } from '../../images';
import { Scroll, TestAnswersTab, TestPreview, TestQuestionTab, TimerPanel } from '../../components/test';
import { useTranslation } from 'react-i18next';

function TestPage() {
    const navigate = useNavigate();
    const [movePopupIsActive, setMovePopupIsActive] = useState(false);
    const [tutorPopupIsActive, setTutorPopupIsActive] = useState(false);
    const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);
    const [timerPanelIsActive, setTimerPanelIsActive] = useState(false);
    const [copyTestLinkNotificIsActive, setCopyTestLinkNotificIsActive] = useState(false);
    const [menuOptions, setMenuOptions] = useState<{ icon: any, text: string, onClick: () => void }[]>([]);

    const { t } = useTranslation();

    async function deleteTest() {
        if (testStateService.test?._id)
            await testService.deleteTests([testStateService.test?._id])
                .then(() => navigate(`/test-deleted`))
                .catch(() => {});
    }

    function handleBackBtnClick() {
        if (viewStateService.testPageTab === TestPageTabEnum.preview && testStateService.test)
            viewService.changeTestPageTab(testStateService.test?._id, TestPageTabEnum.questions);
        else if (testStateService.test) {
            viewService.changeTestPageTab(testStateService.test?._id, TestPageTabEnum.questions);
        };
    }

    useEffect(() => {
        resultStateService.clearSelection();
        if (testStateService.test) viewService.setInitialTestPageTab(testStateService.test._id);

        const menuOptions = [
            {
                icon: previewIcon, text: t('test.preview'), onClick: () =>
                    viewService.changeTestPageTab(testStateService.test?._id || '', TestPageTabEnum.preview)
            },
            { icon: timerIcon, text: t('timer.setTimer'), onClick: () => setTimerPanelIsActive(true) },
            { icon: tutorIcon, text: t('assignTutor'), onClick: () => setTutorPopupIsActive(true) },
            { icon: moveIcon, text: t('moveTo'), onClick: () => setMovePopupIsActive(true) },
            { icon: trashIcon, text: t('test.delete'), onClick: () => setDeletePopupIsActive(true) },
        ];

        if (!testStateService.test?.isArchived && !menuOptions.find((item) => item.text === t('test.copyLink'))) {
            menuOptions.splice(3, 0, {
                icon: shareIcon,
                text: t('test.copyLink'),
                onClick: () =>
                    copyText(`${process.env.REACT_APP_APP_URL}/passing?encoded=${testStateService.test?.link}`,
                        () => setCopyTestLinkNotificIsActive(true)),
            });
            setMenuOptions(menuOptions);
        }
        else setMenuOptions(menuOptions);
    }, [testStateService.test]);

    return (
        <PageWrapper backgroundColor='#E9ECF3'>
            <BackButton
                margin={{ top: 40 }}
                to={(viewStateService.testPageTab === TestPageTabEnum.preview
                    ? undefined : testStateService.test?.parentFolder
                        ? `/folder/${testStateService.test?.parentFolder}` : '/')}
                onClick={handleBackBtnClick} />

            <div className={style.wrapper}>
                <div className={style.head}>
                    {viewStateService.testPageTab !== TestPageTabEnum.preview && <>
                        <div
                            className={cn(style.tab, { [style.active]: viewStateService.testPageTab === TestPageTabEnum.questions })}
                            onClick={() => viewService.changeTestPageTab(testStateService.test?._id || '', TestPageTabEnum.questions)}>
                            { t('question.questions') }
                        </div>
                        <div
                            className={cn(style.tab, { [style.active]: viewStateService.testPageTab === TestPageTabEnum.answers })}
                            onClick={() => viewService.changeTestPageTab(testStateService.test?._id || '', TestPageTabEnum.answers)}>
                            { t('question.answers') }
                        </div>
                        <Menu options={menuOptions} defaultPosition={{ right: 1, top: 26 }} />
                        <TimerPanel
                            active={timerPanelIsActive}
                            initialTime={testStateService.test?.time}
                            onClose={() => setTimerPanelIsActive(false)} />
                        <Notification
                            text={ t('test.linkCopied') }
                            active={copyTestLinkNotificIsActive}
                            onClose={() => setCopyTestLinkNotificIsActive(false)}
                            position={{ top: 15, right: 0 }} />
                    </>}
                </div>

                {viewStateService.testPageTab === TestPageTabEnum.questions && <TestQuestionTab />}
                {viewStateService.testPageTab === TestPageTabEnum.answers &&
                    <TestAnswersTab 
                        testId={testStateService.test?._id} 
                        assignedTutors={testStateService.test?.assignedTutors} />}
                {viewStateService.testPageTab === TestPageTabEnum.preview && <TestPreview />}

                <MovePopup
                    active={movePopupIsActive}
                    rootFolderId={testStateService.test?.parentFolder}
                    testIdToReplace={testStateService.test?._id}
                    onSave={(value?: string) => { testStateService.updateTest({ parentFolderId: value }) }}
                    onClose={() => setMovePopupIsActive(false)} />

                {testStateService.test &&
                    <AssignTutorPopup
                        testId={testStateService.test._id}
                        assignedTutors={testStateService.test.assignedTutors}
                        isActive={tutorPopupIsActive}
                        onClose={() => setTutorPopupIsActive(false)} />}

                <DeletePopup
                    title={t('test.confirmDeletion')}
                    active={deletePopupIsActive}
                    onClose={() => setDeletePopupIsActive(false)}
                    onSubmit={() => {
                        deleteTest();
                        setDeletePopupIsActive(false);
                    }} />
            </div>
            <Scroll />
        </PageWrapper >
    )
}

export default observer(TestPage);
