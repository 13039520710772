import cn from 'classnames';
import { useState, useEffect, useRef, useCallback } from "react";
import style from './FolderSelect.module.scss';
import { selectArrowImg } from "../../../images";
import FolderSelectOption from './FolderSelectOption';
import { useTranslation } from 'react-i18next';

interface IOption {
    _id?: string;
    title: string;
    folders: { _id?: string, title: string, folders: string[] }[];
}

interface IFolderSelectProp {
    initialOptionsFolders: IOption[];
    onSelect: (distFolderId?: string) => void;
}

function FolderSelect({ initialOptionsFolders, onSelect }: IFolderSelectProp) {
    const [isActive, setIsActive] = useState<boolean>();
    const [selected, setSelected] = useState<IOption>(initialOptionsFolders[0]);
    const selectRef = useRef(null);

    const { t } = useTranslation();

    function handleClick() {
        setIsActive(!isActive);
    }

    function handleSelect(item: IOption) {
        setSelected(item);
        onSelect(item._id);
    }

    const checkSelected = useCallback((e: any) => {
        // @ts-ignore
        if (!selectRef?.current?.contains(e.target)) setIsActive(false);
    }, []);


    useEffect(() => {
        if (isActive) document.addEventListener('click', checkSelected);
        else document.removeEventListener('click', checkSelected);
    }, [isActive]);

    useEffect(() => {
        setSelected(initialOptionsFolders[0]);
        onSelect(initialOptionsFolders[0]?._id);
    }, [initialOptionsFolders.length]);

    return (
        <div
            className={cn(style.dropdown, { [style.active]: isActive })}
            ref={selectRef}
            onClick={handleClick}>
            <div className={cn(style.dropdownBtn)} >
                <span>{ selected?.title === 'Homepage' ? t(selected?.title) : selected?.title }</span>
                <img
                    className={cn(style.dropdownBtnIcon, { [style.open]: isActive })}
                    src={selectArrowImg}
                    alt='' />
            </div>
            <div
                className={style.dropdownContentWrapper}
                style={{ display: isActive ? 'block' : 'none' }} >
                <div className={style.dropdownContent}>

                    {initialOptionsFolders?.map(item =>
                        <FolderSelectOption
                            key={item._id}
                            folderId={item._id}
                            folders={item.folders}
                            title={item.title === 'Homepage' ? t(item.title) : item.title}
                            onSelect={handleSelect} />)}
                </div>
            </div>
        </div >
    );
}

export default FolderSelect;