import { AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';
import {
    IAddQuestionFilePayload,
    IAddQuestionOptionFilePayload,
    IQuestion,
    IUpdateQuestionInCategoryPayload,
    IUpdateQuestionPayload
} from '../types/question.types';
import { IServerResponse } from '../types/common.types';

class QuestionApiService {
    async createQuestion(categoryId: string, testId: string) {
        return axiosInstance.post<IQuestion>('/question/create', { categoryId, testId })
            .then((res: AxiosResponse) => res.data.data)
    }

    async updateQuestion(payload: IUpdateQuestionPayload) {
        return axiosInstance.patch<IQuestion>('/question', { payload })
            .then((res: AxiosResponse) => res.data.data)
    }

    async updateQuestionsOrder(orderIndexes: { questionId: string, orderIndex: number }[]) {
        return axiosInstance.patch<IServerResponse>('/question/order', { orderIndexes })
            .then((res: AxiosResponse) => res.data);
    }

    async updateQuestionOrderInCategory(payload: IUpdateQuestionInCategoryPayload) {
        return axiosInstance.patch<IServerResponse>('/question/category-order', { payload })
            .then((res: AxiosResponse) => res.data);
    }

    async moveQuestion(fromCategoryId: string, toCategoryId: string, questionId: string) {
        return axiosInstance.patch<IServerResponse>('/question/move', { fromCategoryId, toCategoryId, questionId })
            .then((res: AxiosResponse) => res.data.data);
    }

    async addQuestionFile(payload: IAddQuestionFilePayload) {
        return axiosInstance.post<IQuestion>('/question/files', payload)
            .then((res: AxiosResponse) => res.data.data)
    }

    async addQuestionOptionFile(payload: IAddQuestionOptionFilePayload) {
        return axiosInstance.post<IQuestion>('/question/option-files', payload)
            .then((res: AxiosResponse) => res.data.data)
    }

    async deleteQuestion(testId: string, questionId: string, categoryId: string) {
        return axiosInstance.post<IServerResponse>('/question/delete', { testId, questionId, categoryId })
            .then((res: AxiosResponse) => res.data)
    }
}

export const questionApiService = new QuestionApiService();