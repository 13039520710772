import { ReactNode } from 'react';
import style from './Prompt.module.scss';
import { promptArrowIcon } from '../../../images';
import { IPosition } from '../../../types';
import { ReactComponent as PromptArrowIcon } from '../../../images/prompt-arrow.svg';

interface IPromptProp {
    children: ReactNode;
    isActive?: boolean;
    text: string;
    color?: string;
    textPosition?: IPosition;
    arrowPosition?: IPosition;
}

function Prompt({ children, isActive = true, text, color, textPosition, arrowPosition }: IPromptProp) {
    return (
        <div className={style.prompt} >
            {isActive && <div
                className={style.promptContent}
                style={{
                    top: `${textPosition?.top}px`,
                    bottom: `${textPosition?.bottom}px`,
                    right: `${textPosition?.right}px`,
                    left: `${textPosition?.left}px`,
                    background: color ? color : '#ECEEF2',
                }} >
                <PromptArrowIcon
                    className={style.promptArrow}
                    src={promptArrowIcon}
                    alt=''
                    style={{
                        top: `${arrowPosition?.top}px`,
                        bottom: `${arrowPosition?.bottom}px`,
                        right: `${arrowPosition?.right}px`,
                        left: `${arrowPosition?.left}px`,
                        fill: color ? color : '#ECEEF2',
                    }} />
                {text}
            </div>}
            {children}
        </div>
    )
}

export default Prompt;