import { observer } from 'mobx-react-lite';
import style from './PageWrapper.module.scss';
import Header from '../header/Header';

interface IAuthPageWrapperProp {
    children: React.ReactNode;
    backgroundColor?: string;
}

function PageWrapper({ children, backgroundColor }: IAuthPageWrapperProp) {

    return (
        <div className={style.wrapper} style={{ backgroundColor: backgroundColor ?? '#fff' }}>
            <Header/>
            {children}
        </div>
    )
}

export default observer(PageWrapper);
