import { useTranslation } from 'react-i18next';
import { InfoPageWrapper } from '../../components/common';

function RequestSentPage() {
    const { t } = useTranslation();

    return (
        <InfoPageWrapper
            largeText={ t('request.sent') }
            smallText={ t('request.contact') } />
    )
}

export default RequestSentPage;