import { folderApiService } from '../api';
import { folderStateService } from '../state/folder.state';
import { IContentHomePage, ICreateFolderPayload, IFolder, IFolderContent, IUpdateFolderPayload } from '../types/folder.types';

class FolderService {
    async getFolderContent(folderId?: string) {
        try {
            const folder: IFolderContent | IContentHomePage = await folderApiService.getFolderContent(folderId);
            folderStateService.setFolder(folder);
            return Promise.resolve(folder);
        }
        catch {
            return Promise.reject();
        }
    }

    async createFolder(payload: ICreateFolderPayload) {
        try {
            const folder: IFolder = await folderApiService.createFolder(payload);
            folderStateService.addFolder(folder);
            return Promise.resolve(folder);
        }
        catch {
            return Promise.reject();
        }
    }

    async updateFolderTitle(payload: IUpdateFolderPayload) {
        try {
            const updatedFolder: IFolder = await folderApiService.updateFolderTitle(payload);
            folderStateService.updateFolderTitle(updatedFolder.title);
            return Promise.resolve(updatedFolder);
        }
        catch {
            return Promise.reject();
        }
    }

    async replaceTests(testsIds: string[], distFolderId?: string) {
        try {
            const success = await folderApiService.replaceTests(testsIds, distFolderId);
            if (!success) throw Error();
            folderStateService.deleteTests(testsIds);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async replaceFolders(foldersIds: string[], distFolderId?: string,) {
        try {
            const success = await folderApiService.replaceFolders(foldersIds, distFolderId);
            if (!success) throw Error();
            folderStateService.deleteFolders(foldersIds);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async deleteFolders(foldersIds: string[]) {
        try {
            await folderApiService.deleteFolders(foldersIds);
            folderStateService.deleteFolders(foldersIds);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }
}

export const folderService = new FolderService();