import { toJS } from 'mobx';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Panel from '../panel/Panel';
import { TestCard } from '../../test';
import { FolderCard } from '../../folder';
import style from './ContentList.module.scss';
import { DeletePopup, MovePopup } from '../index';
import { ContentViewTypeEnum } from '../../../types/enums';
import { folderService, testService } from '../../../services';
import { checkboxCheckedIcon, checkboxIcon } from '../../../images';
import { folderStateService, selectionStateService, viewStateService, } from '../../../state';
import { useTranslation } from 'react-i18next';

function ContentList() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedMenuActive, setSelectedMenuActive] = useState(false);
    const [movePopupIsActive, setMovePopupIsActive] = useState(false);
    const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);

    const { t } = useTranslation();

    const selectedMenuOptions = [
        { title: t('delete'), onClick: async () => setDeletePopupIsActive(true) },
        { title: t('moveTo'), onClick: () => setMovePopupIsActive(true) },
    ]

    async function getContent() {
        setLoading(true);
        await folderService.getFolderContent(id).catch(() => {});
        setLoading(false);
    }

    async function deleteElements() {
        if (selectionStateService.foldersIds.length)
            await folderService.deleteFolders(selectionStateService.foldersIds).catch(() => {});
        if (selectionStateService.testsIds.length)
            await testService.deleteTests(selectionStateService.testsIds).catch(() => {});
        selectionStateService.removeFullSelection();
    }

    async function replaceOneElement(folderId?: string, testId?: string, distFolderId?: string) {
        if (folderId) selectionStateService.setSelectedFolder(folderId);
        else if (testId) selectionStateService.setSelectedTest(testId);
        setMovePopupIsActive(true);
    }

    useEffect(() => {
        if (selectionStateService.foldersIds.length
            || selectionStateService.testsIds.length) setSelectedMenuActive(true);
        else setSelectedMenuActive(false);
    }, [selectionStateService.selectedElementsCount]);

    useEffect(() => {
        selectionStateService.removeFullSelection();
        getContent();
    }, [id]);

    return (
        <div className={cn({
            [style.tileView]: viewStateService.contentView === ContentViewTypeEnum.tile,
            [style.listView]: viewStateService.contentView === ContentViewTypeEnum.list,
        })}>
            {(folderStateService.folder?.folders?.length === 0
                && folderStateService.folder?.tests?.length === 0)
                ? <div className={style.emptyMessage}>{ t('contentList.emptyFolder') }</div>
                : <>
                    {viewStateService.contentView === ContentViewTypeEnum.list &&
                        <div className={style.listViewHead}>
                            <img
                                className={style.selectAllIcon}
                                onClick={() => selectionStateService.handleFullSelection()}
                                src={selectionStateService.fullSelection ? checkboxCheckedIcon : checkboxIcon}
                                alt='' />
                            <div></div>
                            <div>{ t('contentList.title') }</div>
                            <div></div>
                            <div>{ t('contentList.createdOn') }</div>
                            <div>{ t('contentList.editedOn') }</div>
                            <div></div>
                        </div>}
                    {toJS(folderStateService.folder?.folders)?.map(item =>
                        <FolderCard key={item._id} folder={item} onReplace={replaceOneElement} />)}
                    {toJS(folderStateService.folder?.tests?.map(item =>
                        <TestCard key={item._id} test={item} onReplace={replaceOneElement} />))}
                </>}

            {selectedMenuActive &&
                <div className={style.panelContainer}>
                    <Panel
                        keepActive={selectedMenuActive} >
                        <div className={style.selectedMenu}>
                            <div className={style.selectedMenuCount}>
                                { t('contentList.itemsSelected', { count: selectionStateService.selectedElementsCount }) }
                            </div>
                            {selectedMenuOptions.map(item =>
                                <div
                                    key={item.title}
                                    className={style.selectedMenuOption}
                                    onClick={() => item.onClick()}>
                                    {item.title}
                                </div>)}
                        </div>
                    </Panel>
                </div>}

            <MovePopup
                active={movePopupIsActive}
                rootFolderId={id}
                onSave={() => setMovePopupIsActive(false)}
                onClose={() => {
                    selectionStateService.removeFullSelection();
                    setMovePopupIsActive(false);
                }} />

            <DeletePopup
                title={ t('contentList.confirmDeletion', { count: selectionStateService.selectedElementsCount }) }
                active={deletePopupIsActive}
                onClose={() => setDeletePopupIsActive(false)}
                onSubmit={() => {
                    deleteElements();
                    setDeletePopupIsActive(false);
                }} />
        </div>
    )
}

export default observer(ContentList);