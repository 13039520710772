import { makeAutoObservable, computed } from 'mobx';
import { folderStateService } from './folder.state';

class SelectionStateService {
    foldersIds: string[] = [];
    testsIds: string[] = [];

    constructor() { makeAutoObservable(this) };

    @computed get selectedElementsCount() {
        return this.foldersIds.length + this.testsIds.length;
    }

    @computed get fullSelection() {
        if (!folderStateService.folder) return false;
        const selectedItemsCount = this.foldersIds.length + this.testsIds.length;
        const itemsCount = folderStateService.folder.tests.length
            + folderStateService.folder.folders.filter(item => !item.isArchive).length;
        return selectedItemsCount && selectedItemsCount === itemsCount;
    }

    handleFolderSelection(folderId: string) {
        const selectedFolderIndex = this.foldersIds.findIndex(item => item === folderId);
        if (selectedFolderIndex >= 0) this.foldersIds.splice(selectedFolderIndex, 1);
        else this.foldersIds.push(folderId);
    }

    handleTestSelection(testId: string) {
        const selectedTestIndex = this.testsIds.findIndex(item => item === testId);
        if (selectedTestIndex >= 0) this.testsIds.splice(selectedTestIndex, 1);
        else this.testsIds.push(testId);
    }

    setSelectedTest(testId: string) {
        const selectedTest = this.testsIds.find(item => item === testId);
        if (!selectedTest) this.testsIds.push(testId);
    }

    setUnselectedTest(testId: string) {
        const selectedTestIndex = this.testsIds.findIndex(item => item === testId);
        if (selectedTestIndex >= 0) this.testsIds.splice(selectedTestIndex, 1);
    }

    setSelectedFolder(folderId: string) {
        const selectedFolder = this.foldersIds.find(item => item === folderId);
        if (!selectedFolder) this.foldersIds.push(folderId);
    }

    setUnselectedFolder(folderId: string) {
        const selectedFolderIndex = this.foldersIds.findIndex(item => item === folderId);
        if (selectedFolderIndex >= 0) this.foldersIds.splice(selectedFolderIndex, 1);
    }

    handleFullSelection() {
        if (!folderStateService.folder) return;
        const selectedItemsCount = this.selectedElementsCount;
        const itemsCount = folderStateService.folder.tests.length +
            folderStateService.folder.folders.filter(item => !item.isArchive).length;

        if (selectedItemsCount < itemsCount) {
            const foldersIds: string[] = [];
            folderStateService.folder.folders.forEach(item => !item.isArchive && foldersIds.push(item._id));
            this.foldersIds = foldersIds;
            this.testsIds = folderStateService.folder.tests.map(item => item._id);
        }
        else {
            this.foldersIds = [];
            this.testsIds = [];
        }
    }

    removeFullSelection() {
        this.foldersIds = [];
        this.testsIds = [];
    }

    checkFolderSelection(folderId: string) {
        const selectedFolder = this.foldersIds.find(item => item === folderId);
        if (selectedFolder) return true;
        else return false;
    }

    checkTestSelection(testId: string) {
        const selectedTest = this.testsIds.find(item => item === testId);
        if (selectedTest) return true;
        else return false;
    }
}

export const selectionStateService = new SelectionStateService();