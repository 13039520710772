import cn from 'classnames';
import { useEffect, useState } from 'react';
import style from './TimerLeft.module.scss';
import { timerIcon } from '../../../images';
import { useCountdown } from '../../../utils/use-count-down';
import { useTranslation } from 'react-i18next';

interface ITimerLeftProp {
    finalTime: string;
}

function TimerLeft({ finalTime }: ITimerLeftProp) {
    const [hours, minutes, seconds] = useCountdown(finalTime);
    const [countingOverdueTime, setCountingOverdueTime] = useState(false);
    const [overdueTime, setOverdueTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

    const { t } = useTranslation();

    function countOverdueTime() {
        // @ts-ignore
        let currentDate = Date.parse(new Date());
        let duration = (currentDate - Date.parse(finalTime));
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let seconds = Math.floor((duration / 1000) % 60);
        setOverdueTime({ hours, minutes, seconds });
    }

    function numbersToTimerFormat(value?: number) {
        if (!value) return '00';
        if (value < 10) return `0${value}`;
        else return value.toString();
    }

    useEffect(() => {
        if (hours < 0 && minutes < 0) {
            setInterval(() => countOverdueTime(), 10)
            setCountingOverdueTime(true);
        };
    }, [hours, minutes]);

    useEffect(() => {
        if (hours < 0 && minutes < 0) setCountingOverdueTime(true);
    }, []);

    return (
        <div className={cn(style.timer, { [style.timeIsOver]: countingOverdueTime })}>
            <div className={style.title}>
                <div>{!countingOverdueTime ? t('timer.timeLeft') : t('timer.timeOver') }</div>
                <img src={timerIcon} alt='' />
            </div>
            <div className={style.divider}></div>
            <div className={style.time}>
                {!countingOverdueTime &&
                    <>
                        {hours > 0 ? (numbersToTimerFormat(hours).toString().length < 2
                            ? `0${numbersToTimerFormat(hours)}` : numbersToTimerFormat(hours)) : '00'}
                        <> : </>
                        {minutes > 0
                            ? (numbersToTimerFormat(minutes).toString().length < 2
                                ? `0${numbersToTimerFormat(minutes)}` : numbersToTimerFormat(minutes)) : '00'}
                        <> : </>
                        {seconds > 0
                            ? (numbersToTimerFormat(seconds).toString().length < 2
                                ? `0${numbersToTimerFormat(seconds)}` : numbersToTimerFormat(seconds)) : '00'}
                    </>
                }
                {countingOverdueTime &&
                    <>- {overdueTime.hours.toString().length < 2 ? `0${overdueTime.hours}` : overdueTime.hours}
                        :
                        {overdueTime.minutes.toString().length < 2 ? `0${overdueTime.minutes}` : overdueTime.minutes}
                        :
                        {overdueTime.seconds.toString().length < 2 ? `0${overdueTime.seconds}` : overdueTime.seconds}</>}
            </div>
            <div></div>
        </div>
    )
}


export default TimerLeft;