import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import style from './TestQuestionTab.module.scss';
import { addQuestionIcon } from '../../../images';
import { testService } from '../../../services';
import { TestHead } from '../test-head/TestHead';
import { missedFieldsStateService, testStateService } from "../../../state";
import { IQuestion } from '../../../types/question.types';
import { ICategory } from '../../../types/category.types';
import CategoryCard from '../test-category-card/CategoryCard';
import { useTranslation } from 'react-i18next';
import { categoryService } from '../../../services/category.service';
import { errorPopup } from '../../common/error-popup/errorPopup';

const TestQuestionTab = observer(() => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();

    function scrollToNewElement(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    async function createQuestion() {
        const defaultCategory: ICategory = testStateService.defaultCategory;

        if (!id || !defaultCategory) return;

        await testService.createQuestion(defaultCategory._id, id)
            .then((data) => {
                if (data) {
                    setTimeout(() => {
                        scrollToNewElement(`@${data._id}`);
                    }, 1)
                }
            })
            .catch(() => {});
    };

    async function createQuestionInCategory(categoryId: string) {
        if (!id) return;

        await testService.createQuestionInCategory(categoryId, id)
            .then((data) => {
                if (data) {
                    setTimeout(() => {
                        scrollToNewElement(`@${data._id}`)
                    })
                }
            })
            .catch(() => {});
    }

    async function createCategory() {
        if (!id) return;

        await categoryService.createCategory(id)
            .then((data) => {
                if (data) {
                    setTimeout(() => {
                        scrollToNewElement(data._id);
                    }, 1)
                }
            })
            .catch(() => {});
    }

    async function getTestInfo() {
        if (!id) return;
        try {
            await testService.getTest(id);
        } catch {
            errorPopup('Error loading test');
        } finally {
            setLoading(false);
        }
    }

    function sortCards(a: IQuestion | ICategory, b: IQuestion | ICategory) {
        if (a.orderIndex > b.orderIndex) return 1;
        else return -1;
    }

    useEffect(() => { missedFieldsStateService.clearElements() }, [id]);
    useEffect(() => { getTestInfo() }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (<>
        <div>
        <TestHead
            defaultCategoryId={testStateService.defaultCategory._id}
            isRandom={Boolean(testStateService.defaultCategory.isRandom)}
        />
        <div>
            <CategoryCard 
                key={testStateService.defaultCategory._id}
                categoryIndex={-1} 
                sortCards={sortCards} 
                category={testStateService.defaultCategory}
            />
        <div className={style.items}>
            <button 
                className={style.itemBtn}
                onClick={createCategory}
            >
                { t('test.addCategory') }
            </button>
            <button 
                className={style.itemBtn}
                onClick={createQuestion}
            >
                { t('test.addQuestion') }
            </button>
        </div>
        </div>

        {toJS(testStateService.categories)?.sort(sortCards).map((item: ICategory, index) => {
            if (!item.isDefault)
                return (
                    <div
                        id={item._id}
                        key={index}   
                    >
                    <CategoryCard 
                        key={item._id} 
                        categoryIndex={index} 
                        sortCards={sortCards} 
                        category={item} 
                    />
                        <div className={style.addQuestion} >
                            <img
                                className={style.addQuestionBtn}
                                onClick={() => createQuestionInCategory(item._id)}
                                src={addQuestionIcon}
                                alt='' />
                        </div>
                    </div>
                    
                )
        })}
        </div>
    </>)
})

export default TestQuestionTab;