import { makeAutoObservable, observable } from 'mobx';
import { IQuestion, IResult, ITest } from '../types';

class ResultStateService {
  results: IResult[] = [];
  selectedAllResultData: {
    result: IResult;
    questions: IQuestion[];
    test: ITest;
  }[] = [];
  selectedResultsIds: string[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setResults(results: IResult[]) {
    this.results = results.map((result) => observable(result));
  }

  setOneSelected(resultId: string) {
    const selectedResultIndex = this.selectedResultsIds.findIndex(
      (item) => item === resultId,
    );
    if (selectedResultIndex >= 0)
      this.selectedResultsIds.splice(selectedResultIndex, 1);
    else this.selectedResultsIds.push(resultId);
  }

  setAllSelected() {
    if (this.selectedResultsIds.length < this.results.length) {
      this.selectedResultsIds = this.results.map((item) => item._id);
    } else this.selectedResultsIds = [];
  }

  setAllDataSelected(resultAllData: {
    result: IResult;
    questions: IQuestion[];
    test: ITest;
  }) {
    this.selectedAllResultData.push(resultAllData);
  }

  clearSelection() {
    this.selectedResultsIds = [];
    this.selectedAllResultData = [];
  }

  deleteResults(resultsIds: string[]) {
    this.results = this.results.filter(
      (item) => !resultsIds.includes(item._id),
    );
  }
}

export const resultStateService = new ResultStateService();
