import { useEffect, useState } from 'react';
import { attachImg } from '../../../images';
import style from './TestPassingAttachments.module.scss';
import { AddButton, AttachedElement, UploadInput } from '../../common';
import { useTranslation } from 'react-i18next';

interface ITestPassingAttachmentsProp {
    questionId: string;
    initialFile?: File | string;
    onChange: (file?: File) => void;
}

function TestPassingAttachments({ initialFile, onChange }: ITestPassingAttachmentsProp) {
    const [file, setFile] = useState<File | undefined | string>(undefined);

    const { t } = useTranslation();

    useEffect(() => { if (file && typeof file !== 'string') onChange(file) }, [file]);

    useEffect(() => { if (initialFile) setFile(initialFile) }, []);

    return (
        <div className={style.attachments}>
            <UploadInput setFile={setFile} accept={['image/*', '.pdf', '.doc', '.docx']} >
                <AddButton onClick={() => {}} icon={attachImg} text={ t('question.addFile') } />
            </UploadInput>
            {initialFile && <AttachedElement
                element={typeof initialFile == 'string' ? initialFile : ''}
                onDelete={() => {
                    setFile(undefined);
                    onChange();
                }}
                margin={{ top: 13 }} />}
        </div>
    )
}

export default TestPassingAttachments;
