import style from './FolderSelect.module.scss';

interface IOption {
    _id?: string;
    title: string;
    folders: { _id?: string, title: string, folders: string[] }[];
}

interface IFolderSelectOptionProp {
    folderId?: string;
    title: string;
    folders: { _id?: string, title: string, folders: string[] }[];
    onSelect: (item: IOption) => void;
}

function FolderSelectOption({ folderId, title, folders, onSelect }: IFolderSelectOptionProp) {

    return (
        <div
            className={style.item}
            onClick={() => onSelect({ _id: folderId, folders, title })}>
            <div className={style.itemInfo}>
                <div>{title}</div>
            </div>
        </div>
    )
}

export default FolderSelectOption;
