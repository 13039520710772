import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { closeIcon } from '../../../images';
import { Button, Input, Notification, Panel } from '../../common';
import style from './TimerPanel.module.scss';
import { testService } from '../../../services';
import { testStateService } from '../../../state';
import { useTranslation } from 'react-i18next';

interface ITimerPanelProp {
    active: boolean;
    onClose: () => void;
    initialTime?: { hours: number, minutes: number };
}

function TimerPanel({ active, initialTime, onClose }: ITimerPanelProp) {
    const [hours, setHours] = useState<string>('00');
    const [minutes, setMinutes] = useState<string>('00');
    const [savedNotificIsActive, setSavedkNotificIsActive] = useState(false);
    const [savedNotificText, setSavedkNotificText] = useState('');
    const [disableRequest, setDisableRequest] = useState(false);

    const { t } = useTranslation();

    function replaceCharacter(string: string, index: number, replacement: string) {
        return (
            string.slice(0, index) +
            replacement +
            string.slice(index + replacement.length)
        );
    }

    async function handleSave() {
        if (!testStateService.test) return;
        setDisableRequest(true);

        const time: { hours: number, minutes: number } = { hours: 0, minutes: 0 };
        if (hours[0] === '0') time.hours = Number(hours[hours.length - 1]);
        else time.hours = Number(hours);
        if (minutes[0] === '0') time.minutes = Number(minutes[minutes.length - 1]);
        else time.minutes = Number(minutes);

        await testService.updateTest({ testId: testStateService.test?._id, time })
            .then(() => setSavedkNotificText(t('savedSuccess')))
            .catch(() => setSavedkNotificText(t('savedError')))
            .finally(() => {
                setSavedkNotificIsActive(true);
                setDisableRequest(false);
            })
    }

    function numbersToTimerFormat(value?: number) {
        if (!value) return '00';
        if (value < 10) return `0${value}`;
        else return value.toString();
    }

    useEffect(() => {
        if (initialTime?.hours) {
            if (initialTime.hours < 10) setHours(`0${initialTime.hours}`);
            else setHours(String(initialTime.hours));
        }
        else setHours('00');

        if (initialTime?.minutes) {
            if (initialTime.minutes < 10) setMinutes(`0${initialTime.minutes}`);
            else setMinutes(String(initialTime.minutes));
        }
        else setMinutes('00');
    }, [initialTime]);

    return (
        <Panel
            active={active}
            onClose={onClose}
            position={{ top: 12, right: 25 }}
            width={300}>
            <div className={style.head}>
                <div></div>
                <div className={style.title}>{ t('timer.setTimer') }</div>
                <img
                    onClick={onClose}
                    src={closeIcon}
                    alt=''
                    style={{ cursor: 'pointer' }} />
            </div>
            <div className={style.inputs}>
                <div className={style.hours}>
                    <div className={style.inputsWrapper}>
                        <Input
                            value={hours[0]}
                            width={48}
                            valueType='number'
                            onChange={(value) => setHours(prev => replaceCharacter(prev, 0, value))} />
                        <Input
                            value={hours[hours.length - 1]}
                            width={48}
                            valueType='number'
                            onChange={(value) => setHours(prev => replaceCharacter(prev, prev.length - 1, value))} />
                    </div>
                    <div className={style.text}>{ t('timer.hours') }</div>
                </div>
                <div className={style.dots}>
                    <div className={style.dot}></div>
                    <div className={style.dot}></div>
                </div>
                <div className={style.minutes}>
                    <div className={style.inputsWrapper}>
                        <Input
                            value={minutes[0]}
                            width={48}
                            valueType='number'
                            onChange={(value) => setMinutes(prev => replaceCharacter(prev, 0, value))} />
                        <Input
                            value={minutes[minutes.length - 1]}
                            width={48}
                            valueType='number'
                            onChange={(value) => setMinutes(prev => replaceCharacter(prev, prev.length - 1, value))} />
                    </div>
                    <div className={style.text}>{ t('timer.minutes') }</div>
                </div>
            </div>
            <div className={style.btns}>
                <Button
                    text={ t('cancelButton') }
                    onClick={onClose}
                    width={94}
                    type='outlined' />
                <Button
                    text={ t('saveButton') }
                    onClick={handleSave}
                    width={94}
                    disabled={disableRequest
                        || ((numbersToTimerFormat(initialTime?.hours) === hours)
                            && (numbersToTimerFormat(initialTime?.minutes) === minutes))} />
            </div>

            <Notification
                text={savedNotificText}
                active={savedNotificIsActive}
                initialDisplay='flex'
                onClose={() => setSavedkNotificIsActive(false)}
                position={{ top: 10, right: 75 }} />
        </Panel>
    )
}

export default observer(TimerPanel);