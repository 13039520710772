import { makeAutoObservable } from 'mobx';
import { ContentViewTypeEnum, TestPageTabEnum } from '../types/enums';

class ViewStateService {
    contentView: ContentViewTypeEnum = ContentViewTypeEnum.tile;
    testPageTab: TestPageTabEnum = TestPageTabEnum.questions;

    constructor() { makeAutoObservable(this) }

    changeContentView(viewType: ContentViewTypeEnum) {
        this.contentView = viewType
    }

    changeTestPageTab(tab: TestPageTabEnum) {
        this.testPageTab = tab;
    }
}

export const viewStateService = new ViewStateService();