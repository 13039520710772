import { makeAutoObservable } from 'mobx';

class MissedFieldsService {
    elements: { [refId: string]: React.MutableRefObject<any> } = {};
    scrollIndex: number = -1;
    elementsKeys: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    clearElements() {
        this.elements = {};
        this.scrollIndex = -1;
        this.elementsKeys = [];
    }

    addElement(refId: string, element: React.MutableRefObject<any>) {
        if (refId && element) {
            const newElem = { [`${refId}`]: element };
            this.elements = { ...this.elements, ...newElem };
        }
        this.elementsKeys = Object.keys(this.elements);
        return { errorState: true };
    }

    removeElement(refId: string) {
        delete this.elements[refId];
        this.elementsKeys = this.elementsKeys.filter(item => item !== refId);
        return { errorState: false };
    }

    scrollUp() {
        if (this.elementsKeys[this.scrollIndex + 1]) {
            this.elements[this.elementsKeys[this.scrollIndex + 1]].current.scrollIntoView({ behavior: "smooth" });
            this.scrollIndex = this.scrollIndex + 1;
        }
    }

    scrollBack() {
        if (this.elementsKeys[this.scrollIndex - 1]) {
            this.elements[this.elementsKeys[this.scrollIndex - 1]].current.scrollIntoView({ behavior: "smooth" });
            this.scrollIndex = this.scrollIndex - 1;
        }
    }
}

export const missedFieldsStateService = new MissedFieldsService();
