import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import style from './Popup.module.scss';
import { closeIcon } from '../../../images';

interface IPopupProp {
    children: ReactNode;
    active: boolean;
    width?: number;
    title?: string;
    onClose?: () => void;
}

function Popup({ children, title, active, width, onClose }: IPopupProp) {
    const [isActive, setIsActive] = useState<boolean>(active);
    const contentRef = useRef(null);
    const closeButtonRef = useRef(null);

    const checkActive = useCallback((e: any) => {
        // @ts-ignore
        if (!contentRef?.current?.contains(e.target) || closeButtonRef.current?.contains(e.target)) {
            setIsActive(false);
            if (onClose) onClose();
        };
    }, []);

    useEffect(() => { setIsActive(active) }, [active]);
    useEffect(() => {
        if (isActive) document.addEventListener('click', checkActive);
        else document.removeEventListener('click', checkActive);
    }, [isActive]);

    return (
        <div
                className={style.popup}
            style={{ display: isActive ? 'flex' : 'none' }}>
                <div
                    className={style.content}
                    ref={contentRef}
                    style={{ width: `${width}px` }}>
                    <img
                        className={style.closeIcon}
                        ref={closeButtonRef}
                        src={closeIcon}
                        alt='' />
                    <div className={style.title}>{title}</div>
                    {children}
                </div>
        </div>
    )
}

export default Popup;