import Swal from 'sweetalert2';

export const errorPopup = (title='Some error occurred', text='Please try again or contact the administrator') => {
  return Swal.fire({
    title,
    icon: 'error',
    text,
    allowEscapeKey: true,
  });
};
