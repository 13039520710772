import { AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';
import { ICreateFolderPayload, IFolder, IFolderContent, IUpdateFolderPayload } from '../types/folder.types';

class FloderApiService {
    async getUnnestedFolders() {
        return axiosInstance.get<IFolder[]>('/folder/unnested')
            .then((res: AxiosResponse) => res.data.data)
    }

    async getFoldersNestedContent(foldersIds: string[], rootFolderId?: string,) {
        return axiosInstance.post<IFolder[]>('/folder/nested', { rootFolderId, foldersIds })
            .then((res: AxiosResponse) => res.data.data)
    }

    async getFolderContent(folderId?: string) {
        return axiosInstance.get<IFolderContent>(`/folder/${folderId ?? 0}`)
            .then((res: AxiosResponse) => res.data.data)
    }

    async createFolder(payload: ICreateFolderPayload) {
        return axiosInstance.post<IFolder[]>('/folder', payload)
            .then((res: AxiosResponse) => res.data.data)
    }

    async updateFolderTitle(payload: IUpdateFolderPayload) {
        return axiosInstance.patch('/folder', payload)
            .then((res: AxiosResponse) => res.data.data)
    }

    async deleteFolders(foldersIds: string[]) {
        return axiosInstance.post('/folder/delete', { foldersIds })
            .then((res: AxiosResponse) => res.data);
    }

    async replaceFolders(foldersIds: string[], distFolderId?: string) {
        return axiosInstance.patch('/folder/replace', { foldersIds, distFolderId })
            .then((res: AxiosResponse) => res.data)
    }

    async replaceTests(testsIds: string[], distFolderId?: string) {
        return axiosInstance.patch('/folder/replace-tests', { testsIds, distFolderId })
            .then((res: AxiosResponse) => res.data)
    }
}

export const folderApiService = new FloderApiService();