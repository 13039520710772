import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './PathElement.module.scss';
import { useEffect } from 'react';
import { pathStateService } from '../../../../state';
import { IPathElement } from '../../../../types';
import { useTranslation } from 'react-i18next';

interface IPathElementProp {
    pathElement: IPathElement;
}

function PathElement({ pathElement }: IPathElementProp) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    function handleClick() {
        pathStateService.updatePath(pathElement);
        navigate(pathElement.link);
    }

    useEffect(() => {}, [pathname]);
    return (
        <div
            className={cn(style.element,
                { [style.active]: pathElement.link === pathname })}
            onClick={handleClick}>
            {pathElement.title === 'Homepage' ?  t(pathElement.title)  :  pathElement.title }
        </div>
    )
}

export default PathElement;
