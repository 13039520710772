import { questionApiService, testApiService } from '../api';
import { folderStateService, testStateService } from '../state';
import { IGainAccessTestPayload, IUpdateTestBannerPayload } from '../types/test.types';
import { IAddQuestionFilePayload, IQuestion } from '../types/question.types';
import { IQuestionOption, IUpdateQuestionPayload, ICreateTestPayload, ITest, IUpdateTestPayload } from '../types';
import { runInAction } from 'mobx';
import { categoryApiService } from '../api/category.api-service';

class TestService {
    async getTest(testId: string): Promise<ITest> {
        try {
            const test: ITest = await testApiService.getTest(testId);
            testStateService.setTest(test);
            return Promise.resolve(test);
        }
        catch {
            return Promise.reject();
        }
    }

    async createTest(payload: ICreateTestPayload): Promise<ITest> {
        try {
            const test: ITest = await testApiService.createTest(payload);
            testStateService.setTest(test);
            return Promise.resolve(test);
        }
        catch {
            return Promise.reject();
        }
    }

    async updateTest(payload: IUpdateTestPayload) {
        try {
            testStateService.updateTest(payload);
            const updatedTest: ITest = await testApiService.updateTest(payload);
            testStateService.updateTest(updatedTest);
            // @ts-ignore
            delete updatedTest.questions;
            return Promise.resolve(updatedTest);
        }
        catch {
            return Promise.reject();
        }
    }

    async updateTestBanner(payload: IUpdateTestBannerPayload) {
        try {
            const formData = new FormData();
            if (payload.file) formData.append('file', payload.file);
            formData.append('testId', payload.testId);
            const updatedTest: ITest = await testApiService.updateTestBanner(formData as unknown as IUpdateTestBannerPayload);
            testStateService.updateTest(updatedTest);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async deleteTests(testsIds: string[]) {
        try {
            await testApiService.deleteTests(testsIds);
            folderStateService.deleteTests(testsIds);
            return Promise.resolve();
        }
        catch {
            return Promise.reject()
        }
    }

    async createQuestion(categoryId: string, testId: string) {
        try {
            const question: IQuestion = await questionApiService.createQuestion(categoryId, testId);
            testStateService.addQuestion(question);
            return Promise.resolve(question);
        }
        catch {
            return Promise.reject();
        }
    }

    async createQuestionInCategory(categoryId: string, testId: string) {
        try  { 
            const question: IQuestion =  await questionApiService.createQuestion(categoryId, testId);
            testStateService.addQuestionInCategory(question);
            return Promise.resolve(question);
        } catch {
            return Promise.reject();
        }
    }

    async addQuestionFile(payload: IAddQuestionFilePayload, categoryId: string) {
        try {
            const formData = new FormData()
            formData.append('file', payload.file);
            formData.append('questionId', payload.questionId);
            const updatedQuestion: IQuestion =
                await questionApiService.addQuestionFile(formData as unknown as IAddQuestionFilePayload);

            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(payload.questionId, updatedQuestion);
            } else {
                testStateService.updateQuestionInCategory(payload.questionId, categoryId, updatedQuestion);
            }
            
            return Promise.resolve(updatedQuestion);
        }
        catch {
            return Promise.reject();
        }
    }

    async updateQuestion(payload: IUpdateQuestionPayload, categoryId: string) {
        try {
            const updatedQuestion: IQuestion = await questionApiService.updateQuestion(payload);

            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(payload.questionId, updatedQuestion)
            } else {
                testStateService.updateQuestionInCategory(payload.questionId, categoryId, updatedQuestion);
            }

            return Promise.resolve(updatedQuestion);
        }
        catch {
            return Promise.reject();
        }
    }

    async deleteQuestion(testId: string, questionId: string, categoryId: string) {
        try {
            await questionApiService.deleteQuestion(testId, questionId, categoryId);
            if (categoryId === testStateService.defaultCategory._id) testStateService.deleteQuestion(questionId);
            else testStateService.deleteQuestionInCategory(questionId, categoryId);
            return Promise.resolve();
        }
        catch {
            return Promise.reject();
        }
    }

    async addQuestionOption(questionId: string, categoryId: string) {
        try {
            const categories = [testStateService.defaultCategory, ...testStateService.categories];
            const categoryIndex = categories.findIndex(item => item._id === categoryId);
            const questionIndex  = categories[categoryIndex].questions.findIndex(item => item._id === questionId);
    
            const options = [...categories[categoryIndex].questions[questionIndex].options, { isCorrect: false }];
            const updatedQuestion: IQuestion = await questionApiService.updateQuestion({ questionId, options });
    
            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(questionId, updatedQuestion)
            } else {
                testStateService.updateQuestionInCategory(questionId, categoryId, updatedQuestion);
            }
    
            return Promise.resolve(updatedQuestion);
        } catch {
            return Promise.reject();
        }
    }

    async updateQuestionOption(questionId: string, option: IQuestionOption, categoryId: string) {
        try {
            const categories = [ testStateService.defaultCategory, ...testStateService.categories ];
            const categoryIndex = categories.findIndex(item => item._id === categoryId);
            const questionIndex = categories[categoryIndex].questions.findIndex(item => item._id === questionId);
    
            const updatedOptions = categories[categoryIndex].questions[questionIndex].options.map(item => {
                if (item._id === option._id) return option;
                else return item;
            });
    
            const updatedQuestion: any = await questionApiService.updateQuestion({
                questionId, options: updatedOptions,
            });
    
            if (!updatedQuestion) return Promise.reject();
    
            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(questionId, updatedQuestion);
            } else {
                testStateService.updateQuestionInCategory(questionId, categoryId, updatedQuestion);
            }

            return Promise.resolve(updatedQuestion);
        } catch {
            return Promise.reject();
        }
    }

    async deleteQuestionOption(questionId: string, optionId: string, categoryId: string) {
        try {
            const categories = [ testStateService.defaultCategory, ...testStateService.categories ];
            const categoryIndex = categories.findIndex(item => item._id === categoryId);
            const questionIndex = categories[categoryIndex].questions.findIndex(item => item._id === questionId);

            const options = categories[categoryIndex].questions[questionIndex].options.filter(
                (item: IQuestionOption) => item._id !== optionId
            );

            const updatedQuestion: any = await questionApiService.updateQuestion({ questionId, options });
            if (!updatedQuestion) return Promise.reject();

            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(questionId, updatedQuestion);
            } else {
                testStateService.updateQuestionInCategory(questionId, categoryId, updatedQuestion);
            }

            return Promise.resolve(updatedQuestion);
        } catch {
            return Promise.reject();
        }
    }

    async updateQuestionsOrder(dragQuestionId: string, dropQuestionId: string, categoryId: string) {
        try {
            const categories = [testStateService.defaultCategory, ...testStateService.categories];
            const category = categories.find((item) => item._id === categoryId);
    
            const draggedQuestion = category?.questions.find((item) => item._id === dragQuestionId);
            const dropToIndex = category?.questions.findIndex((item) => item._id === dropQuestionId);
    
            if (!draggedQuestion || dropToIndex === undefined) return Promise.reject();
            const newOrderIndexes: { questionId: string, orderIndex: number }[] = [];
            
            let updatedQuestions = category?.questions.filter((item) => item._id !== dragQuestionId);
    
            updatedQuestions?.splice(dropToIndex, 0, { ...draggedQuestion, orderIndex: dropToIndex});
            updatedQuestions = updatedQuestions?.map((item, index) => { 
                newOrderIndexes.push({ questionId: item._id, orderIndex: index });
                return { ...item, orderIndex: index };
            });

            await questionApiService.updateQuestionsOrder(newOrderIndexes);
            testStateService.updateQuestionOrder(categoryId, updatedQuestions);

            return Promise.resolve();
        } catch {
            return Promise.reject();
        }
    }

    async updateQuestionOrderInCategory(dragQuestionId: string, dropQuestionId: string, fromCategory: string, toCategory: string) {
        try {
            const categories = [testStateService.defaultCategory, ...testStateService.categories];

            const deleteFromCategory = categories.find((item) => item._id === fromCategory);
            const insertToCategory = categories.find((item) => item._id === toCategory);
    
            const draggedQuestion = deleteFromCategory?.questions.find((item) => item._id === dragQuestionId);
            const dropToIndex = insertToCategory?.questions.findIndex((item) => item._id === dropQuestionId);
    
            if (!draggedQuestion || dropToIndex === undefined) return Promise.reject();
            const newOrderIndexes : { questionId: string; orderIndex: number }[] = [];
    
            const fromCategoryUpdatedQuestions = deleteFromCategory?.questions.filter((item) => item._id !== dragQuestionId);
            let toCategoryUpdatedQuestions = [...insertToCategory?.questions || []];
    
            toCategoryUpdatedQuestions?.splice(dropToIndex, 0, { ...draggedQuestion, category: toCategory, orderIndex: dropToIndex });
            toCategoryUpdatedQuestions = toCategoryUpdatedQuestions?.map((item, index) => {
                newOrderIndexes.push({ questionId: item._id, orderIndex: index });
                return { ...item, orderIndex: index };
            });
    
            await questionApiService.updateQuestionOrderInCategory(
                { 
                    fromCategoryId: fromCategory, 
                    toCategoryId: toCategory, 
                    questionId: dragQuestionId, 
                    orderIndexes: newOrderIndexes 
                }
            );
    
            testStateService.updateQuestionOrderInCategory(fromCategory, fromCategoryUpdatedQuestions, toCategory, toCategoryUpdatedQuestions);

            return Promise.resolve();
        } catch(e) {
            return Promise.reject();
        }
    }

    async updateCategoryOrder(dragCategory: string, dropCategory: string) {
        try {
            const categories = [...testStateService.categories];
            const dropToIndex = categories.findIndex((item) => item._id === dropCategory);
            const draggedCategory = categories.find((item) => item._id === dragCategory);

            if (!draggedCategory) return Promise.reject();
            const newOrderIndexes: { categoryId: string, orderIndex: number }[] = [];

            let updatedCategories;

            runInAction(() => {
                updatedCategories = categories.filter((item) => item._id !== dragCategory);
                updatedCategories.splice(dropToIndex, 0, { ...draggedCategory, orderIndex: dropToIndex });
                updatedCategories.forEach((item, index) => {
                    newOrderIndexes.push({ categoryId: item._id, orderIndex: index });
                    item.orderIndex = index;
                });
                testStateService.updateCategoryOrder(updatedCategories);
            });
            await categoryApiService.updateCategoryOrder(newOrderIndexes);
        
            return Promise.resolve();
        } catch {
            return Promise.reject();
        }
    }

    async moveQuestionToCategory (fromCategoryId: string, toCategoryId: string, questionId: string) {
        try {
            const orderIndex = await questionApiService.moveQuestion(fromCategoryId, toCategoryId, questionId);
            testStateService.moveQuestion(fromCategoryId, toCategoryId, questionId, orderIndex);
            return Promise.resolve();
        } catch {
            return Promise.reject();
        }
    }

    async applyForAccess(payload: IGainAccessTestPayload) {
        try {
            await testApiService.applyForAccess(payload);
        } catch(e) {
            return Promise.reject();
        }
    }
}

export const testService = new TestService();