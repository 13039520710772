import cn from 'classnames';
import style from './AuthButton.module.scss';

interface IAuthButtonProps {
    text: string;
    disabled?: boolean;
}

export default function AuthButton({ text, disabled = false }: IAuthButtonProps) {
    return (
        <button
            className={cn(style.button, { [style.disabled]: disabled })}
            type='submit'
            disabled={disabled}>
            {text}
        </button>
    )
}
