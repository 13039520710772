import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './TestArchivedPage.module.scss';
import { Button, ErrorText, InfoPageWrapper, Input } from '../../components/common';
import { emailRegex } from '../../utils';
import { useTranslation } from 'react-i18next';
import LocalizationSwitcher from '../../components/common/localization-switcher/LocalizationSwitcher';
import { testService } from '../../services';

function TestArchivedPage() {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const location = useLocation();
    const testTitle = location?.state?.testTitle;
    const testId = location?.state?.testId;

    const { t } = useTranslation();

    function handleEmailChange(value: string) {
        setEmailError('');
        if (!value || !value.length) {
            setEmailError(t('mandatoryField'));
        }
        const emailValidation = emailRegex.test(value);
        if (!emailValidation) setEmailError(t('invalidEmailFormat'));
        setUserEmail(value);
    }

    async function handleClick() {
        const payload = {
            email: userEmail,
            testTitle,
            testId,
        };

        await testService.applyForAccess(payload)
            .then(() => navigate('/request-sent', { replace: true }))
            .catch(() => navigate('/request-error', { replace: true }))
    }


    return (
        <InfoPageWrapper
            largeText={ t('test.unavailable.form') }
            smallText={ t('test.unavailable.contact') }>
            <div className={style.localizationSwitcher} >
            <LocalizationSwitcher />
            </div>
            <div className={style.form}>
                <Input
                    onChange={handleEmailChange}
                    value={userEmail}
                    width={330}
                    placeholder='exampleemail@gmail.com' />
                <div style={{ width: '100%' }}>
                    <ErrorText text={emailError} position='static' />
                </div>
                <Button
                    text={ t('test.unavailable.apply') }
                    onClick={handleClick}
                    width={330}
                    disabled={!!emailError.length || !userEmail.length} />
            </div>
        </InfoPageWrapper >
    )
}

export default TestArchivedPage;