import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useRef, useState, useEffect } from 'react';
import style from './QuestionOptions.module.scss';
import { useAutosizeTextArea } from '../../../../utils';
import { questionService, testService } from '../../../../services';
import { AttachedElement, Menu, Notification, Prompt, UploadInput } from '../../../common';
import { ReactComponent as CheckboxIcon } from '../../../../images/question-checkbox.svg';
import { attachImg, trashIcon, questionRadioIcon, squareCheckbox } from '../../../../images';
import { ReactComponent as CheckboxCheckedIcon } from '../../../../images/correct-checkbox-checked.svg';
import { IAddQuestionOptionFilePayload, IQuestionOption, OptionTypesEnum } from '../../../../types/question.types';
import { missedFieldsStateService } from '../../../../state';
import { useTranslation } from 'react-i18next';
import { errorPopup } from '../../../common/error-popup/errorPopup';
import useDebounce from '../../../common/use-debounce/useDebounce';

interface IQuestionOptionProp {
    questionId: string;
    categoryId: string;
    option: IQuestionOption;
    type: OptionTypesEnum;
    noRightOptionCheckedError: boolean;
    updateOptions: (option: IQuestionOption) => void;
}

function QuestionOption({ questionId, categoryId, option, type, noRightOptionCheckedError, updateOptions }: IQuestionOptionProp) {
    const [text, setText] = useState(option.text || '');
    const [inputError, setInputError] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [file, setFile] = useState();
    const [fileSavedNotificIsActive, setFileSavedNotificIsActive] = useState(false);
    const [uploadInputClick, setUploadInputClick] = useState(false);

    const optionRef = useRef(null);
    const [optionRefId, setOptionRefId] = useState<string>('');

    const debouncedText = useDebounce(text, 500);

    const { t } = useTranslation();

    useAutosizeTextArea(inputRef.current, text);

    const menuOptions = [
        {
            icon: attachImg, text: t('question.addFile'), onClick: () => {
                if (!uploadInputClick) setUploadInputClick(true);
                else {
                    setUploadInputClick(false);
                    setTimeout(() => setUploadInputClick(true));
                }
            }
        },
        {
            icon: trashIcon, text: t('question.deleteVariant'),
            onClick: async () => {
                if (option._id) await testService.deleteQuestionOption(questionId, option._id, categoryId).catch(() => {});
            }
        },
    ]

    function handleChange(e: any) {
        setInputError(false);
        if (!inputRef.current) return;
        if (e.target.value.length === 0) setInputError(true)
        const value = e.target.value.replaceAll('\n', '');
        setText(value);
    }

    // async function handleBlur() {
    //     if (!text || text?.length === 0) setInputError(true);
    //     updateOptions({ ...option, text });
    // }

    async function addOptionFile(payload: IAddQuestionOptionFilePayload) {
        await questionService.addQuestionOptionFile(payload, categoryId)
            .then(() => { 
                setFileSavedNotificIsActive(true);
             })
            .catch(() => { errorPopup(t('question.addFileErr')) });
    }

    useEffect(() => {
        if (file && option._id)
            addOptionFile({ file, optionId: option._id, questionId })
                .finally(() => setFile(undefined));
    }, [file]);

    useEffect(() => {
        if (optionRef.current && !text.length) {
            missedFieldsStateService.addElement(optionRefId, optionRef);
        }
        if (text.length && missedFieldsStateService.elements[optionRefId]) {
            missedFieldsStateService.removeElement(optionRefId);
        }
    }, [text.length]);

    useEffect(() => {
        setText(option.text || '');
        if (!option.text?.length) setInputError(true);
        const refOptionId = `${questionId + Math.random()}`;
        setOptionRefId(refOptionId);
        if (optionRef.current && !option.text?.length) {
            missedFieldsStateService.addElement(refOptionId, optionRef);
        }
    }, []);

    useEffect(() => {
        if (!text || text?.length === 0) setInputError(true);
        updateOptions({ ...option, text: debouncedText });
    }, [debouncedText]);

    return (
        <div
            ref={optionRef}
            className={style.option}>
            <div className={style.info}>
                <img
                    alt=''
                    className={style.chooseBtn}
                    src={(type === OptionTypesEnum.oneCorrect) ? questionRadioIcon :
                        (type === OptionTypesEnum.manyCorrect) ? squareCheckbox : ''} />
                <div style={{ width: '100%' }}>
                    <div className={cn(style.optionWrapper, { [style.emptyVariant]: inputError })}>
                        <textarea
                            name='questionOptions'
                            ref={inputRef}
                            rows={1}
                            value={text}
                            // onBlur={handleBlur}
                            className={style.input}
                            placeholder={inputError ? t('question.enterVariant') : t('question.variant') }
                            onInput={(e) => {
                                setInputError(false);
                                handleChange(e);
                            }} />
                        {option.file &&
                            <AttachedElement
                                element={option.file}
                                margin={{ bottom: 10 }}
                                onDelete={() => updateOptions({ ...option, file: undefined })} />}
                    </div>
                    <UploadInput
                        click={uploadInputClick}
                        setFile={setFile}
                        accept={['image/*', 'audio/*', 'video/*', '.pdf', '.doc', '.docx']}>
                        <Notification
                            text={ t('savedSuccess') }
                            active={fileSavedNotificIsActive}
                            initialDisplay='flex'
                            onClose={() => setFileSavedNotificIsActive(false)}
                            position={{ top: -1, left: 0 }} />
                    </UploadInput>
                </div>
                <Prompt
                    text={ t('question.rightAnswer') }
                    textPosition={{ bottom: 32, right: -23 }}
                    arrowPosition={{ right: 24 }}>
                    {option.isCorrect
                        ? <CheckboxCheckedIcon
                            onClick={() => updateOptions({ ...option, text, isCorrect: !option.isCorrect })} />
                        : <CheckboxIcon
                            className={cn(style.setCorrectBtn, { [style.error]: noRightOptionCheckedError })}
                            onClick={() => updateOptions({ ...option, text, isCorrect: !option.isCorrect })} />}
                </Prompt>
                <div style={{ margin: '-7px 0 0 5px' }}>
                    <Menu options={menuOptions} defaultPosition={{ bottom: 28, right: 22 }} />
                </div>
            </div>
        </div>
    )
}

export default observer(QuestionOption);
