import { observer } from 'mobx-react-lite';
import style from './Scroll.module.scss';
import { scrollArrowIcon } from '../../../images';
import { missedFieldsStateService, viewStateService } from '../../../state';
import { TestPageTabEnum } from '../../../types/enums';
import { useTranslation } from 'react-i18next';

function Scroll() {
    const { t } = useTranslation();

    return (
        <>{
            (missedFieldsStateService.elementsKeys.length
                && viewStateService.testPageTab === TestPageTabEnum.questions) ?
                <div className={style.scroll} style={{ width: '150px' }}>
                    <div
                        onClick={() => missedFieldsStateService.scrollBack()}
                        style={{ paddingBottom: '22px', cursor: 'pointer' }}>
                        {missedFieldsStateService.elementsKeys[missedFieldsStateService.scrollIndex - 1] &&
                            <img
                                alt=''
                                src={scrollArrowIcon}
                                className={style.arrow} />}
                    </div>
                    <div className={style.text}>{ t('scroll') }</div>
                    <div
                        onClick={() => missedFieldsStateService.scrollUp()}
                        style={{ paddingTop: '22px', cursor: 'pointer' }}>
                        {missedFieldsStateService.elementsKeys[missedFieldsStateService.scrollIndex + 1] &&
                            <img alt=''
                                src={scrollArrowIcon}
                                className={style.arrow}
                                style={{ transform: 'rotate(-180deg)' }} />}
                    </div>
                </div> : ''
        }
        </>
    )
}

export default observer(Scroll);