import cn from 'classnames';
import { useState } from 'react';
import style from './AuthInput.module.scss';
import { openEyeIcon, closedEyeIcon } from '../../../images';

interface IAuthInputProp {
    id?: string;
    type: 'login' | 'password';
    title: string;
    value: string;
    error?: string;
    marginBottom?: number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function AuthInput({ id, type, title, value, error, marginBottom, onChange }: IAuthInputProp) {
    const [hidden, setHidden] = useState(true);

    return (
        <div className={style.inputWrapper} style={{ marginBottom: `${marginBottom}px` }}>
            <div className={style.title}>{title}</div>
            <div className={cn(style.input, { [style.error]: error })}>
                <input
                    id={id}
                    onChange={onChange}
                    value={value}
                    autoComplete='none'
                    type={(hidden && type === 'password') ? 'password' : 'text'} />
                {type === 'password' &&
                    <img
                        alt=''
                        className={style.eye}
                        src={hidden ? openEyeIcon : closedEyeIcon}
                        onClick={() => setHidden((prev) => !prev)} />}
                {error && <div className={style.errorText}>{error}</div>}
            </div>
        </ div >
    )
}

export default AuthInput;