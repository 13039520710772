export enum TestPageTabEnum {
    questions = 'Questions',
    answers = 'Answers',
    preview = 'Preview',
    result = 'Result',
}

export enum ContentViewTypeEnum {
    tile = 'tile',
    list = 'list',
}

export enum AttachedElementType {
    questionLink = 'questionLink',
    questionFile = 'questionFile',
}
