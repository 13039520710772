import { AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';
import { IServerResponse } from '../types/common.types';

class CheckApiService {
  async getCheckContent() {
    return axiosInstance
      .get<IServerResponse>('/check')
      .then((res: AxiosResponse) => res.data.data);
  }

  async getTestToCheck(testId: string) {
    return axiosInstance
      .get<IServerResponse>(`/check/test/${testId}`)
      .then((res: AxiosResponse) => res.data.data);
  }

  async getResultCheckInfo(resultId: string) {
    return axiosInstance
      .get<IServerResponse>(`/check/${resultId}`)
      .then((res: AxiosResponse) => res.data.data);
  }

  async getResultCheckInfoMany(resultIds: string[]) {
    return axiosInstance
      .post<Blob>(`/check/many`, { resultIds }, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'answers_data.xlsx');
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 0);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  }

  async updateResultAnswerScore(answerId: string, score: number) {
    return axiosInstance
      .patch<IServerResponse>(`/check`, { answerId, score })
      .then((res: AxiosResponse) => res.data.data);
  }
}

export const checkApiService = new CheckApiService();
