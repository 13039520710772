import { useEffect, useState } from "react";
import Popup from "../Popup";
import style from './CreateTutorPopup.module.scss';
import { Button } from "../../button/Button";
import CopyIcon from '../../../../images/copy.svg';
import Notification from "../../notification/Notification";
import { useTranslation } from "react-i18next";
import { emailRegex } from "../../../../utils";

interface ICreateTutorPopupProp {
  title: string;
  active: boolean;
  showPassword: boolean;
  password: string;
  onClose: () => void;
  onSubmit: (email: string, sendEmail: boolean, copyPass: boolean) => Promise<void>;
}

function CreateTutorPopup({ title, active, showPassword, password, onClose, onSubmit }: ICreateTutorPopupProp) {
  const [sendEmail, setSendEmail] = useState(false);
  const [copyPass, setCopyPass] = useState(false);
  const [email, setEmail] = useState('');
  const [copyPassNotificIsActive, setCopyPassNotificIsActive] = useState(false);
  const [error, setError] = useState('');
  const [emailErr, setEmailErr] = useState('');

  const { t } = useTranslation();

  const handleSendEmailCheckbox = () => {
    setSendEmail(!sendEmail);
  };

  const handleCopyPassCheckbox = () => {
    setCopyPass(!copyPass);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setEmail('');
    setSendEmail(false);
    setCopyPass(false);
    setError('');
    setEmailErr('');
  };

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password)
      .then(() => {
        setCopyPassNotificIsActive(true);
      })
  };

  useEffect(() => {
    if (email) {
      if (!emailRegex.test(email)) setEmailErr('Invalid email');
      else setEmailErr('');
    }
  });

  return (
    <Popup
      width={400}
      active={active}
      title={ !showPassword ? title : t('accountsPage.password') }
      onClose={handleClose}
    >
     { !showPassword ? (
      <>
      <div className={style.checkboxContainer}>
        <div>
          <label>{t('accountsPage.enterEmail')}</label>
          <input
            id="emailInput"
            type="email"
            value={email}
            onChange={handleEmailChange}
            className={style.inputField}
            placeholder="example@gmail.com" />
          { error ? <span style={{ color: '#C50000', fontSize: 12, fontWeight: 500 }}> { error } </span> : <></> }
          { emailErr ? <span style={{ color: '#C50000', fontSize: 12, fontWeight: 500 }}> { emailErr } </span> : <></> }
        </div>
        <div>
          <div className={style.checkboxDiv}>
            <input
              id="sendEmailID"
              type="checkbox"
              readOnly
              checked={sendEmail}
              onClick={handleSendEmailCheckbox} />
            <label htmlFor='sendEmailID'>
              { t('accountsPage.sendEmail') }
            </label>
          </div>
          <div className={style.checkboxDiv}>
            <input
              id="copyPassID"
              type="checkbox"
              readOnly
              checked={copyPass}
              onClick={handleCopyPassCheckbox} />
            <label htmlFor="copyPassID">
            { t('accountsPage.copyPassword') }
            </label>
          </div>
        </div>
      </div>
      <div className={style.checkboxButtons}>
        <Button
          text={t('cancelButton')}
          type='outlined'
          onClick={handleClose} />
        <Button
          text={t('submitButton')}
          disabled={Boolean(emailErr)}
          onClick={() => { 
            if (sendEmail || copyPass) {
              onSubmit(email, sendEmail, copyPass)
              .then(() => {
                if (!copyPass) {
                  handleClose();
                }    
                setEmail('');
                setSendEmail(false);
                setCopyPass(false); 
                setError('');
                setEmailErr('');
              })
              .catch(() => {
                setError(t('errorOccurred'));
              }); 
            } else {
              setError(t('accountsPage.checkboxValidation'));
            }
          }} 
        />
      </div>
      </> 
      ) : 
      (
        <div>
          <div className={style.passDiv} >
            <input
              id="password"
              type="text"
              value={password}
              readOnly
              className={style.passField}
            />
            <img src={CopyIcon} className={style.copyPassBtn} onClick={handleCopyPassword}/>
          </div>
          <Notification 
            text={t('accountsPage.passCopied')}
            active={copyPassNotificIsActive}
            onClose={() => setCopyPassNotificIsActive(false)}
            position={{ right: 5 }}
          />
        </div>
      )
    }
    </Popup>
  );
};

export default CreateTutorPopup;