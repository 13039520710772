import { useNavigate } from "react-router-dom";
import style from './TutorTestCard.module.scss';

interface ITutorTestCardProps {
  id: string;
  title?: string;
}

function TutorTestCard({ id, title }: ITutorTestCardProps) {
  const navigate = useNavigate();

  function handleCardClick() {
    navigate(`test/${id}`);
  }

  return (
    <div
      key={id}
      onClick={handleCardClick}
      className={style.card}
    >
      { title }
    </div>
  );
}

export default TutorTestCard;