import { useTranslation } from 'react-i18next';
import { InfoPageWrapper } from '../../components/common';

function TestPassedPage() {
    const { t } = useTranslation();

    return (
        <InfoPageWrapper
            largeText={ t('test.passed') }
            mediumText={ t('test.contact') } />
    )
}

export default TestPassedPage;