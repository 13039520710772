import cn from 'classnames';
import { useState, useEffect, useRef, useCallback } from "react";
import style from './Select.module.scss';
import ErrorText from '../error-text/ErrorText';
import { radioChecked, radioUnchecked, selectArrowImg } from "../../../images";

interface ISelectOption {
    icon?: string;
    text: string;
    value: string;
}

interface ISelectProp {
    title?: string;
    initialValue?: ISelectOption;
    initialCheck?: boolean;
    options: ISelectOption[];
    noValueError?: string;
    type?: 'question' | 'common';
    onSelect: (value: string) => void;
}

function Select({ title, initialValue, initialCheck, options, noValueError, type = 'common', onSelect }: ISelectProp) {
    const [isActive, setIsActive] = useState<boolean>();
    const [error, setError] = useState(false);
    const [selected, setSelected] = useState<ISelectOption | undefined>(initialValue);
    const selectRef = useRef(null);

    function handleClick() {
        setError(false);
        setIsActive(!isActive);
    }

    const checkSelected = useCallback((e: any) => {
        // @ts-ignore
        if (!selectRef?.current?.contains(e.target)) setIsActive(false);
    }, []);

    useEffect(() => {
        if (isActive) document.addEventListener('click', checkSelected);
        else document.removeEventListener('click', checkSelected);
    }, [isActive]);

    useEffect(() => {
        if (isActive === false && !selected) setError(true);
    }, [isActive, selected]);

    useEffect(() => {
        if (initialCheck && !initialValue) setError(true);
    }, []);

    return (
        <div
            className={cn(style.dropdown, style[type], { [style.active]: isActive })}
            ref={selectRef}
            onClick={handleClick}>
            <div className={cn(style.dropdownBtn, { [style.error]: error })} >
                {
                    selected
                        ? <span>
                            {selected.icon && <img className={style.selectedIcon} src={selected.icon} alt='' />}
                            {selected.text}
                        </span>
                        : title
                }
                <img
                    className={cn(style.dropdownBtnIcon, { [style.open]: isActive })}
                    src={selectArrowImg}
                    alt='' />
            </div>
            <ErrorText text={error ? noValueError : ''} />
            <div
                className={style.dropdownContent}
                style={{ display: isActive ? 'block' : 'none' }} >
                {
                    options.map((item: ISelectOption) =>
                        <div
                            key={item.value}
                            className={style.item}
                            onClick={() => {
                                setError(false);
                                setSelected(item);
                                onSelect(item.value);
                            }}>
                            <div className={style.itemInfo}>
                                {item.icon && <img className={style.itemIcon} src={item.icon} alt='' />}
                                {item.text}
                            </div>
                            {
                                type === 'question' &&
                                <img
                                    alt=''
                                    className={style.radioIcon}
                                    src={(item.value === selected?.value) ? radioChecked : radioUnchecked} />
                            }
                        </div>)
                }
            </div>
        </div >
    );
}

export default Select;