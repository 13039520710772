import React, { ReactNode, useEffect, useRef } from 'react';
import { errorPopup } from '../error-popup/errorPopup';
import { useTranslation } from 'react-i18next';

interface IUploadInputProp {
    setFile: Function;
    children: ReactNode
    accept: string[];
    click?: boolean;
}

function UploadInput({ children, setFile, accept, click }: IUploadInputProp) {
    const ref = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {;
            if (e.target.files[0].size <= Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
                setFile(e.target.files[0]);
            } else {
                errorPopup(t('question.tooBigFile.partOne'), t('question.tooBigFile.partTwo'))
            }
        } 
    }

    const resetInput = () => {
        if (ref.current) {
            ref.current.value = '';
        }
    }

    useEffect(() => { 
        if (click) {
            ref.current?.click();
            resetInput();
        }
        
    }, [click]);

    return (
        <div onClick={() => ref.current?.click()}>
            <input
                type="file"
                ref={ref}
                multiple={false}
                style={{ display: "none" }}
                accept={`${accept.map(item => item + ',')}`}
                onChange={onChange}
                onClick={(event: React.MouseEvent<HTMLInputElement>) => { 
                    const element = event.target as HTMLInputElement;
                    element.value = '';
                 }} 
                />
            {children}
        </div>
    )
}

export default UploadInput;