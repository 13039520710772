import style from './LocalizationSwitcher.module.scss';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { setCookies } from '../../../utils';

function LocalizationSwitcher() {
  const { t, i18n } = useTranslation();

  function handleClick(language: string) {
    i18n.changeLanguage(language);
    setCookies('language', language);
  }

  function languageIsEnglish() {
    if (i18n.language === 'en') return true;
    else return false;
  }

  return (
    <div>
      <button 
        className={cn(
          style.localizationSwitcher, 
          style.left, 
          { [style.active]: languageIsEnglish(), 
            [style.inactive]: !languageIsEnglish(),
          })} 
        onClick={() => handleClick('en')}
        disabled={languageIsEnglish()}
      >
        { t('languages.EN') }
      </button>

      <button className={cn(
        style.localizationSwitcher, 
        style.right,          
        { [style.active]: !languageIsEnglish(), 
          [style.inactive]: languageIsEnglish(),
        })}
        onClick={() => handleClick('uk')}
        disabled={!languageIsEnglish()}
      >
        { t('languages.UA') }
      </button>
    </div>
  )
}

export default observer(LocalizationSwitcher);