import { ICategory } from "../types/category.types";
import { AxiosResponse } from 'axios';
import { axiosInstance } from "./axios-instance";
import { IServerResponse } from "../types/common.types";

class CategoryApiService {
  async createCategory(testId: string) {
    return axiosInstance.post<ICategory>('/category/create', { testId })
      .then((res: AxiosResponse) => res.data.data);
  }

  async updateCategory(categoryId: string, title: string) {
    return axiosInstance.post<ICategory>('/category/update', { categoryId, title })
      .then((res: AxiosResponse) => res.data.data);
  }

  async updateCategoryRandom(categoryId: string, isRandom: boolean) {
    return axiosInstance.post<ICategory>('/category/update/random', { categoryId, isRandom })
      .then((res: AxiosResponse) => res.data.data);
  }

  async updateCategoryOrder(orderIndexes: { categoryId: string, orderIndex: number }[]) {
    return axiosInstance.patch<IServerResponse>('/category/order', { orderIndexes })
      .then((res: AxiosResponse) => res.data);
  }

  async deleteCategory(categoryId: string, testId: string) {
    return axiosInstance.post<IServerResponse>('/category/delete', { categoryId, testId })
      .then((res: AxiosResponse) => res.data);
  }
}

export const categoryApiService = new CategoryApiService();