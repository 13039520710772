import axios from 'axios';
import { AxiosResponse } from 'axios';
import { removeCookies, setCookies } from '../utils';
import { authApiService } from './auth.api-service';
import { authStateService } from '../state/auth.state';
import { IRefreshTokenResponse } from '../types/auth.types';
import { store } from '../redux/store';
import { logout } from '../redux/features';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    withCredentials: true
});

const authAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    withCredentials: true,
});

const passingAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    withCredentials: true,
});

axiosInstance.interceptors.response.use(
    async (res: AxiosResponse) => res,
    async (err) => {
        const originalRequest = err.config;
        const { dispatch } = store;
        if (err.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const data: IRefreshTokenResponse | null = await authApiService.refreshTokens();
            if (!data || !data.data || !data.data.accessToken) {
                authStateService.setUnauthorized();
                dispatch(logout());
                removeCookies('accessToken');
                removeCookies('refreshToken');
                return Promise.reject(err);
            }
            setCookies('accessToken', data.data.accessToken);
            setCookies('refreshToken', data.data.refreshToken);
            return (axiosInstance(originalRequest));
        }
        return Promise.reject(err);
    }
)

passingAxiosInstance.interceptors.response.use(
    async (res: AxiosResponse) => {
        // if (res.data?.data?.passingToken) setCookies('passingToken', res.data.data.passingToken);
        return res;
    },
    async (err) => Promise.reject(err)
)

export { axiosInstance, authAxiosInstance, passingAxiosInstance };