import moment from 'moment';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResult } from '../../../types';
import { DeletePopup } from '../../common';
import style from './TestAnswersTab.module.scss';
import { resultService } from '../../../services';
import { resultStateService } from '../../../state';
import { ReactComponent as TrashIcon } from '../../../images/bin.svg';
import { ReactComponent as ConfirmIcon } from '../../../images/confirm.svg';
import { ReactComponent as CheckboxIcon } from '../../../images/correct-checkbox-result.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../images/correct-checkbox-checked.svg';
import { useTranslation } from 'react-i18next';
import { ITutor } from '../../../types/category.types';

interface IResultCardProp {
    result: IResult;
    tutorAccess?: boolean;
    assignedTutors?: ITutor[] | undefined;
    testMaxScore: number;
    testId?: string;
}

function ResultCard({ result, assignedTutors, testMaxScore, tutorAccess, testId }: IResultCardProp) {
    const navigate = useNavigate();
    const [isSelected, setIsSelected] = useState(false);
    const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);

    const { t } = useTranslation();

    async function deleteResult() {
        await resultService.deleteResults([result._id], result.test).catch(() => {});
    }

    function handleCardClick() {
        navigate(`/check/${result._id}`, { state: { testId } });
    }

    useEffect(() => {
        const resultIsSelected = resultStateService.selectedResultsIds.find(item => item === result._id);
        if (resultIsSelected) setIsSelected(true);
        else setIsSelected(false);
    }, [resultStateService.selectedResultsIds.length]);

    return (
        <div className={cn(style.card, {
            [style.isSelected]: isSelected,
            [style.cardGridContainer]: !tutorAccess,
            [style.cardGridContainerTutor]: tutorAccess,
        })}>
            <div
                className={style.iconWrapper}
                onClick={() => resultStateService.setOneSelected(result._id)}>
                {isSelected && <CheckboxCheckedIcon />}
                {!isSelected && <CheckboxIcon />}
            </div>
            <div
                onClick={handleCardClick}
                className={cn(style.cardInfo,
                    { [style.tutorAccessCardGrid]: tutorAccess, [style.cardGrid]: !tutorAccess })}>
                <div>{result.name}</div>
                <div className={style.email}>{result.email}</div>
                <div>{moment(result.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                <div>{result.score}/{testMaxScore}</div>
                <div>
                    {result.isChecked &&
                        <ConfirmIcon className={style.icon} style={{ fill: '#491B94' }} />}
                </div>
                <div className={style.tutor} style={{ whiteSpace: 'pre-line' }} >
                    {assignedTutors?.map((item) => item.login).join('\n')}
                </div>
            </div>
            {
                !tutorAccess &&
                <div
                    onClick={() => setDeletePopupIsActive(true)}
                    style={{ textAlign: 'right' }}>
                    <TrashIcon style={{ fill: '#121212' }} />
                </div>
            }
            <DeletePopup
                title={ t('deleteRecord') }
                active={deletePopupIsActive}
                onClose={() => setDeletePopupIsActive(false)}
                onSubmit={() => {
                    deleteResult();
                    setDeletePopupIsActive(false);
                }} />
        </div >
    )
}

export default observer(ResultCard);
