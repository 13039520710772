import { useEffect, useState } from 'react';
import { ErrorText } from '../../common';
import { IQuestionOption } from '../../../types';
import style from './TestPassingOptions.module.scss';
import Attachments from '../attachments/Attachments';
import { checkboxCheckedIcon, checkboxIcon, radioChecked, radioUncheckedPassingIcon } from '../../../images';
import { useTranslation } from 'react-i18next';

interface ITestPassingOptionsProp {
    correctOptionsCount?: number;
    options: IQuestionOption[];
    initialSelect?: { optionId: string }[];
    isRequired?: boolean;
    onChange: (options: { optionId: string }[]) => void;
}

function TestPassingOptions({ correctOptionsCount, options, initialSelect, isRequired, onChange }: ITestPassingOptionsProp) {
    const [selected, setSelected] = useState<IQuestionOption[]>([]);
    const [errorText, setErrorText] = useState('');

    const { t } = useTranslation();

    function handleSelect(item: IQuestionOption) {
        let newSelected: IQuestionOption[];
        if (correctOptionsCount && correctOptionsCount > 1) {
            const itemIsSelected = selected.find(elem => item._id === elem._id);
            if (itemIsSelected) {
                const filteredSelected = selected.filter(elem => item._id !== elem._id);
                newSelected = filteredSelected;
                if (!filteredSelected.length && isRequired) setErrorText(t('mandatoryField'));
            }
            else {
                newSelected = [...selected, item];
                setErrorText('');
            }
        }
        else {
            if (selected[0]?._id !== item?._id) {
                newSelected = [item];
                setErrorText('');
            }
            else {
                newSelected = [];
                if (isRequired) setErrorText(t('mandatoryField'));
            }
        };
        setSelected(newSelected);
        const newSelectedIds: { optionId: string }[] = [];
        newSelected.forEach(item => { if (item._id) newSelectedIds.push({ optionId: item._id }) });
        onChange(newSelectedIds);
    }

    useEffect(() => {
        if (initialSelect) {
            const initialSelectedList: IQuestionOption[] = [];
            initialSelect.forEach(item => {
                const option = options.find(elem => elem._id === item.optionId);
                if (option) initialSelectedList.push(option);
            });
            setSelected(initialSelectedList);
        }
    }, []);

    return (
        <div className={style.options}>
            {((correctOptionsCount ?? 0) > 1) &&
                <div className={style.multipleCorrectFlag}>
                    * { t('test.multipleCorrect.partOne') } <span>{ t('test.multipleCorrect.partTwo') }</span> { t('test.multipleCorrect.partThree') }
                </div>}
            {options.map((item) =>
                <div
                    key={item._id}
                    className={style.option}
                    onClick={() => handleSelect(item)}>
                    <div className={style.iconWrapper}>
                        <img
                            alt=''
                            className={style.icon}
                            src={((correctOptionsCount && correctOptionsCount > 1)) ?
                                (selected.find(elem => item._id === elem._id)
                                    ? checkboxCheckedIcon : checkboxIcon)
                                : (selected.find(elem => item._id === elem._id)
                                    ? radioChecked : radioUncheckedPassingIcon)} />
                    </div>
                    <div className={style.optionContent}>
                        <div
                            className={style.text}
                            style={{ marginBottom: (item.text && item.file) ? '15px' : 'none' }}>
                            {item.text}
                        </div>
                        {item.file && <Attachments files={[item.file]} isOption={true} />}
                    </div>
                </div>)}
            <div>{<ErrorText text={errorText} />} </div>
        </div >
    )
}

export default TestPassingOptions;