import cn from 'classnames';
import style from './Button.module.scss';
import { IPosition } from '../../../types';

interface IButtonProp {
    text?: string;
    icon?: string;
    disabled?: boolean;
    type?: 'main' | 'seconadry' | 'outlined';
    height?: 'medium' | 'large';
    width?: number;
    margin?: IPosition;
    ref?: any;
    onClick?: () => void;
}

export function Button({
    text,
    icon,
    type = 'main',
    height = 'large',
    width,
    disabled = false,
    margin,
    onClick }: IButtonProp) {

    return (
        <button
            className={cn(style.button,
                style[type],
                style[height],
                { [style.disabled]: disabled })}
            onClick={onClick}
            disabled={disabled}
            style={{
                width: `${width}px`,
                marginTop: `${margin?.top}px`,
                marginBottom: `${margin?.bottom}px`,
                marginRight: `${margin?.right}px`,
                marginLeft: `${margin?.left}px`,
            }}>
            {icon && <img className={style.icon} src={icon} alt='' />}
            {text}
        </button>
    )
}
