import { useEffect } from 'react';
import { logoImg } from '../../../images';
import style from './InfoPageWrapper.module.scss';

interface IInfoPageWrapperProp {
    children?: React.ReactNode;
    largeText?: string;
    mediumText?: string;
    smallText?: string;
    backgroundColor?: string;
    onRedirect?: () => void;
    redirectDelay?: number;
}

function InfoPageWrapper({
    children, largeText, mediumText, smallText, backgroundColor, onRedirect, redirectDelay }: IInfoPageWrapperProp) {

    useEffect(() => {
        if (onRedirect && redirectDelay) setTimeout(() => onRedirect(), redirectDelay);
    }, []);

    return (
        <div className={style.wrapper}
            style={{ backgroundColor: backgroundColor ?? '#E9ECF3' }}>
            <div className={style.header}>
                <div className={style.logo}>
                    <img src={logoImg} alt='' />
                    <div className={style.subText}>Surveys</div>
                </div>
            </div>

            {(largeText || mediumText || smallText) ? <div className={style.content}>
                <div className={style.largeText}>{largeText}</div>
                <div className={style.mediumText}>{mediumText}</div>
                <div className={style.smallText}>{smallText}</div>
                {children}
            </div> : <div>{children}</div>}
        </div>
    )
}

export default InfoPageWrapper;