import { ReactNode } from "react";
import style from './AuthPageWrapper.module.scss';
import backGradientImg from '../../../images/auth/back-vector.png';
import LocalizationSwitcher from '../../common/localization-switcher/LocalizationSwitcher';

interface IAuthPageWrapperProp {
    children: ReactNode
}

export default function AuthPageWrapper({ children }: IAuthPageWrapperProp) {
    return (
        <div className={style.wrapper}>
            <img className={style.gradient} src={backGradientImg} alt='' />
            <div className={style.localizationSwitcher}>
                <LocalizationSwitcher />
            </div>
            <div className={style.children}>
                {children}
            </div>
            <img className={style.gradient} src={backGradientImg} alt='' />
        </div>
    )
}
