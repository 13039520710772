import * as yup from 'yup';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './LoginPage.module.scss';
import { authLogoImg } from '../../images';
import { removeCookies } from '../../utils';
import { authService } from '../../services';
import { ILoginPayload } from '../../types';
import { AuthPageWrapper, AuthInput, AuthButton } from '../../components/auth';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/store';
import { login } from '../../redux/features';
import { IUser } from '../../types/category.types';

function LoginPage() {
  const navigate = useNavigate();
  const [disableLogin, setDisableLogin] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: { login: '', password: '' },
    onSubmit: async (values: ILoginPayload) => {
      setDisableLogin(true);
      await authService.login(values)
        .then((data: IUser) => {
          dispatch(login(data)); 
        })
        .then(() => {
          navigate('/', { replace: true }) 
        })
        .catch(() => setLoginError(true));
      formik.resetForm();
      setDisableLogin(false);
    },
    validationSchema: yup.object({
      login: yup.string().required('Login is required'),
      password: yup.string().required('Password is required'),
    })
  })

  useEffect(() => {
    removeCookies('accessToken');
    removeCookies('refreshToken');
  }, [])

  return (
    <AuthPageWrapper>
      <form className={style.form} onSubmit={formik.handleSubmit}>
        <div className={style.logo}>
          <img src={authLogoImg} alt='' />
          <div className={style.subText}>Surveys</div>
        </div>

        <AuthInput
          id='login'
          title={ t('loginPage.login') }
          type='login'
          onChange={formik.handleChange}
          value={formik.values.login}
          error={(formik.touched.login && formik.errors.login) ? t('loginPage.loginErr') : undefined}
          marginBottom={25} />

        <AuthInput
          id='password'
          title={ t('loginPage.password') }
          type='password'
          onChange={formik.handleChange}
          value={formik.values.password}
          error={(formik.touched.password && formik.errors.password) ? t('loginPage.passErr') : undefined}
          marginBottom={40} />

        <AuthButton text={ t('loginPage.enter') } disabled={disableLogin} />
      </form>

      {loginError && <div className={style.loginError}>{ t('loginPage.generalErr') }</div>}
    </AuthPageWrapper>
  )
}

export default observer(LoginPage);