import cn from 'classnames';
import { useEffect, useState } from 'react';
import style from './MaxScore.module.scss';
import { testService } from '../../../../services';
import { useTranslation } from 'react-i18next';

interface IMaxScoreProp {
    questionId: string;
    categoryId: string;
    initialValue: number;
    isImmutable?: boolean;
    noRightOptionCheckedError?: boolean;
}

function MaxScore({ questionId, categoryId, initialValue, isImmutable = false, noRightOptionCheckedError }: IMaxScoreProp) {
    const [maxScore, setMaxScore] = useState(initialValue);

    const { t } = useTranslation();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;
        if (value.match("^[0-9]*$") && value.length <= 2) {
            setMaxScore(Number(e.currentTarget.value));
        }
    }

    async function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (!e.currentTarget.value.length) setMaxScore(0);
        await testService.updateQuestion({ questionId, maxScore }, categoryId).catch(() => {});
    }

    useEffect(() => {}, [initialValue])

    return (
        <div className={cn(style.maxScore, { [style.error]: noRightOptionCheckedError })}>
            <div>{ t('question.maxScore') }:</div>
            {isImmutable
                ? <div className={style.initialValue}>{initialValue}</div>
                : <input
                    name='maxscore'
                    className={style.input}
                    value={maxScore}
                    onChange={handleChange}
                    onBlur={handleBlur} />}
        </div>
    )
}


export default MaxScore;