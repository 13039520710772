import { useNavigate } from 'react-router-dom';
import { InfoPageWrapper } from '../../components/common';
import { useTranslation } from 'react-i18next';

function TestDeletedPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <InfoPageWrapper
            largeText={ t('test.deleted') }
            mediumText={ t('test.redirect') }
            onRedirect={() => navigate('/', { replace: true })}
            redirectDelay={2000} />
    )
}

export default TestDeletedPage;