import { makeAutoObservable, observable } from 'mobx';
import { IContentHomePage, IFolder, IFolderContent, ITestCard } from '../types';

class FolderStateService {
    folder: IFolderContent | IContentHomePage | null = null;
    constructor() { makeAutoObservable(this) }

    setFolder(folder: IFolderContent | IContentHomePage) {
        this.folder = {
            ...folder,
            folders: observable(folder.folders),
            tests: observable(folder.tests),
        };
    }

    updateFolderTitle(title: string) {
        if (this.folder?.hasOwnProperty('title')) {
            // @ts-ignore
            this.folder.title = title;
        }
    }

    addFolder(folder: IFolder) {
        this.folder?.folders.splice(1, 0, folder);
    }

    addTest(test: ITestCard) {
        this.folder?.tests.unshift(test);
    }

    deleteFolders(foldersIds: string[]) {
        if (this.folder) {
            this.folder.folders = this.folder.folders
                .filter((item) => !foldersIds.includes(item._id));
        }
    }

    deleteTests(testsIds: string[]) {
        if (this.folder) {
            this.folder.tests = this.folder.tests
                .filter((item) => !testsIds.includes(item._id));
        }
    }
}

export const folderStateService = new FolderStateService();
