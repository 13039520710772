import { useState, useEffect } from 'react';
import Popup from '../Popup';
import Input from '../../input/Input';
import { ITest } from '../../../../types';
import { Button } from '../../button/Button';
import { emailRegex } from '../../../../utils';
import ErrorText from '../../error-text/ErrorText';
import style from './AssignTutorPopup.module.scss';
import { testService } from '../../../../services';
import AssingedTutorElement from './AssingedTutorElement';
import Notification from '../../notification/Notification';
import { useTranslation } from 'react-i18next';
import { ITutor } from '../../../../types/category.types';

interface IAssignTutorPopupProp {
    isActive: boolean;
    assignedTutors?: ITutor[];
    testId: string;
    onClose: () => void;
}

function AssignTutorPopup({ isActive, assignedTutors, testId, onClose }: IAssignTutorPopupProp) {
    const [tutors, setTutors] = useState(assignedTutors);
    const [tutorEmail, setTutorEmail] = useState('');
    const [tutorAssingingError, setTutorAssingingError] = useState('');
    const [tutorAssignedNotificIsActive, setTutorAssignedNotificIsActive] = useState(false);
    const [tutorAssignedNotificText, setTutorAssignedNotificText] = useState<string>();

    const { t } = useTranslation();

    function handleTutorEmailChange(value: string) {
        setTutorAssingingError('');
        if (!value || value.length === 0) {
            setTutorAssingingError(t('mandatoryField'));
        }
        const emailValidation = emailRegex.test(value);
        if (!emailValidation && value) setTutorAssingingError(t('invalidEmailFormat'));
        setTutorEmail(value.trim());
    }

    async function handleTutor(payload: { testId: string, assignTutors?: string[], unassignTutors?: string[] }) {
        await testService.updateTest(payload)
            .then((data: ITest) => {
                setTutors(data.assignedTutors);
                setTutorAssignedNotificText(t('savedSuccess'));
                setTutorEmail('');
            })
            .catch(() => setTutorAssignedNotificText(t('assignTutorNotificText.error')))
            .finally(() => setTutorAssignedNotificIsActive(true));
    }

    useEffect(() => { setTutors(assignedTutors) }, [assignedTutors]);

    return (
        <Popup
            active={isActive}
            title={t('assignTutor')}
            onClose={() => {
                setTutorEmail('');
                setTutorAssingingError('');
                onClose();
            }}>

            {tutors?.length
                ? <div className={style.tutorAssinged}>
                    {tutors.map((item, index) =>
                        <AssingedTutorElement
                            key={index}
                            email={item.login}
                            onDelete={() => handleTutor({ testId, unassignTutors: [item._id] })} />)}
                </div>
                : <div className={style.tutorAssinged}> { t('assignTutorNotificText.notAssigned') } </div>}

            <div className={style.assignTutorPopupContent}>
                <Input
                    onChange={handleTutorEmailChange}
                    value={tutorEmail}
                    width={360}
                    placeholder='exampleemail@gmail.com'
                    clearButton={true} />
                <div style={{ textAlign: 'left', width: '100%' }}>
                    <ErrorText text={tutorAssingingError} />
                </div>
                <div className={style.assignTutorPopupContentBtns}>
                    <Button
                        text={ t('cancelButton') }
                        onClick={() => {
                            setTutorEmail('');
                            setTutorAssingingError('');
                            onClose();
                        }}
                        width={170}
                        type='outlined' />
                    <Button
                        text={ t('saveButton') }
                        onClick={() => handleTutor({ testId, assignTutors: [tutorEmail.trim()] })}
                        width={170}
                        disabled={
                            !!tutorAssingingError.length
                            || tutorEmail.length === 0
                            || tutors?.some(tutor => tutor.login === tutorEmail)
                            } />
                </div>
                <Notification
                    text={tutorAssignedNotificText || ''}
                    active={tutorAssignedNotificIsActive}
                    initialDisplay='flex'
                    onClose={() => setTutorAssignedNotificIsActive(false)}
                    position={{ top: 10 }} />
            </div>
        </Popup>
    )
}

export default AssignTutorPopup;