import { useState } from 'react';
import style from './AddLinkPanel.module.scss';
import { testService } from '../../../../services';
import { Button, Input, Notification } from '../../../common';
import { useTranslation } from 'react-i18next';

interface IAddLInkPanelProp {
    questionId: string;
    categoryId: string;
    initialLinks?: string[];
    onClose: () => void;
}

function AddLinkPanel({ questionId, categoryId, initialLinks, onClose }: IAddLInkPanelProp) {
    const [link, setLink] = useState('');
    const [linkSavedNotificIsActive, setlinkSavedNotificIsActive] = useState(false);

    const { t } = useTranslation();

    async function updateQuestion() {
        const newLinksArray: string[] = [link];
        if (initialLinks) newLinksArray.push(...initialLinks);
        await testService.updateQuestion({ questionId, links: newLinksArray }, categoryId)
            .then(() => setlinkSavedNotificIsActive(true))
            .catch(() => {})
            .finally(() => setLink(''));
    }

    return (
        <div className={style.panel}>
            <Input
                placeholder={ t('question.pasteLink') }
                onChange={(value) => setLink(value)}
                value={link}
                textStyle='link'
                clearButton={true} />
            <div className={style.buttons}>
                <Button
                    type='outlined'
                    text={ t('cancelButton') }
                    width={174}
                    onClick={onClose} />
                <Button
                    text={ t('saveButton') }
                    width={174}
                    disabled={!link.length}
                    onClick={updateQuestion} />
            </div>
            <Notification
                text={ t('question.savedLink') }
                active={linkSavedNotificIsActive}
                initialDisplay='flex'
                onClose={() => setlinkSavedNotificIsActive(false)}
                position={{}} />
        </div>
    )
}

export default AddLinkPanel;