import { useTranslation } from "react-i18next";
import { InfoPageWrapper } from "../../components/common";

function RequestErrorPage () {
  const { t } = useTranslation();

  return (
    <InfoPageWrapper
      largeText={ t('errorOccurred') }
      smallText={ t('tryLater') }
    >
    </InfoPageWrapper>
  );
}

export default RequestErrorPage;