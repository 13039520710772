import { IResUser, IUser } from "../types/category.types";
import { IServerResponse } from "../types/common.types";
import { axiosInstance } from "./axios-instance";
import { AxiosResponse } from 'axios';

class AdminApiService {
  async getTutors() {
    return axiosInstance.get<IUser[]>('/admin/tutors')
      .then((res: AxiosResponse) => res.data.data);
  }

  async createTutor(email: string, sendEmail: boolean, copyPass: boolean) {
    return axiosInstance.post<IResUser>('/admin/tutors/create', { email, sendEmail, copyPass })
      .then((res: AxiosResponse) => res.data.data);
  }

  async deleteTutor(id: string) {
    return axiosInstance.post<IServerResponse>('/admin/tutors/delete', { id })
      .then((res: AxiosResponse) => res.data);
  }
}

export const adminApiService = new AdminApiService();