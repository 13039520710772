import cn from 'classnames';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../panel/Panel';
import Input from '../input/Input';
import { ITest } from '../../../types';
import style from './CreationPanel.module.scss';
import { folderIcon, plusIcon, submitIcon } from '../../../images';
import { folderService, testService } from '../../../services';
import { Button } from '../button/Button';
import { useTranslation } from 'react-i18next';

function CreationPanel() {
    const { id } = useParams();
    const navigate = useNavigate();
    const creatingButtonRef = useRef(null);
    const [activeCreateFolderPanel, setActiveCreateFolderPanel] = useState(false);
    const [folderName, setFolderName] = useState('');

    const { t } = useTranslation();

    async function createFolder() {
        await folderService.createFolder({ title: folderName, parentFolderId: id })
            .then(() => setActiveCreateFolderPanel(false))
            .catch(() => {})
            .finally(() => setFolderName(''));
    }

    async function createTest() {
        await testService.createTest({ parentFolderId: id })
            .then((data: ITest) => navigate(`/test/${data._id}`)).catch(() => {});
    }

    return (
        <div className={style.buttons}>
            <div 
                className={style.buttonTutors}
                onClick={() => navigate('/tutors')} 
            >
                { t('tutors') }
            </div>
            <div className={style.createFolderPanel}>
                <Button
                    type='seconadry'
                    text={ t('creationPanel.createFolder') }
                    icon={folderIcon}
                    onClick={() => setActiveCreateFolderPanel(true)} />
                <Panel
                    position={{ top: 14 }}
                    exeptRef={creatingButtonRef}
                    active={activeCreateFolderPanel}
                    onClose={() => setActiveCreateFolderPanel(false)}>
                    <div className={style.panelContent}>
                        <Input
                            placeholder={ t('creationPanel.folderTitle') }
                            value={folderName}
                            width={250}
                            onChange={setFolderName} />
                        <button
                            onClick={createFolder}
                            disabled={folderName.length === 0}
                            className={cn(style.saveFolderTiteBtn,
                                { [style.isAvailable]: folderName.length > 0 })}>
                            <img src={submitIcon} alt='' />
                        </button>
                    </div>
                </Panel>
            </div>
            <Button
                text={ t('creationPanel.createTest') }
                icon={plusIcon}
                onClick={createTest}
                margin={{ left: 15 }} />
        </div>
    )
}

export default CreationPanel;
