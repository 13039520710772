import { useRef, useEffect } from 'react';
import { IPosition } from '../../../types';
import style from './Notification.module.scss';

interface INotificationProp {
    text: string;
    active: boolean;
    position?: IPosition;
    initialDisplay?: 'flex' | 'block';
    onClose?: () => void;
}

function Notification({ text, active, initialDisplay = 'block', position, onClose }: INotificationProp) {
    const ref = useRef<any>(null);

    useEffect(() => {
        if (!ref.current) return;
        if (active) {
            ref.current.style.display = initialDisplay;
            setTimeout(() => {
                if (ref.current) ref.current.style.display = 'none';
                if (onClose) onClose();
            }, 3000);
        }
        else {
            ref.current.style.display = 'none';
            if (onClose) onClose();
        }
    }, [active]);

    useEffect(() => {
        if (!ref.current) return;
        ref.current.style.display = 'none';
    }, [])

    return (
        <div className={style.notificationWrapper} ref={ref} >
            <div
                className={style.notification}
                style={{
                    top: `${position?.top}px`,
                    bottom: `${position?.bottom}px`,
                    right: `${position?.right}px`,
                    left: `${position?.left}px`,
                }}>
                {text}
            </div>
        </div>
    )
}

export default Notification;