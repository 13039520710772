import { checkApiService } from "../../api";
import { PageWrapper } from "../../components/common";
import { useEffect, useState } from 'react';
import { ITest } from "../../types";
import { useNavigate } from "react-router-dom";
import TutorTestCard from "../tutor-test-card/TutorTestCard";
import style from './TutorsPage.module.scss';
import { useTranslation } from "react-i18next";

function TutorsPage() {
  const [tests, setTests] = useState<Pick<ITest, '_id' | 'title'>[]>();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { t } = useTranslation();

  async function getCheckContent() {
    await checkApiService.getCheckContent()
      .then((data) => {
        setTests(data.tests);
        setLoading(false);
      })
      .catch(() => navigate('/results-unavailable'));
  }

  useEffect(() => {
    getCheckContent();
  }, []);

  return (
    <PageWrapper backgroundColor='#E9ECF3'>
      <div className={style.tutorsPageContent}>
      { loading ? (
        <div>Loading...</div>
      ) : (
        tests && tests.length > 0
          ? (<div>
              <div className={style.headerCard}>{ t('assignedTests') }</div>
              {
                tests.map((item) => (
                  <TutorTestCard id={item._id} title={item.title}/>
                ))
              }
            </div>) 
          : (<div>
              { t('notAssignedYet') }
            </div>)
        )
      }
      </div>
    </PageWrapper>
  )
}

export default TutorsPage;
