import cn from 'classnames';
import { closeIcon } from '../../../images';
import style from './Input.module.scss';

interface IInputProp {
    value: string | number;
    placeholder?: string;
    width?: number;
    clearButton?: boolean;
    textStyle?: 'normal' | 'link';
    valueType?: 'string' | 'number';
    onChange: (value: string) => void;
}

function Input({ value, placeholder, width, clearButton,
    valueType = 'string', textStyle = 'normal', onChange }: IInputProp) {

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (valueType === 'number'
            && e.target.value.match("^[0-9]*$")
            && e.target.value.length <= 1) onChange(e.target.value);
        if (valueType === 'string') onChange(e.target.value);
    }

    return (
        <div
            className={cn(style.inputWrapper, style[valueType])}
            style={{ width: width ? `${width}px` : '100%' }} >
            <input
                type={valueType}
                className={cn(style.input, style[textStyle])}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                autoComplete='none' />
            {clearButton &&
                <img
                    className={style.clearButton}
                    src={closeIcon}
                    alt=''
                    onClick={() => onChange('')} />}
        </div >
    )
}

export default Input;