import { makeAutoObservable } from 'mobx';
import { IQuestion } from '../types';
import { emailRegex } from '../utils';

class TestPassingStateService {
    requiredAnswers: { [quesitonId: string]: boolean } = {};

    constructor() { makeAutoObservable(this) }

    setRequiredQuestions(questions: IQuestion[]) {
        this.requiredAnswers = {}
        questions.forEach((item) => {
            if (item.isRequired) {
                let answeredState = true;
                if (!item.answers?.length
                    || (!item.answers[0]?.checkedOptions?.length && !item.answers[0].text?.length)) {
                    answeredState = false;
                    this.requiredAnswers[item._id] = answeredState;
                    return;
                }
                if (item.isEmail) {
                    if (!emailRegex.test(item.answers[0].text || '')) {
                        answeredState = false;
                    }
                }
                this.requiredAnswers[item._id] = answeredState;
            }  
        });
    }

    udateRequiredQuestionsAnswer(questionId: string, answered: boolean) {
        if (this.requiredAnswers.hasOwnProperty(questionId)) {
            this.requiredAnswers[questionId] = answered;
        }
    }

    getMissedQuestionToAnswer() {
        return Object.keys(this.requiredAnswers).filter((key) => !this.requiredAnswers[key]).pop()
    }

    checkQuestionHasAnswer(questionId: string) {
        return this.requiredAnswers[questionId];
    }

    checkFinishAvailable() {
        let finishAvailable = true;
        for (let i in this.requiredAnswers) {
            if (!this.requiredAnswers[i]) finishAvailable = false;
        }
        return finishAvailable;
    }
}

export const testPassingStateService = new TestPassingStateService();
