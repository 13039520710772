import moment from 'moment';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFolder } from '../../../types';
import style from './FolderCard.module.scss';
import { DeletePopup, Menu } from '../../common';
import { folderService } from '../../../services';
import { ContentViewTypeEnum } from '../../../types/enums';
import { ReactComponent as CheckIcon } from '../../../images/check.svg';
import { pathStateService, selectionStateService, viewStateService } from '../../../state';
import { checkboxCheckedIcon, checkboxIcon, editIcon, folderPhoto, moveIcon, trashIcon } from '../../../images';
import { useTranslation } from 'react-i18next';

interface IFolderCardProp {
    folder: IFolder;
    onReplace: (folderId?: string, testId?: string) => void;
}

function FolderCard({ folder, onReplace }: IFolderCardProp) {
    const navigate = useNavigate();
    const [title, setTitle] = useState(folder.title);
    const [titleEditing, setTitleEditing] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);

    const { t } = useTranslation();

    const menuOptions = [
        { icon: editIcon, text: t('folder.rename'), onClick: () => { setTitleEditing(true) } },
        { icon: moveIcon, text: t('moveTo'), onClick: () => onReplace(folder._id) },
        { icon: trashIcon, text: t('folder.delete'), onClick: async () => setDeletePopupIsActive(true) },
    ];

    async function updateFolder() {
        if (title.length === 0) return;
        await folderService.updateFolderTitle({ title, folderId: folder._id });
        setTitleEditing(false);
    }

    async function deleteFolder() {
        await folderService.deleteFolders([folder._id]).catch(() => {})
    }

    function handleClick(e: any) {
        if (titleEditing) return;
        const elemClass = e.target.className;
        if (!elemClass || typeof elemClass === 'object') return;
        if (elemClass.toLowerCase().includes('menu')) return;
        pathStateService.addToPath({ title, folderId: folder._id });
        navigate(`/folder/${folder._id}`);
    }

    useEffect(() => {
        const folderIsSelected = selectionStateService.checkFolderSelection(folder._id);
        if (folderIsSelected) setIsSelected(true);
        else setIsSelected(false);
    }, [selectionStateService.foldersIds.length]);

    return (<>
        {
            viewStateService.contentView === ContentViewTypeEnum.tile &&
            <div className={style.tile} onClick={handleClick}>
                <div className={style.head}>
                    <img className={style.head} src={folderPhoto} alt='' />
                    {!folder.isArchive &&
                        <Menu
                            options={menuOptions}
                            verticalDots={true}
                            defaultPosition={{ top: 26, right: 23 }} />}
                </div>

                <div className={style.info}>
                    <div className={cn(style.title, { [style.titleEditing]: titleEditing })}>
                        <input
                            className={cn(style.input, { [style.titleEditing]: titleEditing })}
                            disabled={!titleEditing}
                            value={title}
                            onChange={(e => setTitle(e.target.value))} />
                        {titleEditing &&
                            <CheckIcon
                                className={cn(style.confirmIcon, { [style.active]: title.length > 0 })}
                                onClick={updateFolder} />}
                    </div>
                    <div className={style.counts}>
                        { t('folder.folderCount', { count: folder.folders?.length }) }, {' '}
                        { t('folder.filesCount', { count: folder.tests?.length })}
                    </div>
                </div>
            </div>
        }
        {
            viewStateService.contentView === ContentViewTypeEnum.list &&
            <div className={cn(style.list, { [style.isSelected]: isSelected })} >
                {!folder.isArchive ?
                    <img
                        onClick={() => { !folder.isArchive && selectionStateService.handleFolderSelection(folder._id) }}
                        className={style.selectIcon}
                        src={isSelected ? checkboxCheckedIcon : checkboxIcon}
                        alt='' />
                    : <div></div>}
                <div onClick={handleClick} className={style.mainInfo}>
                    <img
                        className={style.icon}
                        src={folderPhoto}
                        alt='' />
                    <div className={cn(style.title, { [style.titleEditing]: titleEditing })}>
                        <input
                            className={cn(style.input, { [style.titleEditing]: titleEditing })}
                            disabled={!titleEditing}
                            value={title}
                            onChange={(e => setTitle(e.target.value))} />
                        {titleEditing &&
                            <CheckIcon
                                className={cn(style.confirmIcon, { [style.active]: title.length > 0 })}
                                onClick={updateFolder} />}
                    </div>
                    <div className={style.counts}>
                        { t('folder.folderCount', { count: folder.folders?.length }) }, {' '}
                        { t('folder.filesCount', { count: folder.tests?.length })}
                    </div>
                    <div className={style.createdOn}>
                        {moment(folder.createdAt).format('DD-MM-YYYY hh:mm A')}
                    </div>
                    <div className={style.editedOn}>
                        {moment(folder.updatedAt).format('DD-MM-YYYY hh:mm A')}
                    </div>
                </div>
                <div>
                    {!folder.isArchive &&
                        <Menu
                            options={!folder.isArchive ? menuOptions : []}
                            defaultPosition={{ top: 30, right: 23 }} />}
                </div>
            </div>
        }

        <DeletePopup
            title={ t('confirmDeletionItem') }
            active={deletePopupIsActive}
            onClose={() => setDeletePopupIsActive(false)}
            onSubmit={() => {
                deleteFolder();
                setDeletePopupIsActive(false);
            }} />
    </>)
}

export default observer(FolderCard);