import { useEffect, useState } from 'react';
import style from './Attachments.module.scss';
import { downloadIcon, linkImg } from '../../../images';


interface IAttachmentsProp {
    files?: string[];
    links?: string[];
    isOption?: boolean;
}

function Attachments({ files, links, isOption }: IAttachmentsProp) {
    const [images, setImages] = useState<string[]>();
    const [videos, setVideos] = useState<string[]>();
    const [audios, setAudios] = useState<string[]>();
    const [applications, setApplications] = useState<string[]>();

    useEffect(() => {
        if (files) {
            const imagesArray: string[] = [];
            const videosArray: string[] = [];
            const audiosArray: string[] = [];
            const applicationsArray: string[] = [];

            files.forEach((item) => {
                const fileType = item.split('/').slice(-1)[0].split('.')[0];
                if (fileType === 'image') imagesArray.push(item);
                else if (fileType === 'audio') audiosArray.push(item);
                else if (fileType === 'video') videosArray.push(item);
                else if (fileType === 'application') applicationsArray.push(item);
            });
            setImages(imagesArray);
            setVideos(videosArray);
            setAudios(audiosArray);
            setApplications(applicationsArray);
        }
    }, []);

    return (
        <div className={style.attachments}>
            {(links && links.length) ?
                <>
                    {links.map((item, index) =>
                        <div
                            key={index}
                            className={style.textElement}
                            style={{ marginTop: !isOption ? '18px' : 'none' }}>
                            <div className={style.text}>
                                <a target="_blank" rel="noreferrer" href={item}>{item}</a>
                            </div>
                            <img src={linkImg} alt='' style={{ marginLeft: '5px' }} />
                        </div>)}
                </> : ''
            }
            {
                (applications && applications.length) ?
                    <>
                        {applications.map((item, index) =>
                            <a
                                key={index}
                                className={style.textElement}
                                target="_blank"
                                rel="noreferrer"
                                download={item.split('/').slice(-1)[0]}
                                href={`${process.env.REACT_APP_BACK_URL}/${item}`}
                                style={{ display: 'flex', alignItems: 'center', marginTop: !isOption ? '18px' : 'none' }}>
                                <div className={style.text}>
                                    {item.split('/').slice(-1)[0]}
                                </div>
                                {item.split('/').slice(-1)[0].split('.').slice(-1)[0] !== 'pdf'
                                    && <img src={downloadIcon} alt='' style={{ marginLeft: '5px' }} />}
                            </a>)}
                    </> : ''
            }
            {
                (audios && audios.length) ?
                    <>
                        {audios.map((item, index) =>
                            <audio
                                key={index}
                                controls
                                className={style.audio}
                                style={{ marginTop: !isOption ? '18px' : 'none' }} >
                                <source src={`${process.env.REACT_APP_BACK_URL}/${item}`}></source>
                            </audio>
                        )}
                    </> : ''
            }
            {
                (images && images.length) ?
                    <div
                        className={style.images}
                        style={{
                            marginTop: !isOption ? '18px' : 'none',
                            display: !isOption ? 'grid' : 'block',
                        }}>
                        {images.map((item, index) =>
                            <div
                                key={index}
                                className={style.image}
                                style={{ backgroundImage: `url(${process.env.REACT_APP_BACK_URL}/${item})` }}>
                            </div>
                        )}
                    </div> : ''
            }
            {
                (videos && videos.length) ?
                    <div
                        className={style.videos}
                        style={{ textAlign: 'left' }}>
                        {videos.map((item, index) =>
                            <video
                                key={index}
                                controls
                                className={style.video}
                                style={{ marginTop: !isOption ? '18px' : 'none' }}>
                                <source src={`${process.env.REACT_APP_BACK_URL}/${item}`}></source>
                            </video>
                        )}
                    </div> : ''
            }
        </div >
    )
}

export default Attachments;