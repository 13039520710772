import { closeIcon } from '../../../images';
import { IPosition } from '../../../types';
import style from './AttachedElements.module.scss';

interface IAttachedElementProp {
    element: string;
    onDelete: () => void;
    initialLink?: boolean;
    margin?: IPosition;
}

function AttachedElement({ element, initialLink, margin, onDelete }: IAttachedElementProp) {

    return (
        <div
            className={style.element}
            style={{
                marginTop: `${margin?.top}px`,
                marginBottom: `${margin?.bottom}px`,
                marginRight: `${margin?.right}px`,
                marginLeft: `${margin?.left}px`,
            }}>
            {element && <img
                alt=''
                className={style.removeButton}
                src={closeIcon}
                onClick={onDelete} />}
            <div className={style.text}>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={initialLink ? element : `${process.env.REACT_APP_BACK_URL}/${element}`} >
                    {initialLink && element}
                    {!initialLink &&
                        (element?.split('/').length ? element?.split('/').slice(-1)[0].split('.')[1] : element)}
                </a>
            </div>
        </div>
    )
}

export default AttachedElement;