import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import style from './Menu.module.scss';
import { IPosition } from '../../../types';
import { ReactComponent as MenuDotsImg } from '../../../images/menu-dots.svg';

interface IMenuProp {
    options: { icon: string, text: string, onClick: () => void }[];
    defaultPosition?: IPosition;
    dynamicPosition?: IPosition;
    verticalDots?: boolean;
    children?: React.ReactNode;
}

function Menu({ options, defaultPosition, dynamicPosition, verticalDots, children }: IMenuProp) {
    const [isActive, setIsActive] = useState<boolean>();
    const menuRef = useRef(null);

    function handleClick(e: any) {
        setIsActive(prev => !prev);
    }

    const checkActive = useCallback((e: any) => {
        // @ts-ignore
        if (!menuRef?.current?.contains(e.target)) setIsActive(false);
    }, []);

    useEffect(() => {
        if (isActive) document.addEventListener('click', checkActive);
        else document.removeEventListener('click', checkActive);
    }, [isActive]);

    return (
        <div
            className={style.wrapper}
            ref={menuRef}
            onClick={handleClick}>
            <div className={cn(style.menuIconWrapper, { [style.active]: isActive })}>
                <MenuDotsImg
                    className={cn(style.menuIcon, { [style.active]: isActive })}
                    style={{ transform: `${verticalDots ? 'rotate(90deg)' : 'none'}` }} />
            </div>
            <div
                className={style.menu}
                style={{
                    display: isActive ? 'block' : 'none',
                    top: `${defaultPosition?.top ?? dynamicPosition?.top}px`,
                    bottom: `${defaultPosition?.bottom ?? dynamicPosition?.top}px`,
                    right: `${defaultPosition?.right ?? dynamicPosition?.top}px`,
                    left: `${defaultPosition?.left ?? dynamicPosition?.top}px`,
                }}>
                {options.map((item, index) =>
                    <div
                        key={index}
                        className={style.menuItem}
                        onClick={item.onClick}>
                        <img className={style.menuItemIcon} src={item.icon} alt='' />
                        <div className={style.menuItemText}>{item.text}</div>
                    </div>)}
            </div>
            {children}
        </div>
    )
}

export default Menu;
