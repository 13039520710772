import style from './TestPassingPage.module.scss';
import { defaultTestCardImg } from '../../images';
import { toJS } from 'mobx';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { testPassingApiService } from "../../api";
// import { removeCookies } from "../../utils";
import { ICategory } from "../../types/category.types";
import { IQuestion, IResult } from '../../types';
import { TestPassingAnswer } from '../../components/test-passing';
import { testPassingStateService } from '../../state';
import { InfoPageWrapper } from '../../components/common';
import LocalizationSwitcher from '../../components/common/localization-switcher/LocalizationSwitcher';
import { TimerLeft } from '../../components/test';
import { useTranslation } from 'react-i18next';
import { errorPopup } from '../../components/common/error-popup/errorPopup';

enum FinishTestErrors {
    alreadyPassed = 'User with this email already passed test',
    requiredFields = 'Not all required fields filled'
}

function getErrorKey (error: string) {
    const errorKey = Object.keys(FinishTestErrors).find((key) => (FinishTestErrors as any)[key] === error);
    if (errorKey) return `finishTestError.${errorKey}`;

    return null;
}

interface ITestPass {
  _id: string;
  parentFolder?: string;
  assignedTutors?: string[];
  title?: string;
  banner?: string;
  isArchived: boolean;
  categories: string[];
  categoryToPass: ICategory;
  time?: { hours: number, minutes: number };
  link: string;
  createdAt?: Date;
  updatedAt?: Date;
  maxScore: number;
}

function TestPassingPage() {
  const [searchParams] = useSearchParams();
  const encoded = searchParams.get('encoded');
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [test, setTest] = useState<ITestPass>();
  const [result, setResult] = useState<IResult>();
  const [finalDate, setFinalDate] = useState();
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const scrollToLastMissedQuestion = () => {
    const elementId = testPassingStateService.getMissedQuestionToAnswer();
    const element = document.getElementById(`question_${elementId}`);
    if (element) {
      new Promise((resolve) => requestAnimationFrame(resolve))
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  async function addResultLeavePageCount() {
    await testPassingApiService.addResultLeavePageCount().catch(() => {});
  };

  async function getTestInfo() {
    if (!encoded) return;
    await testPassingApiService.getTestInfo(encoded, test?.categories[page])
      .then(data => {
        // if (!data.test) {
        //   // removeCookies('passingToken');
        //   navigate('/test-unavailable', { replace: true }); TODO: Decide do we need this ?
        // } 
        if (data.test.isArchived) navigate('/test-archived', {  state: { testTitle: data.test?.title, testId: data.test?._id }, replace: true });
        data.test.categories = [null, ...data.test.categories];

        setPageCount(data.test.categories.length);
        setTest({ ...data.test, categoryToPass: data.category  });
        setResult(data.result);
        data.result.finalDate && setFinalDate(data.result.finalDate);

        testPassingStateService.setRequiredQuestions(data.category.questions);
      })
      .catch(() => {
        navigate('/test-unavailable', { replace: true });
      });
  };

  async function finishTest() {
    await testPassingApiService.finishTest()
      .then((data) => {
        if (data.success) {
          navigate('/test-passed', { replace: true });
        }
      })
      .catch((e) => {
        const error = getErrorKey(e.response.data.error);
        if (error) {
          errorPopup(t(`${error}`));
        } else {
          errorPopup();
        }
      });
  };

  useEffect(() => {
    getTestInfo();
  },[page]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        addResultLeavePageCount();
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleNextPage = () => {
    if (page >= pageCount - 1) return;
    if(!testPassingStateService.checkFinishAvailable()) {
      setNextButtonClicked(true);
      scrollToLastMissedQuestion();
      return;
    };
    setNextButtonClicked(false);
    setPage(page + 1); 
  };

  const handleBackPage = () => {
    if (page <= 0) return;
    setNextButtonClicked(false);
    setPage(page - 1)
  };

  const handleFinishTest = () => {
    if (page !== pageCount - 1) return;
    if (!testPassingStateService.checkFinishAvailable()) {
      setNextButtonClicked(true);
      return;
    }
    setNextButtonClicked(false);
    finishTest();
  };

  return(
    <InfoPageWrapper>
      <div className={style.localizationSwitcher} >
        <LocalizationSwitcher />
      </div>
    <div>
      {(test && result) && <>
        {test?.time && (test.time.hours > 0 || test.time.minutes > 0)}
        <div className={style.content}>
            {finalDate && <TimerLeft finalTime={finalDate} />}
            <div className={style.itemWrapper}>
              <div className={style.title}>{test.title}</div>
              <div className={style.banner}
                style={{
                  backgroundImage: `url(${test.banner ?
                    `${process.env.REACT_APP_BACK_URL}/${test.banner}` : defaultTestCardImg})`
                }}>
              </div>
            </div>
            {
              test.categoryToPass?.title &&
              <div className={style.categoryContainer} >
              <div className={style.categoryTitle} > { test.categoryToPass.title } </div>
              </div>
            }
            {toJS(test.categoryToPass.questions)?.map((item: IQuestion) =>
              <div key={item._id} >
                <TestPassingAnswer resultId={result._id} question={item} nextButtonClicked={nextButtonClicked} />
              </div>
            )}
        </div>
        
        <div className={style.buttons} >
        { page > 0 && 
        <button onClick={handleBackPage} className={style.button} > {t('backButton')} </button> 
        }
        { page < pageCount - 1 && 
        <button onClick={handleNextPage} className={style.button} > { t('nextButton') } </button> 
        }
        { page === pageCount - 1 &&
        <button onClick={handleFinishTest} className={style.sendButton} > { t('sendButton') } </button>
        }
        </div>
      </>}
    </div>
    </InfoPageWrapper>
  );
}

export default observer(TestPassingPage);