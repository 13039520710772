import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../types/category.types";

const initialState: { info: IUser | null } = {
  info: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    login: (state, action: PayloadAction<IUser>) => {
      state.info = action.payload;
      return state;
    },
  },
});

export const { login, logout } = userSlice.actions;

export const userReducer = userSlice.reducer;
