import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getCookies } from './utils';

i18n
  .use(resourcesToBackend(
    (language: any, namespace: any) => 
      import(`./locales/${language}/${namespace}.json`)
  ))
  .use(initReactI18next) 
  .init({
    lng: getCookies('language') || 'en',
    fallbackLng: 'en',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false
    // }
  });

export default i18n;