import style from './AddButton.module.scss';

interface IAddButtonProp {
  onClick: () => void;
  icon: any;
  text: string;
}

export default function AddButton({ onClick, icon, text }: IAddButtonProp) {
  return (
    <div className={style.button} onClick={onClick}>
      <img className={style.icon} src={icon} alt='' />
      <span>{text}</span>
    </div>
  )
}
