import { observer } from "mobx-react-lite";
import { useState, useRef, useEffect } from "react";
import { useAutosizeTextArea } from "../../../utils";
import style from './CategoryHead.module.scss';
import { useDrag, useDrop } from "react-dnd";
import { Items } from "../../../types/category.types";
import { categoryService } from "../../../services/category.service";
import { deleteIcon, dragIcon } from "../../../images";
import { testService } from "../../../services";
import { DeletePopup } from "../../common";
import { useTranslation } from "react-i18next";
import { errorPopup } from "../../common/error-popup/errorPopup";

interface ICategoryHeadProp {
  id: string;
  testId: string;
  title?: string;
  orderIndex: number;
  isRandom?: boolean;
  handleCollapse: () => void;
}

function CategoryHead({ title, id, orderIndex, testId, isRandom, handleCollapse } : ICategoryHeadProp) {
  const [titleText, setTitleText] = useState(title || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [shuffleQuestions, setShuffleQuestions] = useState(isRandom);

  const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);
  const { t } = useTranslation();

  const [{ opacity, isDragging }, drag, preview] = useDrag(() => ({
    type: Items.CATEGORY,
    item: { id, orderIndex },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging()
    })
  }));

  useEffect(() => {
    if (isDragging) handleCollapse();
  }, [isDragging, handleCollapse]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [Items.CATEGORY, Items.QUESTION],
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    drop: async (item: any, monitor) => {
      if (monitor.getItemType() === Items.CATEGORY) {
        if (item.id === id) return;

        await testService.updateCategoryOrder(item.id, id).catch(() => { errorPopup() });
      } else if (monitor.getItemType() === Items.QUESTION) {
        if (item.categoryId === id) return;
        
        await testService.moveQuestionToCategory(item.categoryId, id, item.id).catch(() => { errorPopup() });
      }
    }
  }));

  useAutosizeTextArea(textareaRef.current, titleText);

  function handleChange(e: any) {
    if(!textareaRef.current) return;
    const value = e.target.value.replaceAll('\n', '');
    setTitleText(value);
  }

  async function updateCategory(newTitle: string) {
    if (newTitle === title) return;
    await categoryService.updateCategory(id, newTitle).catch(() => {});
  }

  async function deleteCategory() {
    await categoryService.deleteCategory(id, testId);
  }

  async function handleShuffleQuestionsCheckbox(id: string) {
    await categoryService.updateCategoryRandom(id, !shuffleQuestions)
      .then(() => setShuffleQuestions(!shuffleQuestions))
      .catch(() => errorPopup())
  };

  return (
      <div id={id} ref={drop} >
      <div ref={preview} className={style.head} style={{ opacity }} >
      <div ref={drag} className={style.dragIcon}><img src={dragIcon} alt='' /></div>
        <img
            className={style.deleteButton}
            onClick={() => setDeletePopupIsActive(true)}
            src={deleteIcon}
            alt='' />
        <textarea
          name='category'
          placeholder={ t('test.categoryPlaceholder') }
          ref={textareaRef}
          value={titleText}
          onInput={handleChange}
          onBlur={() => updateCategory(titleText.trim())}
          className={style.titleInput}
          style={ isOver ? { pointerEvents: "none" } : {}}
        />
        <div className={style.shuffleQuestionCheckBox} >
          <input
            id={`shuffleQuestions_${id}`}
            type="checkbox"
            readOnly
            checked={shuffleQuestions}
            onClick={() => handleShuffleQuestionsCheckbox(id)}
          />
          <label htmlFor={`shuffleQuestions_${id}`} >
            { t('shuffleQuestions') }
          </label>
        </div>
      </div>
      <DeletePopup 
        title={t('test.deleteCategory', { title })}
        active={deletePopupIsActive}
        onClose={() => setDeletePopupIsActive(false)}
        onSubmit={() => {
          deleteCategory();
          setDeletePopupIsActive(false);
        }}
      />
    </div>
  );
}

export default observer(CategoryHead);