import { AxiosResponse } from 'axios';
import { ILoginPayload } from '../types';
import { authAxiosInstance } from './axios-instance';
import { IServerResponse } from '../types/common.types';
import { ILoginResponse, IRefreshTokenResponse } from './../types/auth.types';

class AuthApiService {
    async login(payload: ILoginPayload) {
        return authAxiosInstance.post<ILoginResponse>('/auth/sign-in', payload)
            .then((res: AxiosResponse) => res.data)
            .catch(() => null);
    }

    async checkToken() {
        return authAxiosInstance.post<IServerResponse>('/auth/check')
            .then((res: AxiosResponse) => res.data)
    }

    async refreshTokens() {
        return authAxiosInstance.post<IRefreshTokenResponse>('/auth/refresh')
            .then((res: AxiosResponse) => res.data)
            .catch(() => null);
    }
}

export const authApiService = new AuthApiService();