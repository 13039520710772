import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { ContentList, CreationPanel, InfoPanel, PageWrapper } from '../../components/common';


function FolderPage() {
    const { id } = useParams();

    useEffect(() => {}, [id]);

    return (
        <PageWrapper>
            <CreationPanel />
            <InfoPanel />
            <ContentList />
        </PageWrapper>
    )
}

export default observer(FolderPage);
