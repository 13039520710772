import { IQuestion } from "./question.types";

export interface ICategory {
  _id: string;
  test: string;
  title?: string;
  orderIndex: number;
  isDefault?: boolean;
  isRandom?: boolean;
  questions: IQuestion[];
}

export const Items = {
  QUESTION: 'question',
  CATEGORY: 'category',
}

export interface IUser {
  _id: string;
  login: string;
  isAdmin: boolean;
}

export interface ITutors {
  _id: string;
  login: string;
  isAdmin: boolean;
  assignedTests: string[];
}

export interface ITutor {
  _id: string;
  login: string;
}

export interface IResUser {
  _id: string;
  login: string;
  password?: string; 
}