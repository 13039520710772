import { authApiService } from '../api';
import { authStateService } from '../state';
import { ILoginPayload } from '../types';
import { ILoginResponse } from '../types/auth.types';
import { removeCookies, setCookies } from '../utils';

class AuthService {
    async checkAuthorized() {
        try {
            await authApiService.checkToken();
            authStateService.setAuthorized();
            return Promise.resolve({ isAuthorized: true });
        }
        catch {
            return Promise.reject({ isAuthorized: false });
        }
    }

    async login(loginPayload: ILoginPayload) {
        const resp: ILoginResponse = await authApiService.login(loginPayload);
        if (!resp?.data?.accessToken && !resp?.data?.user.login) return Promise.reject();

        const { accessToken, refreshToken } = resp.data;
        setCookies('accessToken', accessToken);
        setCookies('refreshToken', refreshToken);

        authStateService.setAuthorized();
        return Promise.resolve(resp.data.user);
    }

    async logout() {
        removeCookies('accessToken');
        removeCookies('refreshToken');
        authStateService.setUnauthorized();
    }

}

export const authService = new AuthService();