import { ITutors } from '../../../types/category.types';
import style from './TutorTable.module.scss';
import { ReactComponent as TrashIcon } from '../../../images/bin.svg';
import { useEffect, useState, Fragment } from 'react';
import { adminApiService } from "../../../api/admin.api-service";
import { useNavigate } from "react-router-dom";
import { Button, DeletePopup } from "../../../components/common";
import { plusIcon } from "../../../images";
import CreateTutorPopup from '../../common/popup/create-tutor-popup/CreateTutorPopup';
import { errorPopup } from '../../common/error-popup/errorPopup';
import { useTranslation } from 'react-i18next';

function TutorTable () {
  const navigate = useNavigate();
  const [tutors, setTutors] = useState<ITutors[]>();
  const [createPopupIsActive, setCreatePopupIsActive] = useState(false);
  const [deletePopupIsActive, setDeletePopupIsActive] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState('');

  const { t } = useTranslation();

  async function getTutors() {
    await adminApiService.getTutors()
      .then((data) => {
        setTutors(data);
      })
      .catch(() => navigate('/results-unavailable', { replace: true }));
  }

  async function deleteTutor() {
    if (userToDelete) {
      await adminApiService.deleteTutor(userToDelete)
        .then(() => {
          getTutors();
        })
        .catch(() => { errorPopup(t('errorOccurred')) });
    }
  }

  const handleSubmit = async (email: string, sendEmail: boolean, copyPass: boolean) => {
    await adminApiService.createTutor(email, sendEmail, copyPass)
      .then((data) => {
        if (data) {
          getTutors();
        }

        if (data.password) {
          setPass(data.password);
          setShowPassword(true);
        } else {
          handleCreatePopupClose();
        }
      })
  };

  const handleCreatePopupClose = () => {
    setCreatePopupIsActive(false); 
    setShowPassword(false); 
    setPass(''); 
  }

  const handleDelete = (id: string) => {
    setUserToDelete(id);
    setDeletePopupIsActive(true)
  };

  useEffect(() => { getTutors() }, []);

  return (
    <div>
      <div className={style.createButton}>
        <Button 
          text={t('accountsPage.createAccount')}
          icon={plusIcon}
          onClick={() => setCreatePopupIsActive(true)}
          margin={{ right: 125 }}
        />
      </div>
      { tutors && tutors.length > 0 ? (
        <table className={style.contentTable}>
          <thead>
            <tr>
              <th> Email </th>
              <th> Assigned tests </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { 
              tutors.map((item: ITutors) => 
                <tr key={item._id} >
                  <td className={style.columnOne}> {item.login}</td>
                  <td className={style.columnTwo}> {
                    item?.assignedTests.map((testId) => (
                      <Fragment key={`${item._id}_${testId}`}>
                      <span 
                        className={style.clickableTestId} 
                        onClick={() => navigate(`/test/${testId}`)} 
                      >
                        { testId }
                      </span>
                      { item.assignedTests.length > 0 && <span>{', '}</span> }
                      </Fragment>
                    ))} 
                  </td>
                  <td>
                    <div 
                      className={style.deleteButton}
                      onClick={() => handleDelete(item._id)}
                    >
                      <TrashIcon 
                        className={style.selectedPanelIcon}
                      />
                    </div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      ): (<div className={style.emptyTable} > { t('noTutors') } </div>)}
      <CreateTutorPopup
        title={t('accountsPage.newAccount')}
        active={createPopupIsActive}
        showPassword={showPassword}
        password={pass}
        onClose={handleCreatePopupClose}
        onSubmit={handleSubmit}
      />
      <DeletePopup
        title={t('accountsPage.userDeleting')}
        active={deletePopupIsActive}
        onClose={() => {
          setUserToDelete(null);
          setDeletePopupIsActive(false);
        }}
        onSubmit={() => {
          deleteTutor();
          setDeletePopupIsActive(false);
        }}
      />
    </div>
  );
}

export default TutorTable;