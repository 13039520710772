import { questionApiService } from '../api';
import { testStateService } from '../state';
import { IAddQuestionOptionFilePayload, IQuestion } from '../types/question.types';

class QuestionService {
    async addQuestionOptionFile(payload: IAddQuestionOptionFilePayload, categoryId: string) {
        try {
            const formData = new FormData()
            formData.append('file', payload.file);
            formData.append('optionId', payload.optionId);
            formData.append('questionId', payload.questionId);
            const updatedQuestion: IQuestion =
                await questionApiService.addQuestionOptionFile(formData as unknown as IAddQuestionOptionFilePayload);

            if (categoryId === testStateService.defaultCategory._id) {
                testStateService.updateQuestion(payload.questionId, updatedQuestion);
            } else {
                testStateService.updateQuestionInCategory(payload.questionId, categoryId, updatedQuestion);
            }
            
            return Promise.resolve(updatedQuestion);
        }
        catch {
            return Promise.reject();
        }
    }
}

export const questionService = new QuestionService();