import cn from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { checkApiService } from '../../api';
import style from './CheckResultPage.module.scss';
import { IResult, ITest } from '../../types';
import { BackButton, PageWrapper } from '../../components/common';
import CheckResultQuestion from '../../components/check/check-result-question/CheckResultQuestion';
import { useTranslation } from 'react-i18next';
import { ICategory } from '../../types/category.types';
import { useAppSelector } from '../../redux/store';

function CheckResultPage() {
    const { id } = useParams();

    const location = useLocation();

    const [result, setResult] = useState<IResult>();
    const [categories, setCategories] = useState<ICategory[]>();
    const [test, setTest] = useState<Pick<ITest, '_id' | 'time' | 'assignedTutors' | 'title' | 'maxScore'>>();

    const { info } = useAppSelector(state => state.user);

    const { t } = useTranslation();

    async function getResultCheckInfo(resultId: string) {
        await checkApiService.getResultCheckInfo(resultId)
            .then(data => {
                setCategories(data.categories)
                setResult(data.result);
                setTest(data.test);
            })
            .catch(() => {});
    }

    function calculateQuestions() {
        let count = 0;

        if (categories) {
            categories.forEach((item) => {
                count = count + item.questions?.length;
            })
        }

        if (isNaN(count)) {
            return 0;
        }

        return count - 2;
    }

    function returnBacLink() {
        let backLink: string;

        if (info?.isAdmin) {
            const tesTabInfo = localStorage.getItem('test-page-tab');
            if (tesTabInfo) {
                const tesTabInfoParsed = JSON.parse(tesTabInfo);
                backLink = `/test/${tesTabInfoParsed.testId}`;
                return backLink;
            } else {
                return '/';
            }
        }
        else {
            let backLink = '/check';

            if (location?.state?.testId) backLink = `/check/test/${location?.state?.testId}`;
            
            return backLink;
        }
    }

    useEffect(() => { if (id) getResultCheckInfo(id) }, [id]);

    return (
        <PageWrapper backgroundColor='#E9ECF3'>
            <div className={style.content}>
                <div className={style.head}>
                    <BackButton to={returnBacLink()} />
                    <div className={style.title}>{ t('result.testResults') }</div>
                </div>
                <div className={cn(style.mainInfo, style.cardWrapper)}>
                    <div className={style.infoBlock}>
                        <div className={style.infoBlockTitles}>
                            <div className={style.mainInfoTitle}>{ t('result.email') }</div>
                            <div className={style.mainInfoTitle}>{ t('result.name') }</div>
                            <div className={style.mainInfoTitle}>{ t('date') }</div>
                            <div className={style.mainInfoTitle}>{ t('score') }</div>
                            <div className={style.mainInfoTitle}>{ t('result.timeSpent') }</div>
                            {result?.overdueTime &&
                                <div className={style.mainInfoTitle}>{ t('result.overdueTime') }</div>}
                            <div className={style.mainInfoTitle}>{ t('result.checkedBy') }</div>
                        </div>
                        <div>
                            <div className={style.mainInfoItem}>{result?.email}</div>
                            <div className={style.mainInfoItem}>{result?.name}</div>
                            <div className={style.mainInfoItem}> {moment(result?.updatedAt).format('DD-MM-YYYY hh:mm A')}</div>
                            <div className={style.mainInfoItem}>{result?.score}/{test?.maxScore}</div>
                            <div className={style.mainInfoItem}>
                                {result?.passingTime?.hours}h {result?.passingTime?.minutes}m {result?.passingTime?.seconds}s
                            </div>
                            {result?.overdueTime &&
                                <div className={style.mainInfoItem}>
                                    {result?.overdueTime?.hours}h {result?.overdueTime?.minutes}m {result?.overdueTime?.seconds}s
                                </div>}
                            <div className={style.mainInfoItem}>{result?.checkedBy || t('result.notChecked')}</div>
                        </div>
                    </div>
                    <div className={style.infoBlock}>
                        <div className={style.infoBlockTitles}>
                            <div className={style.mainInfoTitle}>{ t('result.pageClosures') }</div>
                            <div className={style.mainInfoTitle}>{ t('test.questionNumber') }</div>
                        </div>
                        <div>
                            <div className={style.mainInfoItem}>{result?.leavePageCount || 0}</div>
                            <div className={style.mainInfoItem}>{calculateQuestions()}</div>
                        </div>
                    </div>
                </div>

                <div>
                    {result && 
                        categories?.map((item) => (
                            <div>
                                { item?.title && 
                                <div className={style.categoryContainer} > 
                                    <div className={style.categoryTitle} >{ item.title }</div> 
                                </div>
                                }
                                <div className={style.cardWrapper}>
                            {item.questions.filter(el => !el.isEmail && !el.isName).map((el, index) => 
                                <CheckResultQuestion
                                    key={el._id}
                                    resultId={result._id}
                                    question={el}
                                    quesitonNumber={index + 1} />
                            )}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </PageWrapper >
    )
}

export default CheckResultPage;
