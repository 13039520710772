import { AxiosResponse } from 'axios';
import { passingAxiosInstance } from './axios-instance';
import { IUpdateResultAnswerDto, IUpdateResultAnswerFileDto } from '../types/test-passing.types';
import { IServerResponse } from '../types/common.types';

class TestPassingApiService {
    async getTestInfo(encoded: string, categoryId?: string) {
        return passingAxiosInstance.post(`/passing?encoded=${encoded}`, { categoryId })
            .then((res: AxiosResponse) => res.data.data);
    }

    async updateResultAnswer(payload: IUpdateResultAnswerDto) {
        return passingAxiosInstance.post('/passing/answer', payload)
            .then((res: AxiosResponse) => res.data.data);
    }

    async addResultLeavePageCount() {
        return passingAxiosInstance.patch<IServerResponse>('/passing/leave')
            .then((res: AxiosResponse) => res.data.data);
    }

    async updateResultAnswerFile(payload: IUpdateResultAnswerFileDto) {
        return passingAxiosInstance.post('/passing/file', payload)
            .then((res: AxiosResponse) => res.data.data);
    }

    async finishTest() {
        return passingAxiosInstance.post('/passing/finish')
            .then((res: AxiosResponse) => res.data);
    }
}

export const testPassingApiService = new TestPassingApiService();