import cn from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { photoImg } from '../../../images';
import style from './TestHead.module.scss';
import { testService } from '../../../services';
import { missedFieldsStateService, testStateService } from '../../../state';
import { AddButton, AttachedElement, ErrorText, Notification, UploadInput } from '../../common';
import { useTranslation } from 'react-i18next';
import { categoryService } from '../../../services/category.service';
import { errorPopup } from '../../common/error-popup/errorPopup';

interface ITestHeadProps {
    defaultCategoryId: string;
    isRandom?: boolean;
}

export const TestHead = observer(({ defaultCategoryId, isRandom }: ITestHeadProps) => {
    const { id } = useParams();
    const [title, setTitle] = useState<string>();
    const [bannerFile, setBannerFile] = useState();
    const [bannerSavedNotificIsActive, setBannerSavedNotificIsActive] = useState(false);
    const [fileUploadErr, setFileUploadErr] = useState(false);
    const [shuffleQuestions, setShuffleQuestions] = useState(isRandom);

    const titleRef = useRef(null);
    const [titleRefId, setTitleRefId] = useState<string>('');

    const { t } = useTranslation();

    function calculateQuestions() {
        let count = testStateService.defaultCategory.questions?.length - 2;

        if (testStateService.categories) {
            testStateService.categories.forEach((item) => {
                count = count + item.questions?.length;
            })
        }

        if (isNaN(count)) {
            return 0;
        }

        return count;
    }

    async function handleBlur(e: any) {
        if (!id
            || e.currentTarget.value === testStateService.test?.title
            || !title?.length) return;
        await testService.updateTest({ testId: id, title: e.currentTarget.value })
            .catch(() => {})
    }

    async function updateTestBanner(testId?: string, file?: File) {
        if (!testId) return;
        await testService.updateTestBanner({ testId, file });
    }

    async function handleShuffleQuestionsCheckbox(id: string) {
        await categoryService.updateCategoryRandom(id, !shuffleQuestions)
          .then(() => setShuffleQuestions(!shuffleQuestions))
          .catch(() => errorPopup())
      };

    useEffect(() => {
        setTitle(testStateService.test?.title);
    }, [testStateService.test?.title]);

    useEffect(() => {
        if (id && bannerFile)
            updateTestBanner(id, bannerFile)
                .then(() => { 
                    setBannerSavedNotificIsActive(true);
                    setFileUploadErr(false);
                })
                .catch(() => { setFileUploadErr(true) });
    }, [bannerFile]);

    useEffect(() => {
        if (titleRef.current && !title?.length) {
            missedFieldsStateService.addElement(titleRefId, titleRef);
        }
        if (title?.length && missedFieldsStateService.elements[titleRefId]) {
            missedFieldsStateService.removeElement(titleRefId);
        }
    }, [title, title?.length]);

    useEffect(() => {}, [id]);

    useEffect(() => {
        const refTitleId = `${id + 'title'}`;
        setTitleRefId(refTitleId);
        if (titleRef.current && !title?.length) missedFieldsStateService.addElement(refTitleId, titleRef);
    }, []);

    return (
        <div id='head' className={style.head}>
            <div
                ref={titleRef}
                className={style.title}>
                <input
                    id='title'
                    value={title || ''}
                    placeholder={ t('test.untitledName') }
                    onBlur={handleBlur}
                    className={cn(style.titleInput, { [style.error]: !title?.length })}
                    onChange={(e) => { setTitle(e.currentTarget.value) }} />
                <ErrorText text={!title?.length ? t('test.enterTitle') : ''} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <UploadInput accept={['image/*']} setFile={setBannerFile}>
                        <AddButton
                            onClick={() => {}}
                            icon={photoImg}
                            text={ t('test.addBanner') } />
                        <Notification
                            text={ t('savedSuccess') }
                            active={bannerSavedNotificIsActive}
                            initialDisplay='flex'
                            onClose={() => setBannerSavedNotificIsActive(false)}
                            position={{ top: 40, left: 0 }} />
                    </UploadInput>
                    <ErrorText text={fileUploadErr ? t('question.addFileErr'): '' } />
                    {testStateService.test?.banner &&
                        <div className={style.attachedBanner}>
                            <AttachedElement
                                element={testStateService.test?.banner}
                                margin={{ top: 10 }}
                                onDelete={async () => await updateTestBanner(testStateService.test?._id)} />
                        </div>}
                </div>
                <div className={style.testShuffleQuestionCheckBox} >
                    <input
                        id={`shuffleQuestions_${defaultCategoryId}`}
                        type="checkbox"
                        readOnly
                        checked={shuffleQuestions}
                        onClick={() => handleShuffleQuestionsCheckbox(defaultCategoryId)}
                    />
                    <label htmlFor={`shuffleQuestions_${defaultCategoryId}`} >
                        { t('shuffleNonCategoryQuestions') }
                    </label>
                </div>
                <div className={style.questionsCount}>
                    { t('test.questionNumber') }  <span>{calculateQuestions()}</span>
                </div>
            </div>
        </div>
    )
})
