import { testCardLogo } from '../../../images';
import style from './TestCardPhoto.module.scss';
import defaultTestCardImg from '../../../images/default-test-card-img.png';

interface ITestCardPhotoProp {
    title: string;
    banner?: string;
}

function TestCardPhoto({ title, banner }: ITestCardPhotoProp) {
    return (
        <div className={style.photoTemplate}>
            <img className={style.logo} src={testCardLogo} alt='' />
            <div className={style.info}>
                <div className={style.title}>{title}</div>
                <div className={style.defaultPhoto} style={{
                    backgroundImage: `url(${banner
                        ? `${process.env.REACT_APP_BACK_URL}/${banner}`
                        : defaultTestCardImg})`
                }}></div>
            </div>
        </div>
    )
}

export default TestCardPhoto;