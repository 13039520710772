import { testPassingApiService } from '../api';
import { IAnswer } from '../types/result.types';
import { IUpdateResultAnswerDto, IUpdateResultAnswerFileDto } from '../types/test-passing.types';

class TestPassingService {

    async updateResultAnswer(payload: IUpdateResultAnswerDto) {
        try {
            const data: { answer: IAnswer, emailAlreadyExists?: true } = await testPassingApiService.updateResultAnswer(payload);
            return Promise.resolve(data);
        }
        catch {
            return Promise.reject();
        }
    }

    async updateResultAnswerFile(payload: IUpdateResultAnswerFileDto) {
        try {
            const formDataPayload = new FormData();
            if (payload.file) formDataPayload.append('file', payload.file);
            formDataPayload.append('questionId', payload.questionId);
            const answer: IAnswer = await testPassingApiService
                .updateResultAnswerFile(formDataPayload as unknown as IUpdateResultAnswerFileDto);
            return Promise.resolve(answer);
        }
        catch {
            return Promise.reject();
        }
    }
}

export const testPassingService = new TestPassingService();