import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { IRoute } from "./types";

interface IPublicRoute extends IRoute {}

export const PublicRoute = ({ children }: IPublicRoute) => {
  const { info } = useAppSelector(state => state.user);

  if (!info) {
    return <Navigate to='/login'/>;
  }

  return children;
};
