import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import style from './Header.module.scss';
import { logoImg, logoutImg } from '../../../images';
import { authService } from '../../../services';
import LocalizationSwitcher from '../localization-switcher/LocalizationSwitcher';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import { logout } from '../../../redux/features';

function Header() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    function logoutFunc() {
        authService.logout();
        dispatch(logout());
        navigate('/login', { replace: true });
    }

    return (
        <div className={style.header}>
            <div 
                className={style.logo}
                onClick={() => navigate('/')}
            >
                <img src={logoImg} alt='' />
                <div className={style.subText}>Surveys</div>
            </div>
            <div className={style.menu}>
                <LocalizationSwitcher />
                {
                    <div className={style.logout} onClick={logoutFunc}>
                        <span>{ t('logout') }</span>
                        <img src={logoutImg} alt='' />
                    </div>
                }
            </div>
        </div >
    )
}

export default observer(Header);