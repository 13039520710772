export async function copyText(text: string, onCopy?: () => void, onFail?: () => void) {
    const textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        onCopy && onCopy();;
    } catch (err) {
        onFail && onFail();
    }
    document.body.removeChild(textArea);
}

  // textArea.value = `http://192.168.124.102:3000/passing?encoded=${testStateService.test?.link}`;
   // await navigator?.clipboard.writeText(`${ process.env.REACT_APP_APP_URL } /passing?encoded=${testStateService.test?.link}`)
    //     .then(() => setCopyTestLinkNotificIsActive(true))
    //     .catch((e) => { console.log(e) });