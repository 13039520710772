import style from './TestUnavailablePage.module.scss';
import { InfoPageWrapper} from '../../components/common';
import { useTranslation } from 'react-i18next';
import LocalizationSwitcher from '../../components/common/localization-switcher/LocalizationSwitcher';

function TestUnavailablePage() {
    const { t } = useTranslation();

    return (
        <InfoPageWrapper
            largeText={ t('test.deleted') }>
            <div className={style.localizationSwitcher} >
            <LocalizationSwitcher />
            </div>
        </InfoPageWrapper >
    )
}

export default TestUnavailablePage;