import cn from 'classnames';
import { IQuestion } from '../../../types';
import { Attachments } from '../../test-passing';
import style from './CheckResultQuestion.module.scss';
import { QuestionTypesEnum } from '../../../types/question.types';
import CheckResultQuestionOptions from './CheckResultQuestionOptions';
import CheckResultScore from './CheckResultScore';
import { useTranslation } from 'react-i18next';

interface ICheckResultQuestionProp {
    question: IQuestion;
    quesitonNumber: number;
    resultId: string;
}

function CheckResultQuestion({ question, quesitonNumber, resultId }: ICheckResultQuestionProp) {
    const { t } = useTranslation();

    return (
        <>{question && <>
            <div className={cn(style.card, { [style.border]: quesitonNumber !== 1 })} >
                <div className={style.questionText}>{ t('question.question') } {quesitonNumber}: {question.text}</div>
                <Attachments files={question.files} links={question.links} />
                <div className={style.answer}>
                    {
                        question.answers && <>
                            {
                                question.type === QuestionTypesEnum.open &&
                                <div>
                                    <span style={{ fontWeight: '700' }}>{ t('question.answer') }: </span>
                                    {question.answers[0]?.text}
                                    {question.answers[0]?.file && <Attachments files={[question.answers[0]?.file]} />}
                                </div>
                            }
                            {
                                question.type === QuestionTypesEnum.closed &&
                                <CheckResultQuestionOptions question={question} />
                            }
                        </>
                    }
                    {
                        (question.type && question.answers) &&
                        <CheckResultScore
                            answerId={question.answers[0]?._id}
                            resultId={resultId}
                            questionType={question.type}
                            maxScore={question.maxScore}
                            initialScore={question.answers[0]?.score || 0}
                            isImmutable={question.type === QuestionTypesEnum.closed || !question.answers[0]?._id} />
                    }
                </div>
            </div>
        </>} </>
    )
}

export default CheckResultQuestion;