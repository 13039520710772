import { makeAutoObservable, observable } from 'mobx';
import { ITest, IUpdateTestDto, IQuestion } from '../types';
import { ICategory } from '../types/category.types';

class TestStateService {
    test: ITest | null = null;
    categories: ICategory[] = [] as ICategory[];
    defaultCategory: ICategory = {} as ICategory;

    constructor() { makeAutoObservable(this) }

    setTest(test: ITest) {
        this.test = test;
        this.categories = test.categories
            .filter((item) => !item.isDefault)
            // .map((item) => { 
            //     return {
            //         ...item, 
            //         questions: observable.array(item.questions, { deep: false })} 
            // });
        this.defaultCategory = test.categories.find((item) => item.isDefault) ?? ({} as ICategory);
    }

    updateTest(updateTestDto: IUpdateTestDto) {
        this.test = observable({ ...this.test, ...updateTestDto }) as ITest;
    }

    updateQuestionOrder(categoryId: string, updatedQuestions: any) {
        if (categoryId === this.defaultCategory._id) this.defaultCategory.questions = observable.array(updatedQuestions);
        else {
            const idx = this.categories.findIndex((item) => item._id === categoryId);
            this.categories[idx].questions = observable.array(updatedQuestions);
        }
    }

    updateQuestionOrderInCategory(
        fromCategoryId: string, 
        fromCategoryUpdatedQuestions: any, 
        toCategoryId: string, 
        toCategoryUpdatedQuestions: any
    ) {
        const categories = [this.defaultCategory, ...this.categories];
        const fromCategory = categories.findIndex((item) => item._id === fromCategoryId);
        const toCategory = categories.findIndex((item) => item._id === toCategoryId);

        categories[fromCategory].questions = observable.array(fromCategoryUpdatedQuestions);
        categories[toCategory].questions = observable.array(toCategoryUpdatedQuestions);
    }

    updateCategoryOrder(updatedCategories: any) {
        this.categories = updatedCategories;
    }

    updateCategory(categoryId: string, title: string) {
        const index = this.categories.findIndex((item) => item._id === categoryId);
        this.categories[index].title = title;
    }

    addQuestion(question: IQuestion) {
        this.defaultCategory.questions.push(observable(question));
    }

    addQuestionInCategory(question: IQuestion) {
        const categoryIndex = this.categories.findIndex(item => item._id === question.category);
        this.categories[categoryIndex].questions.push(observable(question));
    }

    updateQuestion(questionId: string, updatedQuestion: IQuestion) {
        const questionIndex = this.defaultCategory.questions.findIndex(item => item._id === questionId);
        this.defaultCategory.questions[questionIndex] = observable(updatedQuestion);
    }

    updateQuestionInCategory(questionId: string, categoryId: string, updatedQuestion: IQuestion) {
        const categoryIndex = this.categories.findIndex(item => item._id === categoryId);
        const questionIndex = this.categories[categoryIndex].questions.findIndex(item => item._id === questionId);

        this.categories[categoryIndex].questions[questionIndex] = observable(updatedQuestion);
    }

    moveQuestion(fromCategoryId: string, toCategoryId: string, questionId: string, orderIndex: number) {
        let fromCategoryIdx;
        let toCategoryIdx;

        const categories = [this.defaultCategory, ...this.categories];

        categories.forEach((item, index) => {
            if (item._id === fromCategoryId) fromCategoryIdx = index;
            if (item._id === toCategoryId) toCategoryIdx = index;
        });

        if (fromCategoryIdx !== undefined && toCategoryIdx !== undefined) {
            const questionIdx = categories[fromCategoryIdx].questions.findIndex((item) => item._id === questionId);
            const question = categories[fromCategoryIdx].questions[questionIdx];
            categories[fromCategoryIdx].questions = categories[fromCategoryIdx].questions.filter((item) => item._id !== questionId);
            categories[toCategoryIdx].questions.push(observable({...question, orderIndex, category: toCategoryId}));
        }
    }

    deleteQuestion(questionId: string) {
        this.defaultCategory.questions = this.defaultCategory.questions
            .filter((item) => item._id !== questionId);
    }

    deleteQuestionInCategory(questionId: string, categoryId: string) {
        const categoryIndex = this.categories.findIndex(item => item._id === categoryId);
        this.categories[categoryIndex].questions = this.categories[categoryIndex].questions.filter((item) => item._id !== questionId);
    }

    addCategory(category: ICategory) {
        category.questions = category.questions.map((item) => observable(item));
        this.categories.push(observable(category));
    }

    deleteCategory(categoryId: string) {
        this.categories = this.categories.filter((item) => item._id !== categoryId);
    }
}

export const testStateService = new TestStateService();
