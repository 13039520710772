import { categoryApiService } from "../api/category.api-service";
import { testStateService } from "../state";
import { ICategory } from "../types/category.types";

class CategoryService {
  async createCategory(testId: string) {
    try {
      const category: ICategory = await categoryApiService.createCategory(testId);
      testStateService.addCategory(category);

      return Promise.resolve(category);
    } catch {
        return Promise.reject();
    }
  }

  async updateCategory(categoryId: string, title: string) {
    try {
      await categoryApiService.updateCategory(categoryId, title);
      testStateService.updateCategory(categoryId, title);
      
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }

  async updateCategoryRandom(categoryId: string, isRandom: boolean) {
    try {
      await categoryApiService.updateCategoryRandom(categoryId, isRandom);
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }

  async deleteCategory(categoryId: string, testId: string) {
    try {
      await categoryApiService.deleteCategory(categoryId, testId);
      testStateService.deleteCategory(categoryId);
      return Promise.resolve();
    } catch {
      return Promise.reject();
    }
  }
}

export const categoryService = new CategoryService();