import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IResult, ITest } from '../../types';
import style from './CheckAnswerTab.module.scss';
import { resultStateService } from '../../state';
import ResultCard from '../test/test-answers-tab/ResultCard';
import { checkApiService } from '../../api/check.api-service';
import { ReactComponent as ExelIcon } from '../../images/exel.svg';
import { ReactComponent as CheckboxIcon } from '../../images/correct-checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../images/correct-checkbox-checked.svg';
import { ReactComponent as CheckIcon } from '../../images/check.svg';
import { useTranslation } from 'react-i18next';
import { resultService } from '../../services';

function CheckAnswersTab() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [selectPanelActive, setSelectPanelActive] = useState(false);
    const [test, setTest] = useState<Pick<ITest, '_id' | 'time' | 'assignedTutors' | 'title' | 'maxScore'>>();

    const results: IResult[] = resultStateService.results;

    const { t } = useTranslation();

    async function getTestToCheck(id: string) {
        await checkApiService.getTestToCheck(id)
            .then(data => {
                setTest(data.test);
                resultStateService.setResults(data.results);
            })
            .catch(() => navigate('/results-unavailable', { replace: true }));
    }

    async function getSelectedResultsData() {
        checkApiService
          .getResultCheckInfoMany(resultStateService.selectedResultsIds)
          .then((data) => data)
          .catch(() => {});
    }

    async function markResultsAsChecked() {
        await resultService
            .markResults(resultStateService.selectedResultsIds)
            .catch(() => {})
            .finally(() => { if(id) getTestToCheck(id) });
    }

    useEffect(() => {
        if (resultStateService.selectedResultsIds.length) setSelectPanelActive(true);
        else setSelectPanelActive(false);
    }, [resultStateService.selectedResultsIds.length]);

    useEffect(() => { if (id) getTestToCheck(id) }, [id]);

    return (
        <div className={style.contentWrapper}>
            {
                selectPanelActive &&
                <div className={style.selectedPanel}>
                    <div className={style.selectedPanelCount}>
                        { t('check.recordSelected', { count: resultStateService.selectedResultsIds.length}) }
                    </div>
                    <div 
                    className={style.selectedPanelItem}
                    onClick={getSelectedResultsData}
                    >
                        { t('check.excelExport') }
                        <ExelIcon className={style.selectedPanelIcon} />
                    </div>
                    <div
                        className={style.selectedPanelItem}
                        onClick={markResultsAsChecked}
                    >
                        { t('test.markChecked', { count: resultStateService.selectedResultsIds.length }) }
                        <CheckIcon className={style.selectedPanelIcon} />
                    </div>
                </div>
            }
            <div className={style.tutorAccessCardGrid}>
                <div
                    className={style.iconWrapper}
                    onClick={() => resultStateService.setAllSelected()}>
                    {
                        resultStateService.results.length > 0
                            && resultStateService.results.length
                            === resultStateService.selectedResultsIds.length
                            ? <CheckboxCheckedIcon /> : <CheckboxIcon />
                    }
                </div>
                <div className={cn(style.tutorAccessHeadInfo, style.head)}>
                    <div>{ t('check.userName') }</div>
                    <div>{ t('check.userEmail') }</div>
                    <div>{ t('date') }</div>
                    <div>{ t('check.points') }</div>
                    <div>{ t('check.checked') }</div>
                    <div>{ t('tutor') }</div>
                </div>
            </div>
            <div className={style.content}>
                {
                    results && results.map((item) =>
                        <ResultCard
                            key={item._id}
                            result={item}
                            testId={id}
                            testMaxScore={test?.maxScore || 0}
                            assignedTutors={test?.assignedTutors || []}
                            tutorAccess={true}/>)
                }
            </div>
        </div >
    )
}

export default observer(CheckAnswersTab);