import cn from 'classnames';
import { useRef, useState, useEffect } from 'react';
import { ErrorText } from '../../common';
import style from './TestPassingInput.module.scss';
import { testPassingStateService } from '../../../state';
import { emailRegex, useAutosizeTextArea } from '../../../utils';
import { useTranslation } from 'react-i18next';

interface ITextPassingInputProp {
    questionId: string;
    initialText?: string;
    isRequired?: boolean;
    isEmail?: boolean;
    isName?: boolean;
    onChange: (value: string) => void;
}

function TestPassingInput({ questionId, initialText, isRequired, isEmail, onChange }: ITextPassingInputProp) {
    const { t } = useTranslation();

    const [text, setText] = useState(initialText || '');
    const [errorText, setErrorText] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useAutosizeTextArea(textareaRef.current, text);
    async function handleChange(e: any) {
        validateValue(e.target.value);
        const value = e.target.value.replaceAll('\n', '');
        let answeredState = false;
        if (isRequired && (value.length)) answeredState = true;
        if (isEmail && !emailRegex.test(value)) answeredState = false;
        testPassingStateService.udateRequiredQuestionsAnswer(questionId, answeredState);
        setText(value);
    }

    function validateValue(value: string) {
        setErrorText('');
        if (isEmail) {
            const emailValidation = emailRegex.test(value);
            if (!emailValidation) setErrorText(t('invalidEmailFormat'));
        }
    }

    useEffect(() => {
        if (initialText) validateValue(initialText);
        if (textareaRef.current) {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
        if (isRequired && (!initialText?.length || errorText.length))
            testPassingStateService.udateRequiredQuestionsAnswer(questionId, false);
        else testPassingStateService.udateRequiredQuestionsAnswer(questionId, true);
    }, []);

    return (
        <div className={style.info}>
            <textarea
                ref={textareaRef}
                rows={1}
                value={text}
                onInput={handleChange}
                onBlur={() => onChange(text)}
                className={cn(style.input,
                    { [style.error]: errorText.length && isRequired },
                    { [style.full]: text?.length && !errorText })}
                placeholder={isEmail ? 'exampleemail@gmail.com' : t('test.typeAnswer')} />
            <div>{isRequired && <ErrorText text={errorText} />} </div>
        </div>
    )
}


export default TestPassingInput;
