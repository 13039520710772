import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import style from './Panel.module.scss';
import { IPosition } from '../../../types';

interface IPanelProp {
    children: ReactNode;
    active?: boolean;
    keepActive?: boolean;
    position?: IPosition;
    width?: number;
    exeptRef?: any;
    onClose?: () => void;
}

function Panel({ children, position, width, active, keepActive, onClose, exeptRef }: IPanelProp) {
    const [isActive, setIsActive] = useState<boolean>();
    const panelRef = useRef(null);

    const checkActive = useCallback((e: any) => {
        // @ts-ignore
        if (!panelRef?.current?.contains(e.target)
            && !exeptRef?.current?.contains(e.target)) {
            setIsActive(false);
            if (onClose) onClose();
        }
    }, []);

    useEffect(() => {
        if (keepActive) return;
        if (isActive) document.addEventListener('click', checkActive);
        else document.removeEventListener('click', checkActive);
    }, [isActive]);

    useEffect(() => { setIsActive(active) }, [active]);

    return (
        <div className={style.wrapper}>
            <div
                className={style.popup}
                ref={panelRef}
                style={{
                    width: `${width}px`,
                    top: `${position?.top}px`,
                    bottom: `${position?.bottom}px`,
                    right: `${position?.right}px`,
                    left: `${position?.left}px`,
                    display: (isActive || keepActive) ? 'block' : 'none',
                }} >
                {children}
            </div>
        </div>
    )
}

export default Panel;
